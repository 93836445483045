import {
  Button,
  Card,
  Checkbox,
  Col,
  Form,
  Input, Modal,
  Row,
  Select,
  Space,
  Switch,
  Table,
  Tabs,
  TabsProps
} from "antd";
import type { ColumnsType } from "antd/es/table";
import { ContentHeader } from "../../components";

import {
  EyeOutlined,
  DownloadOutlined,
  DeleteOutlined,
  SearchOutlined,
  EditFilled
} from "@ant-design/icons";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useRef, useState } from "react";

import { errorToast, successToast } from "../../helpers";
import useAuth from "../../hooks/useAuth";
import {
  LazyLoadImage,
  ScrollPosition,
  trackWindowScroll,
} from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import {
  useDeleteUserMutation,
  useLazyGetUserCsvQuery,
  useLazyGetUserDetailsQuery,
  usePutBlockUserMutation,
  usePutChangeSellerStatusMutation,
} from "../../services/user";
import { CommonBody } from "../../types/General";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import Loader from "../../components/Loader";

const { confirm } = Modal;

interface DataType {
  sellerFormIsBlocked: boolean | undefined;
  sellerFormStatus: number;
  sellFormExists: any;
  _id: any;
  key: string;
  name: string;
  phone: string;
  userName: string;
  email: string;
  status: boolean;
}

type PropTypes = {
  scrollPosition: ScrollPosition;
};

const ManageUsers = ({ scrollPosition }: PropTypes) => {
  const location = useLocation();
  const { state } = location;
  console.log(state);
  const navigate = useNavigate();
  const { Option } = Select;
  const [userList, userListData] = useLazyGetUserDetailsQuery();

  const [sellerStatus] = usePutChangeSellerStatusMutation();
  const [getUserCsvMethod] = useLazyGetUserCsvQuery();
  const hasInitialized = useRef(false);
  const [userListState, setUserListState] = useState([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [selectedCategory, setSelectedCategory] = useState("1");
  const [isTabLoading, setIsTabLoading] = useState({ "1": false, "2": false });

  const notificationTypes = [
    { name: "Pending", id: "1" },
    { name: "Accept", id: "2" },
    { name: "Reject", id: "3" },
  ];

  const [totalCount, setTotalCount] = useState<number | undefined>();
  const [deleteUser, deleteUserData] = useDeleteUserMutation();
  const [blockUser, blockUserData] = usePutBlockUserMutation();
  const [debouncedQuery, setDebouncedQuery] = useState("");
  const [activeTab, setActiveTab] = useState("1");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [hidePermission, setHidePermission] = useState<any>({
    isAdd: true,
    isDelete: true,
  });

  const handleFeature = async (id: string, currentStatus: boolean) => {
    const data = {
      isBlocked: currentStatus,
    };

    console.log(data);

    let body = generateEncryptedKeyBody(data) as CommonBody;

    try {
      const response = await sellerStatus({
        userId: id,
        encryptedBody: body,
      }).unwrap();
      if (response?.statusCode === 200) {
        successToast(
          !currentStatus
            ? "Added to official store"
            : "Removed from official store"
        );
        getUserDetails(currentPage, "2");
      }
    } catch (error: any) {
      errorToast(error?.data?.message || "");
    }
  };

  const handleRequestStatus = async (value: any, id: string) => {
    const data = {
      status: Number(value),
    };
    console.log(data);
    let body = generateEncryptedKeyBody(data) as CommonBody;

    try {
      const response = await sellerStatus({
        userId: id,
        encryptedBody: body,
      }).unwrap();
      if (response?.statusCode === 200) {
        successToast("Request changed successfully");
        getUserDetails(currentPage, "2");
      }
    } catch (error: any) {
      errorToast(error?.data?.message || "");
    }
  };

  const userData = useAuth();

  const [query, setQuery] = useState("");

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
    getUserDetails(page, activeTab);
  };

  const handleDelete = async (id: string) => {
    try {
      const res = await deleteUser({ userId: id }).unwrap();

      if (res?.statusCode === 200) {
        successToast("User Deleted Successfully");
        getUserDetails(currentPage, activeTab);
      }
    } catch (error: any) {
      console.log(error);
      errorToast(error?.data?.message);
    }
  };

  const showDeleteConfirm = (record: any) => {
    confirm({
      title: "Hold on",
      content: "Are you sure delete this user?",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        handleDelete(record?._id);
        console.log("OK");
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const handleBlock = async (isBlocked: boolean, _id: string) => {
    let data = {
      isBlocked: isBlocked,
    };
    console.log(isBlocked, _id, "both");
    let encryptedBody = generateEncryptedKeyBody(data) as CommonBody;

    try {
      const res = await blockUser({ encryptedBody, userId: _id }).unwrap();
      if (res?.statusCode === 200) {
        getUserDetails(currentPage, activeTab);
        successToast(
          isBlocked
            ? "User Deactivated Successfully!"
            : "User Activated Successfully!"
        );
      }
    } catch (error: any) {
      console.log(error);
      errorToast(error?.data?.message);
    }
  };

  const checkPermission = () => {
    const permissions = userData?.permissions;
    if (permissions?.length) {
      let idx = -1;
      idx = permissions?.findIndex((ele: any) => ele?.label === "Manage Users");

      if (idx != -1) {
        let data = {
          isAdd: permissions[idx]?.isAdd,
          isDelete: permissions[idx]?.isDelete,
        };
        setHidePermission(data);
      }
    }
  };

  useEffect(() => {
    if (userData && userData?.permissions?.length) {
      checkPermission();
    }
  }, [userData]);

  const handleExportCsv = async () => {
    try {
      const res = await getUserCsvMethod({}).unwrap();
      if (res?.statusCode === 200) {
        if (res?.data) {
          window.open(res?.data);
        }
      }
    } catch (error: any) {
      errorToast(error?.data?.message);
    }
  };

  useEffect(() => {
    const delay = 1000; // Debounce delay in milliseconds
    const timerId = setTimeout(() => {
      setQuery(debouncedQuery);
    }, delay);

    return () => {
      clearTimeout(timerId); // Clear the timeout on cleanup
    };
  }, [debouncedQuery]);

  const columns: ColumnsType<DataType> = [
    {
      title: "Image",
      dataIndex: "image",
      key: "image",
      width: "10%",
      align: "center",
      render: (_, { image }: any) => (
        <div>
          <LazyLoadImage
            alt="profile"
            style={{ height: 70, width: 70, borderRadius: 100 }}
            src={image || "/static/images/dummy.png"}
            effect="blur"
            scrollPosition={scrollPosition}
          />
        </div>
      ),
    },
    {
      title: "Full Name",
      dataIndex: "firstName",
      key: "firstName",
      align: "center",
      render: (_, { fullName }: any) => (
        <div>
          <span>{`${fullName}`}</span>
        </div>
      ),
    },

    {
      title: "Phone Number",
      dataIndex: "phone",
      key: "phone",
      align: "center",
      render: (_, { phone, countryCode }: any) => (
        <div>
          <span>{`${phone ? countryCode + " " + phone : "N/A"}`}</span>
        </div>
      ),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      align: "center",
      render: (_, { email }: any) => (
        <div>
          <span>{`${email || "N/A"}`}</span>
        </div>
      ),
    },

    {
      title: "Action",
      key: "action",
      align: "center",
      render: (_, record) => (
        <Space size="middle">
          <Button
            onClick={(e) => {
              e.preventDefault();
              handleViewClick(record);
            }}
          >
            <EyeOutlined style={{ color: "#52c41a" }} />
          </Button>

          {hidePermission?.isAdd ? (
            <Button
              onClick={(e) => {
                e.preventDefault();
                navigate(`/manageusers/edit/${record?._id}`, {
                  state: {
                    page: currentPage,
                    type: activeTab === "1" ? "customer" : "seller",
                  },
                });
              }}
            >
              <EditFilled style={{ color: "#C69320" }} />
            </Button>
          ) : (
            ""
          )}

          {hidePermission?.isDelete ? (
            <Button
              onClick={(e) => {
                e.preventDefault();
                showDeleteConfirm(record);
              }}
            >
              <DeleteOutlined style={{ color: "#d4380d" }} />
            </Button>
          ) : (
            ""
          )}
        </Space>
      ),
    },

    {
      title: "Account Status",
      dataIndex: "status",
      key: "status",
      align: "center",
      render: (_, { isBlocked, _id }: any) => (
        <Switch
          size="small"
          defaultChecked={!isBlocked}
          onChange={() => handleBlock(!isBlocked, _id)}
        />
      ),
    },
    activeTab === "2"
      ? {
          title: "Request",
          dataIndex: "request",
          key: "request",
          width: "15%",
          align: "center",
          render: (_, record) => (
            <div>
              {record?.sellFormExists ? (
                <Select
                  disabled={record?.sellerFormStatus === 2}
                  maxLength={20}
                  className="selector_type"
                  onChange={(val) => handleRequestStatus(val, record?._id)}
                  value={
                    record?.sellerFormStatus === 1
                      ? "1"
                      : record?.sellerFormStatus === 2
                      ? "2"
                      : "3"
                  }
                  style={{ height: 75, width: "100%" }}
                  placeholder="Target"
                >
                  {notificationTypes?.map((item, index) => (
                    <Option key={index} value={item?.id} label={item?.name}>
                      {item?.name}
                    </Option>
                  ))}
                </Select>
              ) : (
                ""
              )}
            </div>
          ),
        }
      : {},
    activeTab === "2"
      ? {
          title: "Official Store",
          key: "action",
          align: "center",
          render: (_, record) => (
            <div>
              {record?.sellFormExists ? (
                <Space size="middle">
                  <Checkbox
                    checked={!record?.sellerFormIsBlocked}
                    onChange={(e) => {
                      e.preventDefault();
                      handleFeature(record?._id, !record?.sellerFormIsBlocked);
                    }}
                  />
                </Space>
              ) : (
                ""
              )}
            </div>
          ),
        }
      : {},
  ];

  const handleViewClick = async (record: any) => {
    navigate(`/manageusers/userdetail/${record?._id}`, {
      state: {
        page: currentPage,
        type: activeTab === "1" ? "customer" : "seller",
      },
    });
  };

  const getUserDetails = async (page: number, tab: string) => {
    setIsLoading(true);
    try {
      const res = await userList({
        page: page,
        size: 10,
        search: query || "",
        type: Number(tab),
      }).unwrap();
      if (res?.statusCode === 200) {
        setUserListState(res?.data?.user || []);
        setTotalCount(res?.data?.count);
      }
    } catch (error: any) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };
  

  const onChange = (key: string) => {
    setActiveTab(key);
    getUserDetails(1, key);
    setCurrentPage(1);
    setUserListState([]);
  };

  const pagination = {
    pageSize: 10,
    current: currentPage,
    total: totalCount,
    onChange: handlePageChange,
  };

  const items: TabsProps["items"] = [
    {
      key: "1",
      label: `Customer`,
      children: (
        <div className="srch">
          <Card style={{ overflowX: "scroll" }}>
            <Row
              style={{ justifyContent: "space-between", padding: "10px 0px" }}
              gutter={16}
            >
              <Col xs={24} md={7}>
                <Form style={{ display: "flex", gap: 20 }}>
                  <Input
                    placeholder="Search"
                    prefix={<SearchOutlined style={{ fontSize: "24px" }} />}
                    style={{ height: 50, borderRadius: 10 }}
                    value={debouncedQuery}
                    onChange={(e) => setDebouncedQuery(e.target.value)}
                    maxLength={20}
                    allowClear
                  />
                </Form>
              </Col>
              <Col
                style={{
                  margin: 1,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Button
                  icon={<DownloadOutlined />}
                  style={{
                    background: "#ECAC35",
                    height: 35,
                    color: "black",
                  }}
                  type="primary"
                  onClick={handleExportCsv}
                >
                  Export CSV
                </Button>
              </Col>
            </Row>
            <div style={{ marginTop: 25 }}>
              <Table
                className="tableSelector"
                columns={columns}
                dataSource={userListState}
                rowKey="_id"
                loading={userListData?.isLoading || blockUserData.isLoading}
                pagination={pagination}
              />
            </div>
          </Card>
        </div>
      ),
    },
    {
      key: "2",
      label: `Seller`,
      children: (
        <div className="srch">
          <Card style={{ overflowX: "scroll" }}>
            <Row
              style={{ justifyContent: "space-between", padding: "10px 0px" }}
              gutter={16}
            >
              <Col xs={24} md={7}>
                <Form style={{ display: "flex", gap: 20 }}>
                  <Input
                    placeholder="Search"
                    prefix={<SearchOutlined style={{ fontSize: "24px" }} />}
                    style={{ height: 50, borderRadius: 10 }}
                    value={debouncedQuery}
                    onChange={(e) => setDebouncedQuery(e.target.value)}
                    maxLength={20}
                    allowClear
                  />
                </Form>
              </Col>
              <Col
                style={{
                  margin: 1,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Button
                  icon={<DownloadOutlined />}
                  style={{
                    background: "#ECAC35",
                    height: 35,
                    color: "black",
                  }}
                  type="primary"
                  onClick={handleExportCsv}
                >
                  Export CSV
                </Button>
              </Col>
            </Row>
            <div style={{ marginTop: 25 }}>
              <Table
                className="tableSelector"
                columns={columns}
                dataSource={userListState}
                rowKey="_id"
                loading={userListData?.isLoading || blockUserData.isLoading}
                pagination={pagination}
              />
            </div>
          </Card>
        </div>
      ),
    },
  ];

  useEffect(() => {
    if (state) {
      getUserDetails(state?.page, state?.type === "first" ? "1" : "2");
    } else {
      getUserDetails(currentPage, activeTab);
    }
  }, [query]);

  useEffect(() => {
    if (state) {
      setCurrentPage(state?.page);
      setActiveTab(state?.type === "first" ? "1" : "2");
    }
  }, []);

  // useEffect(() => {
  //   if (state?.page) {
  //     setCurrentPage(state?.page);
  //     getUserDetails(state?.page);
  //   } else {
  //     getUserDetails(currentPage);
  //   }
  // }, [query, activeTab]);

  // useEffect(() => {
  //   if (state?.type === "first") {
  //     setActiveTab("1");
  //   } else if (state?.type === "second") {
  //     setActiveTab("2");
  //   }
  // }, []);

  return (
    <div style={{ padding: 40 }}>
      <ContentHeader heading="Manage Users" />
   
      <Tabs
        activeKey={activeTab}
        defaultActiveKey="1"
        items={items}
        onChange={onChange}
      />
    </div>
  );
};

// export default ManageUsers;
export default trackWindowScroll(ManageUsers);
