import { Button, Card, Col, Image, Row } from "antd";
import { Key, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  LazyLoadImage,
  ScrollPosition,
  trackWindowScroll,
} from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { useLazyGetProductByIdQuery } from "../../../services/product";

type PropTypes = {
  scrollPosition: ScrollPosition;
};

const FeaturedProductDetails = ({ scrollPosition }: PropTypes) => {
  const location = useLocation();
  const { state } = location;
  console.log(state);

  const { id } = useParams();
  const navigate = useNavigate();

  const [getProductDataById] = useLazyGetProductByIdQuery();
  const [productDetail, setProductDetail] = useState<any>();

  const getProductDetailById = async () => {
    try {
      const res = await getProductDataById(id).unwrap();

      if (res?.statusCode === 200) {
        setProductDetail(res?.data);
      }
    } catch (error: any) {
      console.log(error);
    }
  };

  useEffect(() => {
    getProductDetailById();
  }, []);

  return (
    <div style={{ padding: 50, gap: 20, display: "grid" }}>
      <Card>
        <div
          style={{
            alignItems: "center",
            display: "flex",
            justifyContent: "space-between",
            flexWrap: "wrap",
          }}
        >
          <h2>Product details</h2>
          <Button
            style={{
              background: "#ECAC35",
              width: 100,
              height: 40,
              color: "black",
            }}
            type="primary"
            onClick={() => {
              navigate("/recommended-products", {
                replace: true,
                state: {
                  ...state,
                },
              });
            }}
          >
            Back
          </Button>
        </div>

        <div>
          <Row gutter={16}>
            <Col xs={20} md={8}>
              <h4>Product Name</h4>
              <h5>{productDetail?.name || "-"}</h5>
            </Col>
            <Col xs={20} md={8}>
              <h4>Category of the Product</h4>
              <h5>{productDetail?.categoryId?.name || "-"}</h5>
            </Col>

            <Col xs={20} md={8}>
              <h4>Sub Category of the Product</h4>
              <h5>{productDetail?.subCategoryId?.name || "-"}</h5>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col xs={20} md={8}>
              <h4>Price of the Product</h4>
              <h5>{productDetail?.price}</h5>
            </Col>
            <Col xs={20} md={8}>
              <h4>Address of the User</h4>
              <h5>{productDetail?.address || "-"}</h5>
            </Col>
            <Col xs={20} md={8}>
              <h4>Description of the Product</h4>
              <h5>{productDetail?.description || "-"}</h5>
            </Col>
          </Row>

          <Row gutter={16}>
            {productDetail?.dynamicFields?.length
              ? productDetail?.dynamicFields?.map((i: any, index: number) => (
                  <>
                    {Object?.keys(i)?.map((key: string | number) => (
                      <Col key={index} xs={20} md={8}>
                        <h4>{`${key}`}</h4>

                        <h5>{i[key]}</h5>
                      </Col>
                    ))}
                  </>
                ))
              : undefined}
            {productDetail?.brand ? (
              <Col xs={20} md={8}>
                <h4>Brand of the Product</h4>
                <h5>{productDetail?.brand}</h5>
              </Col>
            ) : undefined}
          </Row>

          <h2>Images, and videos for the product</h2>
          <div>
            <Row gutter={16}>
              <Col xs={24} md={24}>
                <div
                  style={{
                    display: "flex",
                    gap: 20,
                    flexWrap: "wrap",
                    //
                  }}
                >
                  {productDetail?.images?.map(
                    (
                      item: string | undefined,
                      index: Key | null | undefined
                    ) => (
                      <Image
                        style={{
                          height: 200,
                          borderRadius: 10,
                          width: 200,
                        }}
                        src={item}
                      />
                      // <LazyLoadImage
                      //   key={index}
                      //   alt="profile"

                      //   effect="blur"
                      //   scrollPosition={scrollPosition}
                      // />
                    )
                  )}
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default trackWindowScroll(FeaturedProductDetails);
