import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { DeleteOutlined, SearchOutlined } from "@ant-design/icons";
import { Button, Col, Form, Input, Modal, Row, Space } from "antd";
import Table, { ColumnsType } from "antd/es/table";
import { Reports } from "../../types/General";
import moment from "moment";
import ReplyToPostModal from "../../components/replyToPostModal/ReplyToPostModal";
import { useDeleteReportReviewMutation } from "../../services/support";
import { errorToast, successToast } from "../../helpers";
import { useNavigate } from "react-router-dom";
import { EyeOutlined } from "@ant-design/icons";

const { confirm } = Modal;
interface DataType {
  key: string;
  title: string;
  description: string;
  customerName: string;
  date: string;
  email: string;
}

type Permission = {
  isAdd: boolean;
  isDelete: boolean;
};

type PropsConst = {
  hidePermission: Permission;
  allReport: Reports[] | [];
  currentPage: number;
  setCurrentPage: Dispatch<SetStateAction<number>>;
  totalCount: number | undefined;
  loading: boolean;
  setLoading: Dispatch<SetStateAction<boolean>>;
  query: string;
  setQuery: Dispatch<SetStateAction<string>>;

  getAllReportData: (page: number) => void;
};

const Complaints = ({
  hidePermission,
  allReport,
  setCurrentPage,
  setLoading,
  totalCount,
  setQuery,
  query,
  getAllReportData,
  currentPage,
  loading,
}: PropsConst) => {
  const navigate = useNavigate();
  const [debouncedQuery, setDebouncedQuery] = useState("");
  const [visible, setVisible] = useState(false);
  const [reasonText, setReasonText] = useState("");
  console.log(allReport, "-----");
  const [deleteReport] = useDeleteReportReviewMutation();
  //////

  const columns: ColumnsType<Reports> = [
    {
      title: "Customer Name",
      dataIndex: "customerName",
      key: "customerName",
      width: "10%",
      align: "center",
      render: (_, { user }) => (
        <div>
          <span>{user?.fullName}</span>
        </div>
      ),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      width: "10%",
      align: "center",
      render: (_, { user }) => (
        <div>
          <a href={`mailto:${user?.email}`}>{user?.email}</a>
        </div>
      ),
    },
    {
      title: "Reason",
      dataIndex: "reason",
      key: "reason",
      width: "40%",
      align: "center",
      render: (_, { reason }) => (
        <div>
          <span>
            {reason?.length > 50 ? `${reason.substring(0, 50)}...` : reason}
          </span>
        </div>
      ),
    },
    {
      title: "Reported Product",
      dataIndex: "reportedProducts",
      key: "reportedProducts",
      width: "20%",
      align: "center",
      render: (_, { reportedProducts }) => (
        <div>
          <span>{reportedProducts?.name}</span>
        </div>
      ),
    },

    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      width: "15%",
      align: "center",
      render: (_, { createdAt }) => (
        <div>
          <span>{moment(createdAt).format("MMM Do YYYY, h:mm a")}</span>
        </div>
      ),
    },

    {
      title: "Action",
      key: "action",
      width: "15%",
      align: "center",
      render: (record) => (
        <Space size="middle">
          {hidePermission?.isDelete ? (
            <Button
              onClick={(e) => {
                e.preventDefault();
                showDeleteConfirm(record);
              }}
            >
              <DeleteOutlined style={{ color: "#d4380d" }} />
            </Button>
          ) : null}
          <Button
            onClick={() => {
              setReasonText(record.reason); // Set reason text to display
              setVisible(true); // Show modal
            }}
          >
            <EyeOutlined style={{ color: "#52c41a" }} />
          </Button>

          {/* <ReplyToPostModal id={record?._id} /> */}
        </Space>
      ),
    },
  ];
  /////

  const showDeleteConfirm = (record: Reports) => {
    confirm({
      title: "Hold on",
      content: "Are you sure you want to delete this complaint/query?",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        handleDelete(record?._id);
        console.log("OK");
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const handleDelete = async (id: string) => {
    try {
      const res = await deleteReport({ reviewId: id }).unwrap();

      if (res?.statusCode === 200) {
        successToast("Review deleted successfully");

        getAllReportData(currentPage);
      }
    } catch (error: any) {
      console.log(error);
      errorToast(error?.data?.message);
    }
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
    getAllReportData(page);
  };

  const pagination = {
    pageSize: 10,
    current: currentPage,
    total: totalCount,
    onChange: handlePageChange,
  };

  useEffect(() => {
    const delay = 1000; // Debounce delay in milliseconds
    const timerId = setTimeout(() => {
      setQuery(debouncedQuery);
    }, delay);

    return () => {
      clearTimeout(timerId); // Clear the timeout on cleanup
    };
  }, [debouncedQuery]);

  ////////
  return (
    <div>
      <Row style={{ paddingTop: 5, paddingBottom: 20 }} gutter={16}>
        <Col xs={24} md={7}>
          <Form
            style={{ display: "flex", gap: 20 }}
            onFinish={() => getAllReportData(1)}
          >
            <Input
              placeholder="Search"
              prefix={<SearchOutlined style={{ fontSize: "24px" }} />}
              style={{ height: 50, borderRadius: 10 }}
              value={debouncedQuery}
              onChange={(e) => setDebouncedQuery(e.target.value)}
              maxLength={20}
              allowClear
            />
          </Form>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col xs={16} md={24}>
          <Table
            columns={columns}
            dataSource={allReport}
            rowKey="_id"
            loading={loading}
            pagination={pagination}
          />
        </Col>
      </Row>
      <Modal
        title="Reason"
        visible={visible}
        onCancel={() => setVisible(false)}
        footer={null}
        centered
      >
        <p>{reasonText}</p>
      </Modal>
    </div>
  );
};

export default Complaints;
