import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { DeleteOutlined, EditFilled, SearchOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Space,
  Switch,
  Tooltip,
} from "antd";
import Table, { ColumnsType } from "antd/es/table";
import moment from "moment";
import { Banners, CommonBody, Reviews } from "../../types/General";
import { useDeleteReviewMutation } from "../../services/support";
import { errorToast, successToast } from "../../helpers";
import ReplyToPostModal from "../../components/replyToPostModal/ReplyToPostModal";
import { Link, useNavigate } from "react-router-dom";
import {
  useDeleteBannerMutation,
  useUpdateBannerMutation,
  useUpdateBannerStatusMutation,
} from "../../services/banner";
import { generateEncryptedKeyBody } from "../../utils/crypto";

const { confirm } = Modal;
interface DataType {
  key: string;
  title: string;
  description: string;
  customerName: string;
  date: string;
  email: string;
  createdAt: string;
}

type Permission = {
  isAdd: boolean;
  isDelete: boolean;
};

type PropsConst = {
  activeTab: string;
  hidePermission: Permission;
  allBanners: Banners[] | [];
  currentPage: number;
  setCurrentPage: Dispatch<SetStateAction<number>>;
  totalCount: number | undefined;
  loading: boolean;
  setLoading: Dispatch<SetStateAction<boolean>>;
  query: string;
  setQuery: Dispatch<SetStateAction<string>>;
  getAllBannersData: (page: number) => void;
};

const ProductBanner = ({
  activeTab,
  hidePermission,
  allBanners,
  setCurrentPage,
  setLoading,
  totalCount,
  setQuery,
  query,
  getAllBannersData,
  currentPage,
  loading,
}: PropsConst) => {
  const navigate = useNavigate();
  const [deleteBanner] = useDeleteBannerMutation();
  const [debouncedQuery, setDebouncedQuery] = useState("");
  const [updateBannerStatus] = useUpdateBannerStatusMutation();

  console.log(allBanners);

  const handleBannerStatus = async (isBlocked: boolean, _id: string) => {
    const enabledBanners = allBanners.filter((banner) => !banner.isBlocked);

    if (
      !isBlocked &&
      enabledBanners.length === 1 &&
      enabledBanners[0]._id === _id
    ) {
      errorToast("At least one banner must be enabled.");
      return;
    }

    const data = {
      isBlocked: !isBlocked,
    };

    try {
      let encryptedBody = generateEncryptedKeyBody(data) as CommonBody;

      const res = await updateBannerStatus({
        body: encryptedBody,
        bannerId: _id,
        activeTab: 1,
      }).unwrap();
      if (res?.statusCode === 200) {
        successToast(
          !isBlocked
            ? "Banner disabled successfully"
            : "Banner enabled successfully"
        );
        getAllBannersData(1);
      }
    } catch (error: any) {
      console.log(error);
      errorToast(error?.data?.message);
    }
  };

  const columns: ColumnsType<any> = [
    {
      title: "Banner Image",
      dataIndex: "image",
      key: "image",
      align: "center",
      render: (_, record) => (
        <div>
          {record?.webImage ? (
            <img
              alt="img"
              style={{ width: 40, height: 40, borderRadius: 50 }}
              src={record?.webImage}
            />
          ) : (
            "N/A"
          )}
        </div>
      ),
    },
    {
      title: "Title",
      key: "title",
      dataIndex: "title",
      align: "center",
    },

    // {
    //   title: "Price",
    //   dataIndex: "price",
    //   key: "price",
    //   align: "center",
    // },
    {
      title: "Feature",
      dataIndex: "feature",
      key: "feature",
      align: "center",
    },
    {
      title: "Account Status",
      dataIndex: "isBlocked",
      key: "isBlocked",
      align: "center",
      render: (_, record) => (
        <div>
          <Switch
            size="small"
            checked={!record?.isBlocked}
            onChange={() => handleBannerStatus(record?.isBlocked, record?._id)}
          />
        </div>
      ),
    },
    {
      title: "Action",
      key: "action",
      align: "center",
      render: (_, record) => (
        <Space size="middle">
          <Tooltip placement="top" title={"Edit"}>
            <Button
              onClick={(e) => {
                e.preventDefault();
                navigate(`/manage-banners/${record?._id}`);
              }}
            >
              <EditFilled style={{ color: "#C69320" }} />
            </Button>
          </Tooltip>
          <Tooltip placement="top" title={"Delete"}>
            <Button
              onClick={(e) => {
                e.preventDefault();
                showDeleteConfirm(record);
              }}
            >
              <DeleteOutlined style={{ color: "#d4380d" }} />
            </Button>
          </Tooltip>
        </Space>
      ),
    },
  ];

  const showDeleteConfirm = (record: any) => {
    confirm({
      title: "Hold on",
      content: "Are you sure you want to delete this banner?",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        handleDelete(record?._id);
        console.log("OK");
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const handleDelete = async (id: string) => {
    try {
      const res = await deleteBanner({
        bannerId: id,
        activeTab: 1,
      }).unwrap();

      if (res?.statusCode === 200) {
        successToast("Banner deleted successfully");
        getAllBannersData(currentPage);
      }
    } catch (error: any) {
      console.log(error);
      errorToast(error?.data?.message);
    }
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
    getAllBannersData(page);
  };

  const pagination = {
    pageSize: 10,
    current: currentPage,
    total: totalCount,
    onChange: handlePageChange,
  };

  useEffect(() => {
    const delay = 1000; // Debounce delay in milliseconds
    const timerId = setTimeout(() => {
      setQuery(debouncedQuery);
    }, delay);

    return () => {
      clearTimeout(timerId); // Clear the timeout on cleanup
    };
  }, [debouncedQuery]);

  return (
    <div>
      <Row
        gutter={16}
        style={{
          paddingTop: 5,
          paddingBottom: 20,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Col xs={24} md={7}>
          <Form
            style={{ display: "flex", gap: 20 }}
            onFinish={() => getAllBannersData(1)}
          >
            <Input
              placeholder="Search"
              prefix={<SearchOutlined style={{ fontSize: "24px" }} />}
              style={{ height: 50, borderRadius: 10 }}
              value={debouncedQuery}
              onChange={(e) => setDebouncedQuery(e.target.value)}
              maxLength={20}
              allowClear
            />
          </Form>
        </Col>
        <Link to={`/manage-banners/add`}>
          <Button
            style={{
              background: "#ECAC35",
              minWidth: 100,
              height: 35,
              color: "black",
              fontWeight: 500,
            }}
            type="primary"
            // onClick={() => navigate(`/manage-banners/add/${activeTab}`)}
          >
            Add Banner
          </Button>
        </Link>
      </Row>
      <Row gutter={16}>
        <Col xs={16} md={24}>
          <Table
            columns={columns}
            dataSource={allBanners}
            rowKey="_id"
            pagination={pagination}
            loading={loading}
          />
        </Col>
      </Row>
    </div>
  );
};

export default ProductBanner;
