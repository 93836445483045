import { useEffect, useState } from "react";
import { Card, Tabs, TabsProps } from "antd";
import { ContentHeader } from "../../components";
import Complaints from "./Complaints";
import BookingQueries from "./BookingQueries";
import Queries from "./Queries";
import {
  useLazyGetReviewQuery,
  useLazyGetProductReportQuery,
  useDeleteReportReviewMutation,
} from "../../services/support";
import { errorToast } from "../../helpers";
import { Reviews, Reports } from "../../types/General";

const CustomerSupport = () => {
  const [getReviewData] = useLazyGetReviewQuery();
  const [getReportData] = useLazyGetProductReportQuery();

  const [activeTab, setActiveTab] = useState("1");
  const [loading, setLoading] = useState(false);
  const [allReview, setAllReview] = useState<Reviews[]>([]);
  const [allReport, setAllReport] = useState<Reports[]>([]);
  const [totalCount, setTotalCount] = useState<number>();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [query, setQuery] = useState("");

  const onChange = (key: string) => {
    setActiveTab(key);
  };
  console.log("page------", activeTab);
  enum FilterOptions {
    Option1 = 1,
    Option2 = 2,
  }

  const getAllReviewsData = async (page: number) => {
    try {
      setLoading(true);
      let filterOption: FilterOptions | undefined; // Define filterOption variable

      if (Number(activeTab) === 1) {
        filterOption = FilterOptions.Option1;
      } else if (Number(activeTab) === 3) {
        filterOption = FilterOptions.Option2;
      }
      const res = await getReviewData({
        page,
        search: query,
        filter: Number(activeTab) === 1 ? 1 : Number(activeTab) ? 2 : 0,
      }).unwrap();

      setLoading(false);
      if (res?.statusCode === 200) {
        setAllReview(res?.data?.data || []);
        setTotalCount(res?.data?.count);
      }
    } catch (error: any) {
      setLoading(false);
      errorToast(error?.data?.message || error?.data?.error);
    }
  };

  const getAllReportData = async (page: number) => {
    try {
      setLoading(true);
      const res = await getReportData({
        page,
        activeTab: Number(activeTab),
        search: query,
      }).unwrap();
      setLoading(false);
      if (res?.statusCode === 200) {
        setAllReport(res?.data?.data || []);
        setTotalCount(res?.data?.count);
      }
    } catch (error: any) {
      setLoading(false);
      errorToast(error?.data?.message || error?.data?.error);
    }
  };

  const items: TabsProps["items"] = [
    {
      key: "1",
      label: `Queries Received`,
      children: (
        <Queries
          hidePermission={{
            isAdd: true,
            isDelete: true,
          }}
          allReview={allReview}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          loading={loading}
          setLoading={setLoading}
          query={query}
          setQuery={setQuery}
          totalCount={totalCount}
          getAllReviewsData={getAllReviewsData}
        />
      ),
    },
    {
      key: "2",
      label: `Reported Products`,
      children: (
        <Complaints
          hidePermission={{
            isAdd: true,
            isDelete: true,
          }}
          allReport={allReport}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          loading={loading}
          setLoading={setLoading}
          query={query}
          setQuery={setQuery}
          totalCount={totalCount}
          getAllReportData={getAllReportData}
        />
      ),
    },
    {
      key: "3",
      label: `Resolved Queries & Reports`,
      children: (
        <BookingQueries
          hidePermission={{
            isAdd: false,
            isDelete: false,
          }}
          allReview={allReview}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          loading={loading}
          setLoading={setLoading}
          query={query}
          setQuery={setQuery}
          totalCount={totalCount}
          getAllReviewsData={getAllReviewsData}
        />
      ),
    },
  ];

  useEffect(() => {
    getAllReviewsData(1);
  }, [activeTab, query]);

  useEffect(() => {
    getAllReportData(1);
  }, [activeTab, query]);

  return (
    <div style={{ padding: 50 }}>
      <ContentHeader heading="Customer Support" />
      <Card style={{ overflowX: "scroll" }}>
        <Tabs
          activeKey={activeTab}
          defaultActiveKey="1"
          items={items}
          onChange={onChange}
        />
      </Card>
    </div>
  );
};

export default CustomerSupport;
