import { SearchOutlined } from "@ant-design/icons";
import { Card, Col, Form, Input, Row } from "antd";
import Table, { ColumnsType } from "antd/es/table";
import React, { Dispatch, SetStateAction } from "react";

interface DataType {
  key: string;
  score: string;
  nunberOfTokens: string;
  winners: string;
  rank: string;
  name: string;
  price: string;
  _id: string;
  hasFeaturedProducts: boolean;
  isHotDeal:boolean;
  isTopDeal:boolean;
  featuredProducts:any;
}

type props = {
  debouncedQuery: string;
  setDebouncedQuery: Dispatch<SetStateAction<string>>;
  columns: ColumnsType<DataType>;
  AllProductsData: any;
  loading: boolean;
  pagination: any;
};

const AdminProducts = ({
  debouncedQuery,
  setDebouncedQuery,
  columns,
  AllProductsData,
  loading,
  pagination,
}: props) => {
  return (
    <div className="srch">
      <Card style={{ overflowX: "scroll" }}>
        <Row style={{ padding: "10px 0px" }} gutter={16}>
          <Col xs={24} md={7}>
            <Form style={{ display: "flex", gap: 20 }}>
              <Input
                placeholder="Search"
                prefix={<SearchOutlined style={{ fontSize: "24px" }} />}
                style={{ height: 50, borderRadius: 10 }}
                value={debouncedQuery}
                onChange={(e) => setDebouncedQuery(e.target.value)}
                maxLength={20}
                allowClear
              />
            </Form>
          </Col>
        </Row>

        <Table
          columns={columns}
          dataSource={AllProductsData}
          rowKey="_id"
          loading={loading}
          pagination={pagination}
        />
      </Card>
    </div>
  );
};

export default AdminProducts;
