import { Col, Layout, Row, Spin } from "antd";
import { DashboardTypes } from "../../types/General";

import "./dashboard.css";
import {
  DollarCircleOutlined,
  TeamOutlined,
  UsergroupAddOutlined,
  ProfileOutlined,
} from "@ant-design/icons";
import { useLazyGetDashboardQuery } from "../../services/user";
import { DashboardCard } from "../../components";
import { useEffect, useState } from "react";
import { RevenueChart, UsersChart } from "../../components/charts";

const Dashboard = () => {
  const [getDashboardData] = useLazyGetDashboardQuery();
  const [selectedFilter, setSelectedFilter] = useState("monthly");
  const [selectedFilterUser, setSelectedFilterUser] = useState("monthly");
  const [dashboardDetails, setDashboardDetails] = useState<DashboardTypes>();
  const [name, setName] = useState<string | undefined>();
  const [revenueGraphValues, setRevenueGraphValues] = useState<{}>();
  const [graphValues, setGraphValues] = useState<{}>();
  const [loading, setLoading] = useState(false);

  const handleFilterChange = (value: any) => {
    setSelectedFilter(value);
  };
  const handleFilterChangeUser = (value: any) => {
    setSelectedFilterUser(value);
  };

  const showDataHandler = async () => {
    setLoading(true);
    try {
      const payload = await getDashboardData({
        user: selectedFilterUser,
        revenue: selectedFilter,
      }).unwrap();
      setLoading(false);
      console.log(payload?.data, "GET DASHBOARD");
      setDashboardDetails(payload?.data);

      const details = payload?.data;

      if (payload?.statusCode === 200) {
        if (name === "left") {
          setRevenueGraphValues(details?.revenuGenerated);
        } else if (name === "right") {
          setGraphValues(details?.totalUsers);
        } else {
          setGraphValues(details?.totalUsers);

          setRevenueGraphValues(details?.revenuGenerated);
        }
      }
    } catch (error) {
      setLoading(false);
      console.log(error, "error");
    }
  };

  const { Content } = Layout;

  const STATS = [
    {
      name: "Total Users",
      icon: <TeamOutlined style={{ color: "rgb(255, 170, 44)" }} />,
      number: dashboardDetails?.userCount || "0",
      prefix: "#",
    },
    {
      name: "Total Earnings",
      icon: <DollarCircleOutlined style={{ color: "green" }} />,
      number: dashboardDetails?.totalEarning || "0",
      prefix: "$",
    },
    {
      name: "Total Products listed",
      icon: <ProfileOutlined style={{ color: "rgb(255, 120, 45)" }} />,
      number: dashboardDetails?.productCount || "0",
      prefix: "#",
    },

    {
      name: "New Users in past seven days",
      icon: <UsergroupAddOutlined style={{ color: "rgb(0, 173, 163)" }} />,
      number: dashboardDetails?.newUsers || "0",
      prefix: "#",
    },
  ];

  const labels: never[] = [];

  const labels2: never[] = [];

  const graphData = {
    labels,
    datasets: [
      {
        label: "Revenue",
        data: revenueGraphValues,
        borderColor: "#7DBFDD",
        backgroundColor: "#7DBFDD",
      },
    ],
  };

  const graphCustomerData = {
    labels2,
    datasets: [
      {
        label: "Users",
        data: graphValues,
        borderColor: "#7DBFDD",
        backgroundColor: "#7DBFDD",
      },
    ],
  };

  useEffect(() => {
    showDataHandler();
  }, [selectedFilter, selectedFilterUser]);

  return (
    <Content
      style={{
        margin: "0px 10px",
        padding: 35,
        minHeight: 280,
      }}
    >
      <Spin spinning={loading}>
        <div style={{ fontSize: 18 }}>
          <h1>Dashboard</h1>
        </div>

        <div className="top_bar">
          {STATS.map((item, index) => (
            <DashboardCard data={item} key={index} />
          ))}
        </div>

        <div
          style={{
            margin: "20px 0px",
          }}
        >
          <Row gutter={16}>
            <Col lg={12} md={24} sm={24} xs={24}>
              <RevenueChart
                graphData={graphData}
                onFilterChange={handleFilterChange}
                setName={setName}
              />
            </Col>
            <Col lg={12} md={24} sm={24} xs={24}>
              <UsersChart
                graphCustomerData={graphCustomerData}
                onFilterChange={handleFilterChangeUser}
                setName={setName}
              />
            </Col>
          </Row>
        </div>
      </Spin>
    </Content>
  );
};

export default Dashboard;
