import { useEffect } from "react";
import "./App.css";
import { STORAGE_KEYS, getFromStorage } from "./helpers";

import RoutesNavigation from "./routes";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { firebaseCloudMessaging } from "./utils/firebase";

function App() {
  const navigate = useNavigate();

  useEffect(() => {
    const token = getFromStorage(STORAGE_KEYS.token);
    if (token && window?.location?.pathname === "/") {
      window?.location?.replace("/dashboard");
    } else if (!token && window?.location?.pathname === "/") {
      navigate("/", { replace: true });
    } else if (token) {
      navigate(window?.location?.pathname);
    } else {
      navigate("/", { replace: true });
    }
  }, []);

  useEffect(() => {
    // Calls the getMessage() function if the token is there
    async function setToken() {
      try {
        const fcmToken = await firebaseCloudMessaging.init();
        if (fcmToken) {
          console.log("fcm_Token", fcmToken);
        }
      } catch (error) {
        console.log(error);
      }
    }

    setToken();

    const playNotificationSound = () => {
      const audio = new Audio("/static/sound/noti.mp3"); // Specify the path to your notification sound
      audio.play();
    };
    // Event listener that listens for the push notification event in the background
    if ("serviceWorker" in navigator) {
      navigator.serviceWorker.addEventListener("message", (event) => {
        try {
          event?.source?.postMessage("Hi client");
          console.log("event for the service worker", event);
          playNotificationSound();

          return toast(
            <div style={{ height: "100%" }}>
              <div>{event?.data?.data?.title}</div>
              <div>{event?.data?.data?.message}</div>
            </div>
          );
          return event;
        } catch (e) {
          console.log(e, "error");
          return e;
        }
      });
    }

    return () => {
      if ("serviceWorker" in navigator) {
        navigator.serviceWorker.removeEventListener("message", (e) =>
          console.log(e)
        );
      }
    };
  }, []);

  return <RoutesNavigation />;
}

export default App;
