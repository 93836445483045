// @ts-nocheck

import {
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  Input,
  Layout,
  Row,
  Select,
  Upload,
  message,
} from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import dayjs, { Dayjs } from "dayjs";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { RcFile } from "antd/es/upload";
import { UploadOutlined } from "@ant-design/icons";
import { ContentHeader } from "../../../components";
import { UploadMedia } from "../../../utils/mediaUpload";
import { errorToast, successToast } from "../../../helpers";
import {
  usePostAddOrganizationMutation,
  useLazyGetOrganizationDetailsIdQuery,
  usePutUpdateOrganizationMutation,
} from "../../../services/donation";
import { useLazyGetDonationCategoryQuery } from "../../../services/category";
import { category, CommonBody } from "../../../types/General";
import { Autocomplete } from "@react-google-maps/api";

import { generateEncryptedKeyBody } from "../../../utils/crypto";

const AddOrganization = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const url = window.location.pathname;
  const path = url.includes("add");
  const { view } = useParams();
  const [addOrganization, addOrganizationMutation] =
    usePostAddOrganizationMutation();
  const [getDonationCategoryData] = useLazyGetDonationCategoryQuery();
  const [OrganizationDataById] = useLazyGetOrganizationDetailsIdQuery();
  const [updateOrganization] = usePutUpdateOrganizationMutation();

  const { Option } = Select;
  const [frontImage, setFrontImage] = useState("");
  const [backImage, setBackImage] = useState("");
  const [profileImage, setProfileImage] = useState("");
  const [countryName, setCountryName] = useState("in");
  const [phoneCode, setPhoneCode] = useState("+91");
  const [phoneNo, setPhoneNo] = useState("");
  const [docName, setDocName] = useState("");
  const [loading, setLoading] = useState(false);
  const [allCategoryData, setAllCategoryData] = useState<
    category[] | undefined
  >([]);
  const [autocomplete, setAutocomplete] = useState(null);

  const [expiryDate, setExpiryDate] = useState<dayjs.Dayjs | null>(null);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [values, setValues] = useState({
    fullName: "",
    about: "",
    email: "",
  });
  const [lat, setLat] = useState("");
  const [long, setLong] = useState("");
  const [address, setAddress] = useState("");
  const [error, setError] = useState(false);

  const onKeypress = (e: any) => {
    if (e.key === "Enter") {
      e.preventDefault();
      return false;
    }
  };

  const onLoad = (autocompleteObj: any) => {
    setAutocomplete(autocompleteObj);
  };
  const onPlaceChanged = async () => {
    if (autocomplete) {
      var place = await autocomplete.getPlace();
      if (place) {
        form.setFieldsValue({
          address: place.formatted_address,
        });
        setAddress(place.formatted_address);

        const lat = place.geometry.location.lat();
        const lng = place.geometry.location.lng();
        setLat(lat);
        setLong(lng);
      }
    }
  };

  const onFinish = async (values: any) => {
    if (!phoneNo) {
      setError(true);
      return;
    }
    if (phoneNo?.length < 6) {
      setError(true);
      return;
    }

    console.log("Received values of form: ", values);
    let data = {
      name: values?.fullName,
      email: values.email,
      countryCode: (phoneCode.includes("+") ? "" : "+") + phoneCode,
      phone: phoneNo,
      image: profileImage || "",
      donationCategory: selectedCategory,
      about: values.about,
      docName: values.docName,
      docFrontImage: frontImage,
      docBackImage: backImage,
      docExpireDate: expiryDate,
      latitude: lat?.toString(),
      longitude: long?.toString(),
      address,
    };
    if (profileImage) {
      data.image = profileImage;
    }

    try {
      let encryptedBody = generateEncryptedKeyBody(data) as CommonBody;

      // const res = await addDonationCategory(encryptedBody).unwrap();
      let res;
      path
        ? (res = await addOrganization(encryptedBody).unwrap())
        : (res = await updateOrganization({
            encryptedBody,
            organizationId: view,
          }).unwrap());

      if (res?.statusCode === 200) {
        navigate("/manage-organization", { replace: true });
        successToast(
          path
            ? "Organization added successfully"
            : "Organization updated successfully"
        );
      } else {
        errorToast(res?.message);
      }
    } catch (error: any) {
      errorToast(error?.data?.message || error?.data?.error);
    }
  };

  const handleChangePhone = (phone: any, country: any) => {
    setPhoneCode(country?.dialCode);
    setPhoneNo(phone?.replace(country.dialCode, ""));
  };

  const handleImageUpload = async (file: RcFile) => {
    if (file?.type.includes("image")) {
      try {
        const res = await UploadMedia(file);

        if (res?.statusCode === 200) {
          setProfileImage(res?.data);
        }
      } catch (error: any) {
        console.log(error);
      }
    } else {
      errorToast("Only images are allowed");
    }

    return false; // Prevent Ant Design from automatically uploading the file
  };

  const handleDocumentUpload = async (file: any, key: string) => {
    if (file?.type.includes("image")) {
      try {
        const res = await UploadMedia(file);
        if (res?.statusCode === 200) {
          if (key === "front") {
            setFrontImage(res?.data);
          } else {
            setBackImage(res?.data);
          }
        }
      } catch (error: any) {
        console.log(error);
      }
    } else {
      errorToast("Only images are allowed");
    }
  };

  const handleDateChange = (date: dayjs.Dayjs | null, dateString: string) => {
    setExpiryDate(date);
  };
  const disabledDate = (current: Dayjs | null | any) => {
    return current && current.isBefore(dayjs(), "day");
  };

  const getCategoryDetails = async (page: number) => {
    try {
      setLoading(true);
      const res = await getDonationCategoryData({
        page: page,
        size: 10,
        search: "",
      }).unwrap();
      setLoading(false);

      if (res?.statusCode === 200) {
        setAllCategoryData(res?.data?.donationCategory);
      }
    } catch (error: any) {
      setLoading(false);
      console.log(error);
    }
  };

  const handleChangeForSelectedCategory = (value: any) => {
    form.setFieldsValue({
      donationCategory: value,
    });
    setSelectedCategory(value);

    form.validateFields(["donationCategory"]);
  };

  const getOrganizationDetailsById = async () => {
    try {
      const res = await OrganizationDataById(view).unwrap();

      if (res?.statusCode === 200) {
        setValues({
          ...values,
          email: res?.data?.email || "",
          fullName: res?.data?.name || "",
          about: res?.data?.about || "",
        });
        setPhoneCode(res?.data?.countryCode);
        setPhoneNo(res?.data?.phone);
        setProfileImage(res?.data?.image);
        setBackImage(res?.data?.docBackImage);
        setFrontImage(res?.data?.docFrontImage);
        setDocName(res?.data?.docName);
        setExpiryDate(dayjs(res?.data?.docExpireDate));
        setSelectedCategory(res?.data?.donationCategory);
        setAddress(res?.data?.address);

        form.setFieldsValue({
          email: res?.data?.email,
          fullName: res?.data?.name,
          phone: res?.data?.phone,
          backImage: res?.data?.docBackImage,
          frontImage: res?.data?.docFrontImage,
          docName: res?.data?.docName,
          about: res?.data?.about,
          expiryDate: res?.data?.docExpireDate,
          donationCategory: res?.data?.donationCategory,
          address: res?.data?.address,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getCategoryDetails(1);
  }, []);

  useEffect(() => {
    if (!path) {
      getOrganizationDetailsById();
    }
  }, []);

  useEffect(() => {
    form.setFieldsValue({
      expiryDate: expiryDate,
      donationCategory: selectedCategory,
    });
  }, [expiryDate, selectedCategory]);

  return (
    <Layout>
      <Layout className="site-layout">
        {/* <HeaderBar collapsed={collapsed} setCollapsed={setCollapsed} /> */}
        <div style={{ padding: 50 }}>
          <ContentHeader
            buttonText="Back"
            buttonNavigateTo="manage-organization"
            heading="Add Organization"
          />
          <Card>
            <div>
              <Form
                name="basic"
                layout="vertical"
                form={form}
                // labelCol={{ span: 8 }}
                // wrapperCol={{ span: 16 }}
                style={{
                  maxWidth: "100%",
                  marginTop: 10,
                }}
                initialValues={{ remember: true }}
                onFinish={onFinish}
                //   onFinishFailed={onFinishFailed}
                autoComplete="off"
              >
                <Row gutter={16}>
                  <Col xs={24} md={12}>
                    <Form.Item name="profileImage">
                      <Upload
                        beforeUpload={handleImageUpload}
                        showUploadList={false}
                        className="upload_imgD"
                        accept="image/*"
                      >
                        <div>
                          <h4>Upload Banner Image</h4>
                          <Button icon={<UploadOutlined />}>
                            Click to upload
                          </Button>
                        </div>
                        {profileImage ? (
                          <img
                            className="img_profile"
                            src={profileImage}
                            alt="profile"
                          />
                        ) : (
                          <></>
                        )}
                      </Upload>
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col xs={24} md={12}>
                    <Form.Item
                      name="fullName"
                      rules={[
                        {
                          required: true,
                          message: "Please input your Organization name!",
                          whitespace: true,
                        },
                        {
                          min: 3,
                          message: "Name must be at least 3 characters",
                        },
                      ]}
                    >
                      <div>
                        <h4>Organization Name</h4>
                        <Input
                          placeholder="Organization Name"
                          style={{ height: 55 }}
                          value={values?.fullName}
                          onChange={(e) => {
                            setValues({ ...values, fullName: e.target.value });
                          }}
                        />
                      </div>
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={12}>
                    <Form.Item
                      // label="Username"
                      name="donationCategory"
                      rules={[
                        {
                          required: true,
                          message: "Please select Category of Donation",
                        },
                      ]}
                    >
                      <h4>Select Category</h4>
                      <div>
                        <Select
                          allowClear
                          maxLength={20}
                          value={selectedCategory ? selectedCategory : null}
                          onChange={(val) =>
                            handleChangeForSelectedCategory(val)
                          }
                          placeholder="Select Category"
                          // style={{ width: 120 }}
                          optionFilterProp="label"
                          showSearch
                          filterOption={(input, option) =>
                            (
                              option?.label?.toString()?.toLowerCase() || ""
                            ).indexOf(input.toLowerCase()) !== -1
                          }
                        >
                          {allCategoryData?.map((item, index) => (
                            <Option
                              key={index}
                              value={item?._id}
                              label={item?.name}
                            >
                              {item?.name}
                            </Option>
                          ))}
                        </Select>
                      </div>
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col xs={24} md={12}>
                    <Form.Item
                      name="email"
                      rules={[
                        {
                          required: true,
                          message: "Please input your E-mail!",
                        },
                        {
                          type: "email",
                          message: "The input is not valid E-mail!",
                        },
                      ]}
                    >
                      <div>
                        <h4>Email</h4>
                        <Input
                          // disabled
                          style={{ height: 55 }}
                          placeholder="E-mail"
                          value={values.email}
                          onChange={(e) =>
                            setValues({ ...values, email: e.target.value })
                          }
                        />
                      </div>
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={12}>
                    <Form.Item
                      name="phone"
                      // rules={[
                      //   {
                      //     required: true,
                      //     message: "Please input your phone number!",
                      //   },
                      // ]}
                    >
                      <div>
                        <h4>Phone Number</h4>
                        <div className="phn_cs">
                          <PhoneInput
                            value={phoneCode + phoneNo}
                            enableSearch={true}
                            placeholder="Phone number"
                            containerClass={"cntn_telCls"}
                            inputClass={"cstm_cls"}
                            buttonClass={"custm_btn"}
                            onChange={(phone, country) =>
                              handleChangePhone(phone, country)
                            }
                          />
                        </div>
                        {error && !phoneNo ? (
                          <h3 className="error_msg">
                            Please input your phone number!
                          </h3>
                        ) : null}
                        {error && phoneNo?.length && phoneNo?.length < 6 ? (
                          <h3 className="error_msg">
                            Please input valid phone number!
                          </h3>
                        ) : null}
                      </div>
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col xs={24} md={12}>
                    <Form.Item
                      // label="Username"
                      name="about"
                      rules={[
                        {
                          required: true,
                          message: "Please input your description!",
                        },
                        {
                          min: 10,
                          message: "Description must be at least 10 characters",
                        },
                      ]}
                    >
                      <div>
                        <h4>About</h4>
                        <Input
                          value={values.about}
                          onChange={(e) =>
                            setValues({ ...values, about: e.target.value })
                          }
                          placeholder="About"
                          style={{ height: 55 }}
                        />
                      </div>
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={12}>
                    <Form.Item
                      name="address"
                      rules={[
                        {
                          required: true,
                          message: "Please input your Address!",
                          whitespace: true,
                        },
                        {
                          min: 3,
                          message: "Address must be at least 3 characters",
                        },
                      ]}
                    >
                      <Autocomplete
                        onLoad={onLoad}
                        onPlaceChanged={onPlaceChanged}
                      >
                        <div>
                          <h4>Address</h4>
                          <Input
                            allowClear
                            value={address}
                            placeholder="Address"
                            style={{ height: 55 }}
                            onChange={(e) => {
                              setAddress(e.target.value);
                            }}
                            onKeyDown={onKeypress}
                          />
                        </div>
                      </Autocomplete>
                    </Form.Item>
                  </Col>
                </Row>

                <h3>Upload Document</h3>
                <Row gutter={16}>
                  <Col xs={24} md={12}>
                    <Form.Item
                      name="docName"
                      rules={[
                        {
                          required: true,
                          message: "Please input your document name!",
                        },
                        {
                          min: 2,
                          message:
                            "Document name must be at least 2 characters",
                        },
                      ]}
                    >
                      <div>
                        <h4>Name of document</h4>
                        <Input
                          // disabled
                          style={{ height: 55 }}
                          placeholder="Name of document"
                          value={docName}
                          onChange={(e) => setDocName(e.target.value)}
                        />
                      </div>
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={12}>
                    <Form.Item
                      name="expiryDate"
                      rules={[
                        {
                          required: true,
                          message: "This field is compulsory",
                        },
                      ]}
                      validateTrigger={["onChange", "onBlur", "allowClear"]}
                    >
                      <div>
                        <h4>Expiry Date</h4>
                        <DatePicker
                          style={{ width: "100%", height: 55 }}
                          onChange={handleDateChange}
                          format="YYYY-MM-DD"
                          allowClear={false}
                          value={expiryDate}
                          disabledDate={disabledDate}
                        />
                      </div>
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={16}>
                  <Col xs={24} md={12}>
                    <Form.Item
                      name="frontImage"
                      rules={[
                        {
                          required: true,
                          message: "Please upload front image",
                        },
                      ]}
                    >
                      <Upload
                        beforeUpload={(file) =>
                          handleDocumentUpload(file, "front")
                        }
                        showUploadList={false}
                        className="upload_imgD"
                        accept="image/*"
                      >
                        <div>
                          <h4>Upload Front Image</h4>
                          <Button icon={<UploadOutlined />}>
                            Click to upload
                          </Button>
                        </div>
                        {frontImage ? (
                          <img
                            className="edt_prlimg"
                            src={frontImage}
                            alt="profile"
                          />
                        ) : (
                          <></>
                        )}
                      </Upload>
                    </Form.Item>
                  </Col>

                  <Col xs={24} md={12}>
                    <Form.Item
                      name="backImage"
                      rules={[
                        {
                          required: true,
                          message: "Please upload back image",
                        },
                      ]}
                    >
                      <Upload
                        beforeUpload={(file) =>
                          handleDocumentUpload(file, "back")
                        }
                        showUploadList={false}
                        className="upload_imgD"
                        accept="image/*"
                      >
                        <div>
                          <h4>Upload Back Image</h4>
                          <Button icon={<UploadOutlined />}>
                            Click to upload
                          </Button>
                        </div>
                        {backImage ? (
                          <img
                            className="edt_prlimg"
                            src={backImage}
                            alt="profile"
                          />
                        ) : (
                          <></>
                        )}
                      </Upload>
                    </Form.Item>
                  </Col>
                </Row>

                <Form.Item wrapperCol={{ offset: 4, span: 16 }}>
                  <div style={{ display: "flex" }}>
                    <Button
                      loading={addOrganizationMutation?.isLoading}
                      onClick={() => {
                        setError(true);
                      }}
                      style={{
                        background: "#ECAC35",
                        width: 200,
                        height: 50,
                        margin: "auto",
                        color: "black",
                      }}
                      type="primary"
                      htmlType="submit"
                    >
                      {path ? "Add" : "Save"}
                    </Button>
                  </div>
                </Form.Item>
              </Form>
            </div>
          </Card>
        </div>
      </Layout>
    </Layout>
  );
};

export default AddOrganization;
