import { Button, Card, Col, Row } from "antd";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { errorToast } from "../../helpers";
import { useLazyBoosterSubscriptionQuery } from "../../services/memberships";

const BoosterDetails = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const [loading, setLoading] = useState(false);
  const [planDetails, setPlanDetails] = useState<any>();
  const [getPlanDetailsById] = useLazyBoosterSubscriptionQuery();
  const [data, setData] = useState<any>();
  console.log(data, "==");

  const getOrderById = async () => {
    setLoading(true);
    try {
      const response = await getPlanDetailsById({
        size: 50,
        page: 1,
        search: "",
      }).unwrap();
      if (response?.statusCode === 200) {
        setLoading(false);
        setPlanDetails(response?.data?.boosterSusbcribedPlans);
        const obj = response?.data?.boosterSusbcribedPlans?.find(
          (item: any) => item?._id === id
        );
        setData(obj);
      } else {
        setPlanDetails("");
      }
    } catch (error: any) {
      setLoading(false);
      errorToast(error?.data?.message || "");
    }
  };

  useEffect(() => {
    getOrderById();
  }, []);

  return (
    <div style={{ padding: 50, gap: 20, display: "grid" }}>
      <Card loading={loading}>
        <div
          style={{
            alignItems: "center",
            display: "flex",
            justifyContent: "space-between",
            flexWrap: "wrap",
          }}
        >
          <h2>Booster Subscription Details</h2>
          <Button
            style={{
              background: "#ECAC35",
              width: 100,
              height: 40,
              color: "black",
            }}
            type="primary"
            // htmlType="submit"
            onClick={() => {
              navigate("/booster-subscriptions", { replace: true });
            }}
          >
            Back
          </Button>
        </div>

        <div>
          <Row gutter={16}>
            <Col xs={20} md={8}>
              <h4>User Name</h4>
              <h5>{data?.user?.fullName || "--"}</h5>
            </Col>
            <Col xs={20} md={8}>
              <h4>Email</h4>
              <h5>{data?.user?.email || "--"}</h5>
            </Col>
            <Col xs={20} md={8}>
              <h4>Plan Name</h4>
              <h5>{data?.boosterPlan?.planName || "--"}</h5>
            </Col>
            <Col xs={20} md={8}>
              <h4>Price</h4>
              <h5>$ {data?.boosterPlan?.price || "--"}</h5>
            </Col>
            <Col xs={20} md={8}>
              <h4>Validity</h4>
              <h5>{data?.boosterPlan?.validity || "--"}</h5>
            </Col>
            <Col xs={20} md={8}>
              <h4>Status</h4>
              <h5>
                {data?.status == 0
                  ? "Cancelled"
                  : data?.status == 1
                  ? "Active"
                  : "Expired" || "--"}
              </h5>
            </Col>
          </Row>

          <Row>
            <Col xs={15} md={9}>
              <h4>Description</h4>
              <h5>{data?.boosterPlan?.description || "--"}</h5>
            </Col>
            <Col xs={15} md={9}>
              <h4>Feature</h4>
              <h5>{data?.boosterPlan?.feature || "-"}</h5>
            </Col>
          </Row>
        </div>
      </Card>
    </div>
  );
};

export default BoosterDetails;
