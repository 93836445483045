import { Button, Card, Col, Row } from "antd";
import { useNavigate } from "react-router-dom";
import { EventUsersTable } from "../../../components/tables";

const imagesArr = [
  {
    id: 1,
    img: "/static/images/selfie.png",
  },
  {
    id: 2,
    img: "/static/images/selfie.png",
  },
  {
    id: 3,
    img: "/static/images/selfie.png",
  },
];

const ViewDetailsOfEvent = () => {
  const navigate = useNavigate();

  return (
    <div style={{ padding: 50, gap: 20, display: "grid" }}>
      <Card style={{ overflowX: "scroll" }}>
        <div
          style={{
            alignItems: "center",
            display: "flex",
            justifyContent: "space-between",
            flexWrap: "wrap",
          }}
        >
          <h2>Event Details</h2>
          <Button
            style={{
              background: "#ECAC35",
              width: 100,
              height: 40,
              color: "black",
            }}
            type="primary"
            // htmlType="submit"
            onClick={() => {
              navigate("/booking-order-management", { replace: true });
            }}
          >
            Back
          </Button>
        </div>

        <div>
          <Row gutter={16}>
            <Col xs={20} md={8}>
              <h4>Event Name</h4>
              <h5>Meeting</h5>
            </Col>
            <Col xs={20} md={8}>
              <h4>Event Category</h4>
              <h5>Music</h5>
            </Col>
            <Col xs={20} md={8}>
              <h4>Event Description</h4>
              <h5>Meeting hosting</h5>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col xs={20} md={8}>
              <h4>Event Date Time</h4>
              <h5>28 Sep, 10:00pm</h5>
            </Col>
            <Col xs={20} md={8}>
              <h4>Host Name</h4>
              <h5>Emma Watson</h5>
            </Col>
            <Col xs={20} md={8}>
              <h4>Location</h4>
              <h5>New Delhi</h5>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col xs={20} md={8}>
              <h4>Ticket Number</h4>
              <h5>200</h5>
            </Col>
            <Col xs={20} md={8}>
              <h4>Ticket Price</h4>
              <h5>$20</h5>
            </Col>
            <Col xs={20} md={8}>
              <h4>Number Of Seats</h4>
              <h5>20</h5>
            </Col>
          </Row>
        </div>
        <h2>Images, and videos for the event</h2>
        <div>
          <Row gutter={16}>
            <Col xs={24} md={12}>
              <div
                style={{
                  display: "flex",
                  gap: 20,
                }}
              >
                {imagesArr?.map((item, index) => (
                  <img
                    key={index}
                    style={{
                      height: 200,
                      borderRadius: 10,
                      width: 200,
                      // margin: "auto",
                    }}
                    src={item?.img}
                    alt=""
                  />
                ))}
              </div>
            </Col>
          </Row>
        </div>
        <h2>Event Rules</h2>

        <Row gutter={16}>
          <Col xs={24} md={12}>
            <h4>Booking Rules</h4>
            <h5>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s,
            </h5>
          </Col>
          <Col xs={24} md={12}>
            <h4>Vendor Rules</h4>
            <h5>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s
            </h5>
          </Col>
        </Row>
        <Row gutter={16}>
          {" "}
          <Col xs={24} md={12}>
            <h4>Cancellation Rules</h4>
            <h5>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s
            </h5>
          </Col>
        </Row>
      </Card>
      <Card>
        <EventUsersTable />
      </Card>
    </div>
  );
};

export default ViewDetailsOfEvent;
