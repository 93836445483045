import { useEffect, useState } from "react";
import {
  Card,
  Col,
  Input,
  Row,
  Button,
  Space,
  Modal,
  Form,
  Checkbox,
  TabsProps,
  Tabs,
  Select,
  Image,
} from "antd";
import { ContentHeader } from "../../components";
import {
  DeleteOutlined,
  EditFilled,
  EyeOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import Table, { ColumnsType } from "antd/es/table";
import { useLocation, useNavigate } from "react-router-dom";

import {
  useLazyGetProductQuery,
  useDeleteProductMutation,
  useFeatureProductMutation,
  usePutChangeProductStatusMutation,
  useFeatureProductUpdateMutation,
} from "../../services/product";
import { errorToast, successToast } from "../../helpers";
import useAuth from "../../hooks/useAuth";
import AdminProducts from "./AdminProducts";
import UserProducts from "./UserProducts";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import { CommonBody } from "../../types/General";

const { confirm } = Modal;

const ListedProductPost = () => {
  const location = useLocation();
  const { state } = location;
  const { Option } = Select;
  console.log(state);
  const [form] = Form.useForm();
  const [activeTab, setActiveTab] = useState("1");
  console.log(activeTab);
  const [changeUserRequest] = usePutChangeProductStatusMutation();
  const [getProduct, getProductData] = useLazyGetProductQuery();
  const [featureProductMutation] = useFeatureProductMutation();
  const [DealsMutation] = useFeatureProductUpdateMutation();
  const [deleteProduct] = useDeleteProductMutation();
  const [AllProductsData, setAllProductsData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [query, setQuery] = useState("");
  const [debouncedQuery, setDebouncedQuery] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("1");

  const [hidePermission, setHidePermission] = useState<any>({
    isAdd: true,
    isDelete: true,
  });

  const notificationTypes = [
    { name: "Pending", id: "1" },
    { name: "Accept", id: "2" },
    { name: "Reject", id: "3" },
  ];

  const userData = useAuth();

  const handleRequestStatus = async (value: any, id: string) => {
    const data = {
      approvedByAdmin: Number(value),
    };
    let body = generateEncryptedKeyBody(data) as CommonBody;

    try {
      const response = await changeUserRequest({
        userId: id,
        encryptedBody: body,
      }).unwrap();
      if (response?.statusCode === 200) {
        successToast("Request changed successfully");
        getProductDetails(1);
      }
    } catch (error: any) {
      errorToast(error?.data?.message || "");
    }
  };

  const onChange = (key: string) => {
    setActiveTab(key);
    setAllProductsData([]);
  };

  const navigate = useNavigate();

  const getProductDetails = async (page: number) => {
    try {
      setLoading(true);
      const res = await getProduct({
        page: page,
        size: 10,
        search: query || "",
        type: activeTab === "1" ? "admin" : "",
        userType:
          activeTab === "2"
            ? 1
            : activeTab === "3"
            ? 2
            : activeTab === "4"
            ? 3
            : "",
      }).unwrap();
      setLoading(false);
      if (res?.statusCode === 200) {
        setAllProductsData(res?.data?.product);
        setTotalCount(res?.data?.count);
      }
    } catch (error: any) {
      setLoading(false);
      console.log(error);
    }
  };

  const handleChangeForSelectedCategory = (value: any) => {
    form.setFieldsValue({
      notificationCategory: value,
    });
    setSelectedCategory(value);
  };

  const showDeleteConfirm = (record: any) => {
    confirm({
      title: "Hold on",
      content: "Are you sure delete this product?",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        handleDelete(record?._id);
        console.log("OK");
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const handleDelete = async (id: string) => {
    try {
      const res = await deleteProduct({ productId: id }).unwrap();

      if (res?.statusCode === 200) {
        successToast("Product Deleted Successfully");

        getProductDetails(currentPage);
      }
    } catch (error: any) {
      console.log(error);
      errorToast(error?.data?.message);
    }
  };

  const handleFeature = async (id: string, currentStatus: boolean) => {
    try {
      let body={
        status:!currentStatus
      }
      const encryptedBody=generateEncryptedKeyBody(body)
      const res = await DealsMutation({ productId: id,body:encryptedBody }).unwrap();

      if (res?.statusCode === 200) {
        const message = currentStatus
          ? "Product Unefatured Successfully"
          : "Product Featured Successfully";

        successToast(message);

        getProductDetails(currentPage);
      }
    } catch (error: any) {
      console.log(error);
      errorToast(error?.data?.message);
    }
  };

  const handleHotDeal = async (id: string, currentStatus: boolean) => {
    try {
      let body={
        isHotDeal:!currentStatus
      }
      const encryptedBody=generateEncryptedKeyBody(body)
      console.log(body,"body")
      const res = await DealsMutation({ productId: id,body:encryptedBody }).unwrap();

      if (res?.statusCode === 200) {
        const message = currentStatus
          ? "Product removed from hot deal"
          : "Product added to hot deal";

        successToast(message);

        getProductDetails(currentPage);
      }
    } catch (error: any) {
      console.log(error);
      errorToast(error?.data?.message);
    }
  };
  const handleTopDeal = async (id: string, currentStatus: boolean) => {
    try {
      let body={
        isTopDeal:!currentStatus
      }
      console.log(body,"body")
      const encryptedBody=generateEncryptedKeyBody(body)
      const res = await DealsMutation({ productId: id,body:encryptedBody }).unwrap();

      if (res?.statusCode === 200) {
        const message = currentStatus
          ? "Product removed from top deal"
          : "Product added to top deal";

        successToast(message);

        getProductDetails(currentPage);
      }
    } catch (error: any) {
      console.log(error);
      errorToast(error?.data?.message);
    }
  };

  const checkPermission = () => {
    const permissions = userData?.permissions;
    if (permissions?.length) {
      let idx = -1;
      idx = permissions?.findIndex(
        (ele: any) => ele?.label === "Listed Products Posts"
      );

      if (idx != -1) {
        let data = {
          isAdd: permissions[idx]?.isAdd,
          isDelete: permissions[idx]?.isDelete,
        };
        setHidePermission(data);
      }
    }
  };

  useEffect(() => {
    if (userData && userData?.permissions?.length) {
      checkPermission();
    }
  }, [userData]);

  interface DataType {
    key: string;
    score: string;
    nunberOfTokens: string;
    winners: string;
    rank: string;
    name: string;
    price: string;
    _id: string;
    hasFeaturedProducts: boolean;
    isTopDeal:boolean;
    isHotDeal:boolean;
    featuredProducts:any
  }

  const columns: ColumnsType<DataType> = [
    {
      title: "Images",
      dataIndex: "images",
      key: "images",
      // width: "45%",
      align: "center",
      render: (_, { images }: any) => (
        <div className="proImages">
          <Image.PreviewGroup
            preview={{
              onChange: (current, prev) =>
                console.log(`current index: ${current}, prev index: ${prev}`),
            }}
          >
            {" "}
            {images.map((image: string, index: number) => (
              <Image
                key={index}
                alt={`image-${index}`}
                style={{
                  height: 70,
                  width: 70,
                  borderRadius: 100,
                  marginRight: 5,
                }}
                src={image || "/static/images/dummy.png"}
              />
            ))}
          </Image.PreviewGroup>
        </div>
      ),
    },
    {
      title: "Product name",
      dataIndex: "name",
      key: "name",
      align: "center",
      render: (_, { name }: any) => (
        <div>
          <span>{`${name || "-"}`}</span>
        </div>
      ),
    },
    {
      title: "Price",
      key: "rank",
      dataIndex: "rank",
      align: "center",
      render: (_, { price }) => (
        <div>
          <span>{`${price || "-"}`}</span>
        </div>
      ),
    },
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
      align: "center",
      render: (_, { category }: any) => (
        <div>
          <span>{`${category?.name || "-"}`}</span>
        </div>
      ),
    },
    {
      title: "Sub Category",
      dataIndex: "subCategoryId",
      key: "subCategoryId",
      align: "center",
      render: (_, { subcategory }: any) => (
        <div>
          <span>{`${subcategory?.name || "-"}`}</span>
        </div>
      ),
    },

    activeTab === "2"
      ? {
          title: "Request",
          dataIndex: "request",
          key: "request",
          width: "15%",
          align: "center",
          render: (_, record) => (
            <div>
              <Select
                maxLength={20}
                className="selector_type"
                onChange={(val) => handleRequestStatus(val, record?._id)}
                value={selectedCategory}
                style={{ height: 75, width: "100%" }}
                placeholder="Target"
              >
                {notificationTypes?.map((item, index) => (
                  <Option key={index} value={item?.id} label={item?.name}>
                    {item?.name}
                  </Option>
                ))}
              </Select>
            </div>
          ),
        }
      : {},
    {
      title: "Action",
      key: "action",
      align: "center",
      render: (_, record) => (
        <Space size="middle">
          <Button
            onClick={(e) => {
              e.preventDefault();
              const stateObj =
                activeTab === "2"
                  ? { user: "pending" }
                  : activeTab === "3"
                  ? { user: "user" }
                  : activeTab === "4"
                  ? { user: "reject" }
                  : {};
              navigate(`/listed-products-posts/details/${record?._id}`, {
                state: { stateObj: stateObj, page: currentPage },
              });
            }}
          >
            <EyeOutlined style={{ color: "#52c41a" }} />
          </Button>

          {hidePermission?.isAdd && activeTab === "1" ? (
            <Button
              onClick={(e) => {
                e.preventDefault();

                navigate(`/listed-products-posts/edit/${record?._id}`, {
                  state: { page: currentPage },
                });
              }}
            >
              <EditFilled style={{ color: "#C69320" }} />
            </Button>
          ) : (
            ""
          )}
          {hidePermission?.isDelete ? (
            <Button
              onClick={(e) => {
                e.preventDefault();
                showDeleteConfirm(record);
              }}
            >
              <DeleteOutlined style={{ color: "#d4380d" }} />
            </Button>
          ) : (
            ""
          )}
        </Space>
      ),
    },
    activeTab === "3" || activeTab === "1"
      ? {
          title: "Feature product",
          key: "action",
          align: "center",
          render: (_, record) => (
            <Space size="middle">
              
              <Checkbox
                checked={record?.featuredProducts?.status}
                onChange={(e) => {
                  e.preventDefault();
                  handleFeature(record?._id, record?.featuredProducts?.status);
                }}
              />
            </Space>
          ),
        }
      : {},
      activeTab === "3" || activeTab === "1" ? {
        title: "Top Deals",
          key: "action",
          align: "center",
          render: (_, record) => (
            <Space size="middle">
              <Checkbox
                checked={record?.featuredProducts?.isTopDeal}
                onChange={(e) => {
                  e.preventDefault();
                  handleTopDeal(record?._id, record?.featuredProducts?.isTopDeal);
                }}
              />
            </Space>
          ),
      }:{},
      activeTab === "3" || activeTab === "1" ? {
        title: "Hot Deals",
          key: "action",
          align: "center",
          render: (_, record) => (
            <Space size="middle">
              
              <Checkbox
                checked={record?.featuredProducts?.isHotDeal}
                onChange={(e) => {
                  e.preventDefault();
                  handleHotDeal(record?._id, record?.featuredProducts?.isHotDeal);
                }}
              />
            </Space>
          ),
      }:{}
  ];

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
    getProductDetails(page);
  };

  const pagination = {
    pageSize: 10,
    current: currentPage,
    total: totalCount,
    onChange: handlePageChange,
  };

  useEffect(() => {
    const delay = 1000;
    const timerId = setTimeout(() => {
      setQuery(debouncedQuery);
    }, delay);

    return () => {
      clearTimeout(timerId);
    };
  }, [debouncedQuery]);

  useEffect(() => {
    // getProductDetails(currentPage);
    if (state?.page) {
      setCurrentPage(state?.page);
      getProductDetails(state?.page);
    } else {
      getProductDetails(currentPage);
    }
  }, [query, activeTab]);

  // useEffect(() => {
  //   if (state?.page) {
  //     setCurrentPage(state?.page);
  //   }
  // }, []);

  const items: TabsProps["items"] = [
    {
      key: "1",
      label: `Admin`,
      children: (
        <AdminProducts
          debouncedQuery={debouncedQuery}
          setDebouncedQuery={setDebouncedQuery}
          columns={columns}
          AllProductsData={AllProductsData}
          loading={loading}
          pagination={pagination}
        />
      ),
    },
    {
      key: "2",
      label: `Pending Products`,
      children: (
        <UserProducts
          debouncedQuery={debouncedQuery}
          setDebouncedQuery={setDebouncedQuery}
          columns={columns}
          AllProductsData={AllProductsData}
          loading={loading}
          pagination={pagination}
        />
      ),
    },
    {
      key: "3",
      label: `User Products`,
      children: (
        <UserProducts
          debouncedQuery={debouncedQuery}
          setDebouncedQuery={setDebouncedQuery}
          columns={columns}
          AllProductsData={AllProductsData}
          loading={loading}
          pagination={pagination}
        />
      ),  
    },
    {
      key: "4",
      label: `Rejected Products`,
      children: (
        <UserProducts
          debouncedQuery={debouncedQuery}
          setDebouncedQuery={setDebouncedQuery}
          columns={columns}
          AllProductsData={AllProductsData}
          loading={loading}
          pagination={pagination}
        />
      ),
    },
  ];

  useEffect(() => {
    if (state?.stateObj?.type === "second") {
      setActiveTab("2");
    } else if (state?.stateObj?.type === "third") {
      setActiveTab("3");
    } else if (state?.stateObj?.type === "fourth") {
      setActiveTab("4");
    }
   
  }, []);

  return (
    <div style={{ padding: 50 }}>
      <ContentHeader
        heading="Listed Product Post"
        buttonText="Add Product"
        buttonNavigateTo="listed-products-posts/add"
      />

      <Tabs
        activeKey={activeTab}
        defaultActiveKey="1"
        items={items}
        onChange={onChange}
      />
    </div>
  );
};

export default ListedProductPost;
