import { Button, Card, Col, Image, Row, Space } from "antd";
import Table, { ColumnsType } from "antd/es/table";
import moment from "moment";
import { useEffect, useState } from "react";
import { LazyLoadImage, ScrollPosition } from "react-lazy-load-image-component";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  useLazyGetUserDetailsIdQuery,
  useLazyGetProductsByUserIdQuery,
  useLazyGetReportsByIdQuery,
} from "../../../services/user";
import { Customers } from "../../../types/User";

interface DataType {
  _id: any;
  key: string;
  name: string;
  phone: string;
  userName: string;
  email: string;
  status: boolean;
}
interface DataType2 {
  _id: any;
  key: string;
  name: string;
  phone: string;
  userName: string;
  email: string;
  status: boolean;
}

const UserDetails = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const location = useLocation();
  const { state } = location;

  const [getUser, getUserData] = useLazyGetUserDetailsIdQuery();
  const [getReport, getReportData] = useLazyGetReportsByIdQuery();
  const [report, setReport] = useState<any>();
  const [productById, productByIdQuery] = useLazyGetProductsByUserIdQuery();
  const [product, setProduct] = useState<any>();
  const [userDetail, setUserDetail] = useState<Customers>();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalCount, setTotalCount] = useState<number | undefined>();

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
    getProductDetails(page);
  };

  const getUserDetails = async () => {
    try {
      const res = await getUser(id).unwrap();

      if (res?.statusCode === 200) {
        setUserDetail(res?.data);
      }
    } catch (error: any) {
      console.log(error);
    }
  };

  const getProductDetails = async (page: number) => {
    try {
      const res = await productById({ userId: id, page: page }).unwrap();

      if (res?.statusCode === 200) {
        setProduct(res?.data?.products);
        setTotalCount(res?.data?.count);
      }
    } catch (error: any) {
      console.log(error);
    }
  };

  const getReportDetails = async () => {
    try {
      const res = await getReport(id).unwrap();

      if (res?.statusCode === 200) {
        setReport(res?.data?.data);
      }
    } catch (error: any) {
      console.log(error);
    }
  };

  const columns: ColumnsType<DataType> = [
    {
      title: "S.no",
      dataIndex: "serialNumber",
      key: "serialNumber",
      align: "center",
      render: (_, __, index) => <span>{index + 1}</span>,
    },
    {
      title: "Images",
      dataIndex: "images",
      key: "images",
      width: "45%",
      align: "center",
      render: (_, { images }: any) => (
        <div>
          <Image.PreviewGroup
            preview={{
              onChange: (current, prev) =>
                console.log(`current index: ${current}, prev index: ${prev}`),
            }}
          >
            {" "}
            {images.map((image: string, index: number) => (
              <Image
                key={index}
                alt={`image-${index}`}
                style={{
                  height: 70,
                  width: 70,
                  borderRadius: 100,
                  marginRight: 5,
                }}
                src={image || "/static/images/dummy.png"}
              />
            ))}
          </Image.PreviewGroup>
        </div>
      ),
    },
    {
      title: "Full Name",
      dataIndex: "firstName",
      key: "firstName",
      align: "center",
      render: (_, { name }: any) => (
        <div>
          <span>{`${name}`}</span>
        </div>
      ),
    },

    {
      title: "Price",
      dataIndex: "phone",
      key: "phone",
      align: "center",
      render: (_, { price }: any) => (
        <div>
          <span>{`${price}`}</span>
        </div>
      ),
    },
    {
      title: "Category",
      dataIndex: "email",
      key: "email",
      align: "center",
      render: (_, { categoryName }: any) => (
        <div>
          <span>{`${categoryName || "N/A"}`}</span>
        </div>
      ),
    },
  ];

  const columns2: ColumnsType<DataType2> = [
    {
      title: "Reported By",
      dataIndex: "firstName",
      key: "firstName",
      align: "center",
      render: (_, { user }: any) => (
        <div>
          <span>{`${user?.fullName || "-"}`}</span>
        </div>
      ),
    },

    {
      title: "Reason",
      dataIndex: "phone",
      key: "phone",
      align: "center",
      render: (_, { reason }: any) => (
        <div>
          <span>{`${reason}`}</span>
        </div>
      ),
    },
    {
      title: "Reported on",
      dataIndex: "email",
      key: "email",
      align: "center",
      render: (_, { createdAt }: any) => (
        <div>
          <span> {moment(createdAt).format("MM-D-YY / h:mm A")}</span>
        </div>
      ),
    },
  ];

  useEffect(() => {
    getUserDetails();
  }, []);

  useEffect(() => {
    getProductDetails(1);
  }, []);

  useEffect(() => {
    getReportDetails();
  }, []);

  const pagination = {
    pageSize: 10,
    current: currentPage,
    total: totalCount,
    onChange: handlePageChange,
  };

  return (
    <div style={{ padding: 50, gap: 20, display: "grid" }}>
      <Card loading={getUserData?.isLoading}>
        <div
          style={{
            alignItems: "center",
            display: "flex",
            justifyContent: "space-between",
            flexWrap: "wrap",
          }}
        >
          <h2>User details</h2>
          <Button
            style={{
              background: "#ECAC35",
              width: 100,
              height: 40,
              color: "black",
            }}
            type="primary"
            // htmlType="submit"
            onClick={() => {
              navigate("/manageusers", {
                replace: true,
                state: {
                  page: state?.page,
                  type: state?.type === "customer" ? "first" : "second",
                },
              });
            }}
          >
            Back
          </Button>
        </div>

        <div>
          <Row gutter={16}>
            <Col xs={24} md={12}>
              <h4>User image</h4>
              <div>
                <img
                  style={{
                    height: 100,
                    width: 100,
                    borderRadius: "50%",
                    marginTop: 10,
                  }}
                  src={userDetail?.image || "/static/images/dummy.png"}
                  alt=""
                />
              </div>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col xs={20} md={8}>
              <h4>Full Name</h4>
              <h5>{userDetail?.fullName || "-"}</h5>
            </Col>

            <Col xs={20} md={8}>
              <h4>Email</h4>
              <h5>{userDetail?.email || "-"}</h5>
            </Col>
            {/* <Col xs={20} md={8}>
              <h4>User Earning</h4>
              <h5>{userDetail?.userEarning || "-"}</h5>
            </Col> */}
            <Col xs={20} md={8}>
              <h4>Phone Number</h4>
              <h5>
                {userDetail?.countryCode || "-"} {userDetail?.phone || ""}
              </h5>
            </Col>
          </Row>
          {/* <Row gutter={16}>
            <Col xs={20} md={8}>
              <h4>Address</h4>
              <h5>{userDetail?.address || "-"}</h5>
            </Col>
          </Row> */}
        </div>

        {userDetail?.sellerForm ? (
          <div>
            <h4>Seller form details</h4>

            <Col xs={24} md={12}>
              <h4>Image</h4>
              <div>
                <img
                  style={{
                    height: 100,
                    width: 100,
                    borderRadius: "50%",
                    marginTop: 10,
                  }}
                  src={
                    userDetail?.sellerForm?.logoImage ||
                    "/static/images/dummy.png"
                  }
                  alt=""
                />
              </div>
            </Col>

            <Row gutter={16}>
              <Col xs={20} md={8}>
                <h4>Business Name</h4>
                <h5>{userDetail?.sellerForm?.businessName || "-"}</h5>
              </Col>

              <Col xs={20} md={8}>
                <h4>Email</h4>
                <h5>{userDetail?.sellerForm?.email || "-"}</h5>
              </Col>
              {/* <Col xs={20} md={8}>
              <h4>User Earning</h4>
              <h5>{userDetail?.userEarning || "-"}</h5>
            </Col> */}
              <Col xs={20} md={8}>
                <h4>Phone Number</h4>
                <h5>
                  {userDetail?.sellerForm?.countryCode || "-"}{" "}
                  {userDetail?.sellerForm?.phone || ""}
                </h5>
              </Col>
              <Col xs={20} md={8}>
                <h4>Landline</h4>
                <h5>{userDetail?.sellerForm?.landline || "-"}</h5>
              </Col>
              <Col xs={20} md={8}>
                <h4>Address</h4>
                <h5>{userDetail?.sellerForm?.address || "-"}</h5>
              </Col>
              <Col xs={20} md={8}>
                <h4>License Number</h4>
                <h5>{userDetail?.sellerForm?.licenseNo || "-"}</h5>
              </Col>
            </Row>
          </div>
        ) : (
          ""
        )}

        <h4>Products added by user</h4>

        <div style={{ marginTop: 25 }}>
          <Table
            className="tableSelector"
            columns={columns}
            dataSource={product}
            rowKey="_id"
            pagination={pagination}
            loading={productByIdQuery?.isLoading}
          />
        </div>

        <h4>Reports</h4>
        <div style={{ marginTop: 25 }}>
          <Table
            className="tableSelector"
            columns={columns2}
            dataSource={report}
            rowKey="_id"
            loading={getReportData?.isLoading}
          />
        </div>
      </Card>
    </div>
  );
};

export default UserDetails;
