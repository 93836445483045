import { Card, Col, Form, Row } from "antd";
import { Dispatch, SetStateAction } from "react";
import EditText from "../../../../components/textEditor";
import { Events } from "../../../../types/General";

interface props {
  setCancelRule: Dispatch<SetStateAction<string>>;
  cancelRule: string;
  setVendorRule: Dispatch<SetStateAction<string>>;
  vendorRule: string;
  bookingRule: string;
  setBookingRule: Dispatch<SetStateAction<string>>;
  error: boolean;
  eventDetail: Events | undefined;
}

const EventRulesForm = ({
  setCancelRule,
  cancelRule,
  setBookingRule,
  bookingRule,
  setVendorRule,
  vendorRule,
  error,
  eventDetail,
}: props) => {
  return (
    <Card>
      <h3>Event Rules</h3>
      <Row gutter={16}>
        <Col xs={24} md={12}>
          <Form.Item name="vendorRules">
            <div>
              <h4>Vendor Rules</h4>
              {vendorRule ? (
                <EditText content={vendorRule} setContent={setVendorRule} />
              ) : null}
            </div>
            {error && !vendorRule.trim() ? (
              <h3 className="error_msg">This field is required </h3>
            ) : null}
          </Form.Item>
        </Col>

        <Col xs={24} md={12}>
          <Form.Item name="bookingRules">
            <div>
              <h4>Booking Rules</h4>
              {bookingRule ? (
                <EditText content={bookingRule} setContent={setBookingRule} />
              ) : null}
            </div>
          </Form.Item>
          {error && bookingRule?.length < 8 ? (
            <h3 className="error_msg">This field is required </h3>
          ) : null}
        </Col>
      </Row>

      <Row gutter={16}>
        <Col xs={24} md={12}>
          <Form.Item name="cancellationRules">
            <div>
              <h4>Cancellation Rules</h4>
              {cancelRule ? (
                <EditText content={cancelRule} setContent={setCancelRule} />
              ) : null}
            </div>
            {error && !cancelRule ? (
              <h3 className="error_msg">This field is required </h3>
            ) : null}
          </Form.Item>
        </Col>
      </Row>
    </Card>
  );
};

export default EventRulesForm;
