import { Route, Routes } from "react-router-dom";
import {
  Dashboard,
  ManageUsers,
  Notifications,
  RevanueManagement,
  ManageCms,
  ManageFaq,
  ManageSubAdmins,
  Profile,
  SubAdminDetails,
  SubAdminForm,
  UsersForm,
  UserDetails,
  Analytics,
  CustomerSupport,
  Reports,
  RecommendedProductsForm,
  ListedProductPost,
  ListedProductsDetails,
  ProductForm,
  RecommendedProducts,
  ManageEventOwner,
  BookingOrderManagement,
  ViewDetailsOfEvent,
  MembershipPlans,
  EventsForm,
  EventDetails,
  ProductCategories,
  EventBookingForm,
  MembershipForm,
  MembershipDetails,
  OwnerDetails,
  OwnerForm,
  AddProductCategoryForm,
  EventCategories,
  AddEventCategoryForm,
  EventBookingDetails,
  DonationCategory,
  AdaDonationCategoryForm,
  ManageShops,
  ShopDetails,
  EditShopForm,
  ShopCategory,
  ShopCategoryForm,
  ManageOrganization,
  OrganizationDetails,
  AddOrganization,
  Donations,
  DonarDetails,
  FundRaisingBooking,
  FundRaisingDetails,
  ProductSubCategoryList,
  ProductSubCategoryForm,
  ProductSubCategoryView,
  ManageBanners,
  BannerForm,
  NewsLetter,
  Boosters,
  BoosterDetails,
  FeaturedProductDetails,
  ReceiveNotification,
} from "../pages";
import { ForgetPassword, Login } from "../pages/auth";
import MainLayout from "../layout";
import NotificationsHistory from "../pages/notifications/details";
import VerifyOtp from "../pages/auth/otpVerify";
import ResetPassword from "../pages/auth/resetPassword";
import ChangePassword from "../pages/auth/changePassword";

const RoutesNavigation = () => {
  return (
    <Routes>
      <Route path="/" element={<Login />}></Route>
      <Route path="/forgotpassword" element={<ForgetPassword />}></Route>
      <Route path="/verify-otp" element={<VerifyOtp />}></Route>
      <Route path="/reset-password" element={<ResetPassword />}></Route>
      <Route path="/change-password" element={<ChangePassword />}></Route>
      <Route
        path="/dashboard"
        element={
          <MainLayout>
            <Dashboard />
          </MainLayout>
        }
      ></Route>
      <Route
        path="/manageusers"
        element={
          <MainLayout>
            <ManageUsers />
          </MainLayout>
        }
      ></Route>
      <Route
        path="/manageusers/edit/:id"
        element={
          <MainLayout>
            <UsersForm />
          </MainLayout>
        }
      ></Route>
      <Route
        path="/manageusers/userdetail/:id"
        element={
          <MainLayout>
            <UserDetails />
          </MainLayout>
        }
      ></Route>
      <Route
        path="/managesubadmins"
        element={
          <MainLayout>
            <ManageSubAdmins />
          </MainLayout>
        }
      ></Route>
      <Route
        path="/profile"
        element={
          <MainLayout>
            <Profile />
          </MainLayout>
        }
      ></Route>
      <Route
        path="/managecms"
        element={
          <MainLayout>
            <ManageCms />
          </MainLayout>
        }
      ></Route>
      <Route
        path="/revenue-management"
        element={
          <MainLayout>
            <RevanueManagement />
          </MainLayout>
        }
      ></Route>
      <Route
        path="/notifications"
        element={
          <MainLayout>
            <Notifications />
          </MainLayout>
        }
      ></Route>

      <Route
        path="/receive-notifications"
        element={
          <MainLayout>
            <ReceiveNotification />
          </MainLayout>
        }
      ></Route>

      <Route
        path="/notifications/history"
        element={
          <MainLayout>
            <NotificationsHistory />
          </MainLayout>
        }
      ></Route>
      <Route
        path="/recommended-products"
        element={
          <MainLayout>
            <RecommendedProducts />
          </MainLayout>
        }
      ></Route>
      <Route
        path="/manage-faq"
        element={
          <MainLayout>
            <ManageFaq />
          </MainLayout>
        }
      ></Route>
      <Route
        path="/product-categories"
        element={
          <MainLayout>
            <ProductCategories />
          </MainLayout>
        }
      ></Route>
      <Route
        path="/product-categories/sub-category/:id"
        element={
          <MainLayout>
            <ProductSubCategoryList />
          </MainLayout>
        }
      ></Route>
      <Route
        path="/product-categories/sub-category/:view/:id"
        element={
          <MainLayout>
            <ProductSubCategoryForm />
          </MainLayout>
        }
      ></Route>
      <Route
        path="/product-categories/sub-category/view/:id"
        element={
          <MainLayout>
            <ProductSubCategoryView />
          </MainLayout>
        }
      ></Route>
      <Route
        path="/event-categories"
        element={
          <MainLayout>
            <EventCategories />
          </MainLayout>
        }
      ></Route>
      <Route
        path="/analytics"
        element={
          <MainLayout>
            <Analytics />
          </MainLayout>
        }
      ></Route>
      <Route
        path="/listed-products-posts"
        element={
          <MainLayout>
            <ListedProductPost />
          </MainLayout>
        }
      ></Route>
      <Route
        path={`/managesubadmins/:id`}
        element={
          <MainLayout>
            <SubAdminForm />
          </MainLayout>
        }
      ></Route>
      <Route
        path={`/managesubadmins/add`}
        element={
          <MainLayout>
            <SubAdminForm />
          </MainLayout>
        }
      ></Route>
      <Route
        path="/managesubadmins/subadmindetail/:id"
        element={
          <MainLayout>
            <SubAdminDetails />
          </MainLayout>
        }
      ></Route>
      <Route
        path="/customersupport"
        element={
          <MainLayout>
            <CustomerSupport />
          </MainLayout>
        }
      ></Route>
      <Route
        path="/reports"
        element={
          <MainLayout>
            <Reports />
          </MainLayout>
        }
      ></Route>
      <Route
        path="/listed-products-posts/details/:id"
        element={
          <MainLayout>
            <ListedProductsDetails />
          </MainLayout>
        }
      ></Route>

      <Route
        path="/recommended-products/details/:id"
        element={
          <MainLayout>
            <FeaturedProductDetails />
          </MainLayout>
        }
      ></Route>
      <Route
        path="/manage-event-owner"
        element={
          <MainLayout>
            <ManageEventOwner />
          </MainLayout>
        }
      ></Route>
      <Route
        path="/manage-organization"
        element={
          <MainLayout>
            <ManageOrganization />
          </MainLayout>
        }
      ></Route>
      <Route
        path="/manage-organization/:view"
        element={
          <MainLayout>
            <AddOrganization />
          </MainLayout>
        }
      ></Route>
      <Route
        path="/manage-organization/details/:id"
        element={
          <MainLayout>
            <OrganizationDetails />
          </MainLayout>
        }
      ></Route>
      <Route
        path={`/recommended-products/:view`}
        element={
          <MainLayout>
            <RecommendedProductsForm />
          </MainLayout>
        }
      ></Route>
      <Route
        path="/booking-order-management"
        element={
          <MainLayout>
            <BookingOrderManagement />
          </MainLayout>
        }
      ></Route>
      <Route
        path="/listed-products-posts/add"
        element={
          <MainLayout>
            <ProductForm />
          </MainLayout>
        }
      ></Route>
      <Route
        path="/listed-products-posts/edit/:id"
        element={
          <MainLayout>
            <ProductForm />
          </MainLayout>
        }
      ></Route>
      <Route
        path="/event-booking-details/:id"
        element={
          <MainLayout>
            <ViewDetailsOfEvent />
          </MainLayout>
        }
      ></Route>
      <Route
        path="/manage-membership-plans"
        element={
          <MainLayout>
            <MembershipPlans />
          </MainLayout>
        }
      ></Route>
      <Route
        path="/manage-event-owner/add-event/:view/owner/:id"
        element={
          <MainLayout>
            <EventsForm />
          </MainLayout>
        }
      ></Route>
      <Route
        path="/manage-event-owner/event-details/:id/owner/:view"
        element={
          <MainLayout>
            <EventDetails />
          </MainLayout>
        }
      ></Route>
      <Route
        path="/booking-order-management/:id"
        element={
          <MainLayout>
            <EventBookingForm />
          </MainLayout>
        }
      ></Route>
      <Route
        path="/booking-order-management/details/:id"
        element={
          <MainLayout>
            <EventBookingDetails />
          </MainLayout>
        }
      ></Route>
      <Route
        path="/manage-membership-plans/:id"
        element={
          <MainLayout>
            <MembershipForm />
          </MainLayout>
        }
      ></Route>
      <Route
        path="/manage-membership-plans/details/:id"
        element={
          <MainLayout>
            <MembershipDetails />
          </MainLayout>
        }
      ></Route>
      <Route
        path="/manage-event-owner/details/:id"
        element={
          <MainLayout>
            <OwnerDetails />
          </MainLayout>
        }
      ></Route>
      <Route
        path="/manage-event-owner/:id"
        element={
          <MainLayout>
            <OwnerForm />
          </MainLayout>
        }
      ></Route>
      <Route
        path="/product-categories/:view"
        element={
          <MainLayout>
            <AddProductCategoryForm />
          </MainLayout>
        }
      ></Route>
      <Route
        path="/event-categories/:view"
        element={
          <MainLayout>
            <AddEventCategoryForm />
          </MainLayout>
        }
      ></Route>
      <Route
        path="/donation-categories/:view"
        element={
          <MainLayout>
            <AdaDonationCategoryForm />
          </MainLayout>
        }
      ></Route>
      <Route
        path="/shop-categories/:view"
        element={
          <MainLayout>
            <ShopCategoryForm />
          </MainLayout>
        }
      ></Route>
      <Route
        path="/donation-categories"
        element={
          <MainLayout>
            <DonationCategory />
          </MainLayout>
        }
      ></Route>
      <Route
        path="/manage-shops"
        element={
          <MainLayout>
            <ManageShops />
          </MainLayout>
        }
      ></Route>
      <Route
        path="/manage-shops/details/:id"
        element={
          <MainLayout>
            <ShopDetails />
          </MainLayout>
        }
      ></Route>
      <Route
        path="/manage-shops/edit/:id"
        element={
          <MainLayout>
            <EditShopForm />
          </MainLayout>
        }
      ></Route>
      <Route
        path="/shop-categories"
        element={
          <MainLayout>
            <ShopCategory />
          </MainLayout>
        }
      ></Route>
      <Route
        path="/donations"
        element={
          <MainLayout>
            <Donations />
          </MainLayout>
        }
      ></Route>
      <Route
        path="/donations/details/:id"
        element={
          <MainLayout>
            <DonarDetails />
          </MainLayout>
        }
      ></Route>
      <Route
        path="/fund-raising"
        element={
          <MainLayout>
            <FundRaisingBooking />
          </MainLayout>
        }
      ></Route>
      <Route
        path="/fund-raising/details/:id"
        element={
          <MainLayout>
            <FundRaisingDetails />
          </MainLayout>
        }
      ></Route>
      <Route
        path="/manage-banners"
        element={
          <MainLayout>
            <ManageBanners />
          </MainLayout>
        }
      />
      {/* <Route
        path="/manage-banners/add/:activeTab"
        element={
          <MainLayout>
            <BannerForm />
          </MainLayout>
        }
      /> */}
      <Route
        path="/manage-banners/:id"
        element={
          <MainLayout>
            <BannerForm />
          </MainLayout>
        }
      />
      <Route
        path="/manage-newsletter"
        element={
          <MainLayout>
            <NewsLetter />
          </MainLayout>
        }
      ></Route>
      <Route
        path="/booster-subscriptions"
        element={
          <MainLayout>
            <Boosters />
          </MainLayout>
        }
      ></Route>
      <Route
        path="/booster-subscriptions/details/:id"
        element={
          <MainLayout>
            <BoosterDetails />
          </MainLayout>
        }
      ></Route>
    </Routes>
  );
};

export default RoutesNavigation;
