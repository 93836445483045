import { END_POINTS } from "../helpers";
import { CommonBody, DashboardTypes } from "../types/General";
import emptySplitApi from "../utils/rtk";

type CommonResponse = {
  statusCode: number;
  data?: any;
  message: string;
};

type PutBlockUserParams = {
  userId: string;
  encryptedBody: CommonBody;
};

type GetDashboardParams = {
  user: string;
  revenue: string;
};

type getDashboardResponse = {
  statusCode: number;
  message: string;
  data: DashboardTypes;
};

type getProductParams = {
  page: number;
  userId: string | undefined;
};

export const categoryApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getUserDetails: builder.query<
      CommonResponse,
      { page: number; size: number; search: string; type: number }
    >({
      query: ({ page, size, search, type }) => ({
        url:
          END_POINTS.user +
          "?page=" +
          page +
          "&size=" +
          size +
          "&search=" +
          search +
          "&type=" +
          type,

        method: "GET",
      }),
    }),

    getUserDetailsId: builder.query<CommonResponse, string | undefined>({
      query: (userId) => ({
        url: `${END_POINTS.user}/${userId}`,
        method: "GET",
      }),
    }),
    getUserCsv: builder.query<CommonResponse, {}>({
      query: ({}) => ({
        url: `${END_POINTS.getUserCsv}`,
        method: "GET",
      }),
    }),
    deleteUser: builder.mutation<{ statusCode: number }, { userId: string }>({
      query: ({ userId }) => ({
        url: `${END_POINTS.user}/${userId}`,
        method: "DELETE",
      }),
    }),
    putBlockUser: builder.mutation<CommonResponse, PutBlockUserParams>({
      query: ({ encryptedBody, userId }) => ({
        url: `${END_POINTS.user}/${userId}`,
        method: "PUT",
        body: encryptedBody,
      }),
    }),
    putUpdateUser: builder.mutation<{ statusCode: number }, PutBlockUserParams>(
      {
        query: ({ encryptedBody, userId }: any) => ({
          url: `${END_POINTS.user}/${userId}/`,
          method: "PUT",
          body: encryptedBody,
        }),
      }
    ),

    getDashboard: builder.query<getDashboardResponse, GetDashboardParams>({
      query: ({ user, revenue }) => ({
        url:
          END_POINTS.adminDashboard +
          "?userFilter=" +
          user +
          "&revenueFilter=" +
          revenue,
        method: "GET",
      }),
    }),

    getProductsByUserId: builder.query<CommonResponse, getProductParams>({
      query: ({ userId, page }) => ({
        url: `${END_POINTS.getProductsByUserId}/${userId}?page=${page}`,
        method: "GET",
      }),
    }),

    getReportsById: builder.query<CommonResponse, string | undefined>({
      query: (userId) => ({
        url: `${END_POINTS.userReports}/${userId}`,
        method: "GET",
      }),
    }),

    putChangeSellerStatus: builder.mutation<CommonResponse, PutBlockUserParams>(
      {
        query: ({ encryptedBody, userId }) => ({
          url: `${END_POINTS.sellerForm}/${userId}`,
          method: "PUT",
          body: encryptedBody,
        }),
      }
    ),
  }),
});

export const {
  useLazyGetUserDetailsQuery,
  useLazyGetUserDetailsIdQuery,
  usePutUpdateUserMutation,
  usePutBlockUserMutation,
  useDeleteUserMutation,
  useLazyGetUserCsvQuery,
  useLazyGetDashboardQuery,
  useLazyGetProductsByUserIdQuery,
  useLazyGetReportsByIdQuery,
  usePutChangeSellerStatusMutation,
} = categoryApi;
