import { Button, Card, Col, Row, Table } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import type { ColumnsType } from "antd/es/table";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";

import { Permissions, SubAdmins } from "../../../types/User";
import { useLazyGetSubAdminDetailsIdQuery } from "../../../services/subAdmin";

const SubAdminDetails = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const [getSubAdminByIdMethod, getSubAdminByIdMethodQuery] =
    useLazyGetSubAdminDetailsIdQuery();

  const [subAdminDetail, setSubAdminDetail] = useState<SubAdmins>();
  const [permissionArr, setPermissionArr] = useState<any>();

  const columns: ColumnsType<Permissions> = [
    {
      title: "Modules",
      dataIndex: "label",
      key: "label",
    },
    {
      title: "Add/Edit",
      dataIndex: "",
      key: "",
      render: ({ isAdd, key }: any) => {
        return (
          <div>
            {isAdd ? (
              <CheckOutlined style={{ color: "green" }} />
            ) : (
              <CloseOutlined style={{ color: "red" }} />
            )}
          </div>
        );
      },
    },
    {
      title: "View",
      dataIndex: "",
      key: "",
      render: ({ isView, key }: any) => {
        return (
          <div>
            {isView ? (
              <CheckOutlined style={{ color: "green" }} />
            ) : (
              <CloseOutlined style={{ color: "red" }} />
            )}
          </div>
        );
      },
    },
    {
      title: "Delete",
      dataIndex: "",
      key: "",
      render: ({ isDelete, key }: any) => {
        return (
          <div>
            {isDelete ? (
              <CheckOutlined style={{ color: "green" }} />
            ) : (
              <CloseOutlined style={{ color: "red" }} />
            )}
          </div>
        );
      },
    },
  ];

  const getSubAdminDataById = async () => {
    try {
      const res = await getSubAdminByIdMethod(id).unwrap();

      if (res?.statusCode === 200) {
        setSubAdminDetail(res?.data);
        setPermissionArr(res?.data?.permissions);
      }
    } catch (error: any) {
      console.log(error);
    }
  };

  useEffect(() => {
    getSubAdminDataById();
  }, []);

  return (
    <div style={{ padding: 50, gap: 20, display: "grid" }}>
      <Card loading={getSubAdminByIdMethodQuery.isLoading}>
        <div
          style={{
            alignItems: "center",
            display: "flex",
            justifyContent: "space-between",
            flexWrap: "wrap",
          }}
        >
          <h2>Sub-Admin Details</h2>
          <Button
            style={{
              background: "#ECAC35",
              width: 100,
              height: 40,
            }}
            type="primary"
            // htmlType="submit"
            onClick={() => {
              navigate("/managesubadmins", { replace: true });
            }}
          >
            Back
          </Button>
        </div>

        <div>
          <Row gutter={16}>
            <Col xs={24} md={12}>
              <h4>Image</h4>
              <div
                style={{
                  display: "flex",
                }}
              >
                <img
                  style={{
                    height: 100,
                    width: 100,

                    borderRadius: "50%",
                    // margin: "auto",
                  }}
                  src={subAdminDetail?.image || "/static/images/dummy.png"}
                  alt=""
                />
              </div>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col xs={20} md={8}>
              <h4>Full Name</h4>
              <h5>
                {subAdminDetail?.firstName} {subAdminDetail?.lastName}
              </h5>
            </Col>

            <Col xs={20} md={8}>
              <h4>Email</h4>
              <h5>{subAdminDetail?.email || "-"}</h5>
            </Col>
            <Col xs={20} md={8}>
              <h4>Phone Number</h4>
              <h5>
                {subAdminDetail?.countryCode || "-"}
                {subAdminDetail?.phone || "-"}
              </h5>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col xs={20} md={8}>
              <h4>Role</h4>
              <h5>{subAdminDetail?.coAdminRole?.role || "-"}</h5>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col xs={24} md={24}>
              <Table
                style={{ marginTop: 20, marginBottom: 20 }}
                pagination={false}
                dataSource={permissionArr}
                columns={columns}
              />
            </Col>
          </Row>
        </div>
      </Card>
    </div>
  );
};

export default SubAdminDetails;
