// @ts-nocheck
import { Button, Card, Col, Form, Input, Row, Spin } from "antd";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { ContentHeader } from "../../components";
import useAuth from "../../hooks/useAuth";
import Upload, { RcFile } from "antd/es/upload";

import { UploadOutlined } from "@ant-design/icons";
import { useAppDispatch } from "../../hooks/store";
import {
  useLazyGetProfileQuery,
  usePutUpdateProfileMutation,
} from "../../services/auth";
import {
  STORAGE_KEYS,
  errorToast,
  getFromStorage,
  successToast,
} from "../../helpers";
import { setCredentials } from "../../reducer/authSlice";
import { UploadMedia } from "../../utils/mediaUpload";
import { CommonBody } from "../../types/General";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import { Autocomplete } from "@react-google-maps/api";

const Profile = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [error, setError] = useState(false);

  const userData = useAuth();
  const dispatch = useAppDispatch();
  const [firstName, setFirstName] = useState("");
  const [updateProfile, updateProfileData] = usePutUpdateProfileMutation();
  const [getProfile, getProfileData] = useLazyGetProfileQuery();
  const [profileImage, setProfileImage] = useState("");
  const [countryName, setCountryName] = useState("in");
  const [email, setEmail] = useState("");
  const [autocomplete, setAutocomplete] = useState(null);
  const [lastName, setLastName] = useState("");
  const [address, setAddress] = useState("");
  const [phoneCode, setPhoneCode] = useState("+91");
  const [phoneNo, setphoneNo] = useState("");
  const [lat, setLat] = useState("");
  const [long, setLong] = useState("");

  const onKeypress = (e: any) => {
    if (e.key === "Enter") {
      e.preventDefault();
      return false;
    }
  };

  const onLoad = (autocompleteObj: any) => {
    setAutocomplete(autocompleteObj);
  };
  const onPlaceChanged = async () => {
    if (autocomplete) {
      var place = await autocomplete.getPlace();
      if (place) {
        form.setFieldsValue({
          address: place.formatted_address,
        });
        setAddress(place.formatted_address);

        const lat = place.geometry.location.lat();
        const lng = place.geometry.location.lng();
        setLat(lat);
        setLong(lng);
      }
    }
  };

  const onFinish = async (values: any) => {
    if (!phoneNo) {
      setError(true);
      return;
    }
    if (phoneNo?.length < 6) {
      setError(true);
      return;
    }

    let data = {
      firstName,
      lastName,
      email: email,
      address: address,
      image: profileImage || "",
      countryCode: phoneCode,
      phone: phoneNo,
      // latitude: lat?.toString(),
      // longitude: long?.toString(),
    };

    try {
      let encryptedBody = generateEncryptedKeyBody(data) as CommonBody;

      const res = await updateProfile(encryptedBody).unwrap();
      if (res?.statusCode === 200) {
        const getRes = await getProfile({}).unwrap();
        if (getRes?.statusCode === 200) {
          const token = getFromStorage(STORAGE_KEYS.token);
          successToast("Profile updated successfully");
          dispatch(
            setCredentials({
              user: getRes?.data || null,
              token: token ? JSON.parse(`${token}`) : null,
            })
          );
        }

        navigate("/dashboard", { replace: true });
      }
    } catch (error: any) {
      errorToast(error?.data?.message || error?.data?.error);
    }
  };

  const handleChangePhone = (phone: any, country: any) => {
    setPhoneCode(country?.dialCode);
    setphoneNo(phone?.replace(country.dialCode, ""));
    setCountryName(country?.countryCode);
  };

  useEffect(() => {
    if (userData) {
      form.setFieldsValue({
        email: userData?.email,
        firstName: userData?.firstName,
        lastName: userData?.lastName,
        address: userData?.address,
        phone: userData?.phone,
      });
      setFirstName(userData?.firstName || "");
      setLastName(userData?.lastName || "");
      setphoneNo(userData?.phone || "");
      setPhoneCode(userData?.countryCode || "+91");
      setEmail(userData?.email || "");
      setAddress(userData?.address || "");
      setProfileImage(userData?.image || "");
      setLat(userData?.latitude || "");
      setLong(userData?.longitude || "");
    }
  }, [userData, form]);

  const handleImageUpload = async (file: RcFile) => {
    if (file?.type.includes("image")) {
      try {
        const res = await UploadMedia(file);

        if (res?.statusCode === 200) {
          setProfileImage(res?.data);
        }
      } catch (error: any) {
        console.log(error);
      }
    } else {
      errorToast("Only images are allowed");
    }

    return false;
  };

  const handleNameChange = (e: any) => {
    const limit = 15;

    setFirstName(e.target.value.slice(0, limit));
  };

  const handleLastNameChange = (e: any) => {
    setLastName(e.target.value.slice(0, 15));
  };

  return (
    <div style={{ padding: 40 }}>
      <ContentHeader heading="User Profile" />
      <Card loading={getProfileData.isLoading}>
        <div>
          <Form
            name="basic"
            layout="vertical"
            form={form}
            style={{
              maxWidth: "100%",
              marginTop: 10,
            }}
            initialValues={{ remember: true }}
            onFinish={onFinish}
            autoComplete="off"
          >
            <Row gutter={16}>
              <Col xs={24} md={12}>
                <Form.Item name="image">
                  <Upload
                    beforeUpload={handleImageUpload}
                    showUploadList={false}
                    className="upload_imgD"
                    accept="image/*"
                  >
                    <div>
                      <h4>Upload Profile Image</h4>
                      <Button icon={<UploadOutlined />}>Click to upload</Button>
                    </div>
                    {profileImage ? (
                      <img
                        style={{
                          height: 110,
                          width: 110,
                          borderRadius: "50%",
                          marginTop: 20,
                        }}
                        src={profileImage}
                        alt="profile"
                      />
                    ) : (
                      <></>
                    )}
                  </Upload>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col xs={24} md={12}>
                <Form.Item
                  name="firstName"
                  rules={[
                    {
                      required: true,
                      message: "Please input your first name!",
                      whitespace: true,
                    },
                    {
                      min: 2,
                      message: "Name must be at least 2 characters long",
                    },
                  ]}
                >
                  <div>
                    <h4>First Name</h4>
                    <Input
                      value={firstName}
                      placeholder="Full Name"
                      style={{ height: 55 }}
                      onChange={(e) => {
                        setFirstName(e.target.value);
                        handleNameChange(e);
                      }}
                    />
                  </div>
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item name="username" rules={[]}>
                  <div>
                    <h4>Last Name</h4>
                    <Input
                      value={lastName}
                      placeholder="User Name"
                      style={{ height: 55 }}
                      onChange={(e) => {
                        setLastName(e.target.value);
                        handleLastNameChange(e);
                      }}
                    />
                  </div>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col xs={24} md={12}>
                <Form.Item
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: "Please input your E-mail!",
                    },
                    {
                      type: "email",
                      message: "The input is not valid E-mail!",
                    },
                  ]}
                >
                  <div>
                    <h4>Email</h4>
                    <Input
                      value={email}
                      style={{ height: 55 }}
                      placeholder="E-mail"
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                    />
                  </div>
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item
                  name="phone"
                  rules={[
                    {
                      required: true,
                      message: "Please input your phone number!",
                    },
                  ]}
                >
                  <div>
                    <h4>Phone Number</h4>
                    <div className="phn_cs">
                      <PhoneInput
                        value={phoneCode + phoneNo}
                        enableSearch={true}
                        placeholder="Phone number"
                        containerClass={"cntn_telCls"}
                        inputClass={"cstm_cls"}
                        buttonClass={"custm_btn"}
                        onChange={(phone, country) =>
                          handleChangePhone(phone, country)
                        }
                      />
                    </div>
                    {error && !phoneNo ? (
                      <h3 className="error_msg">
                        Please input your phone number!
                      </h3>
                    ) : null}
                    {error && phoneNo?.length && phoneNo?.length < 6 ? (
                      <h3 className="error_msg">
                        Please input valid phone number!
                      </h3>
                    ) : null}
                  </div>
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              {userData?.address ? (
                <Col xs={24} md={12}>
                  <Form.Item
                    name="address"
                    rules={[
                      {
                        required: true,
                        message: "Please input your Address!",
                        whitespace: true,
                      },
                      {
                        min: 3,
                        message: "Address must be at least 3 characters",
                      },
                    ]}
                  >
                    <Autocomplete
                      onLoad={onLoad}
                      onPlaceChanged={onPlaceChanged}
                    >
                      <div>
                        <h4>Address</h4>
                        <Input
                          allowClear
                          value={address}
                          placeholder="Address"
                          style={{ height: 55 }}
                          onChange={(e) => {
                            setAddress(e.target.value);
                          }}
                          onKeyDown={onKeypress}
                        />
                      </div>
                    </Autocomplete>
                  </Form.Item>
                </Col>
              ) : (
                ""
              )}
            </Row>

            <Form.Item wrapperCol={{ offset: 4, span: 16 }}>
              <div style={{ display: "flex" }}>
                <Button
                  loading={updateProfileData.isLoading}
                  style={{
                    background: "#ECAC35",
                    width: 200,
                    height: 50,
                    margin: "auto",
                    color: "black",
                  }}
                  type="primary"
                  htmlType="submit"
                >
                  Save
                </Button>
              </div>
            </Form.Item>
          </Form>
        </div>
      </Card>
    </div>
  );
};

export default Profile;
