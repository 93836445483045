import { Button, Card, Col, Form, Input, Row, Select } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { ContentHeader } from "../../../components";
import EditText from "../../../components/textEditor";
import { generateEncryptedKeyBody } from "../../../utils/crypto";
import { CommonBody } from "../../../types/General";
import { errorToast, successToast } from "../../../helpers";
import {
  useLazyGetPlanDetailsIdQuery,
  usePostAddPlanMutation,
  usePutUpdatePlanMutation,
} from "../../../services/memberships";

const MembershipForm = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [selectedValidity, setSelectedValidity] = useState("");
  const [selectedValidityAr, setSelectedValidityAr] = useState("");
  const [price, setPrice] = useState<number>();
  const { id } = useParams();

  const [feature, setFeature] = useState(id !== "add" ? "" : " ");
  const [featureAr, setFeatureAR] = useState(id !== "add" ? "" : " ");
  const [values, setValues] = useState({
    planName: "",
    planNameAr: "",
    description: "",
    descriptionAr: "",
  });

  const [addPlan, addPlanMutation] = usePostAddPlanMutation();
  const [updatePlan, updatePlanMutation] = usePutUpdatePlanMutation();
  const [getPlanDetailsById, getPlanDetailsByIdQuery] =
    useLazyGetPlanDetailsIdQuery();

  const { Option } = Select;

  const validity = [
    { value: 1, name: "1 Week" },
    { value: 2, name: "2 Weeks" },
    { value: 3, name: "3 Months" },
    { value: 4, name: "6 Months" },
    { value: 5, name: "9 Months" },
    { value: 6, name: "1 Year" },
  ];

  const validityAr = [
    { name: "أسبوع 1" },
    { name: "2 أسابيع" },
    { name: "3 اشهر" },
    { name: "6 اشهر" },
    { name: "9 أشهر" },
    { name: "1 سنة" },
  ];

  const PlanDetailsByIdHandler = async () => {
    try {
      const res = await getPlanDetailsById(id).unwrap();

      if (res?.statusCode === 200) {
        form.setFieldsValue({
          price: res?.data?.price,
          validity: res?.data?.validity,
          validity_ar: res?.data?.validity_ar,
          planName: res?.data?.planName,
          planName_ar: res?.data?.planName_ar,
          description: res?.data?.description,
          description_ar: res?.data?.description_ar,
          feature: res?.data?.feature,
          feature_ar: res?.data?.feature_ar,
        });
        setValues({
          ...values,
          planName: res?.data?.planName || "",
          description: res?.data?.description || "",
          descriptionAr: res?.data?.description_ar || "",
          planNameAr: res?.data?.planName_ar || "",
        });
        setFeature(res?.data?.feature);
        setFeatureAR(res?.data?.feature_ar);
        setPrice(res?.data?.price);
        setSelectedValidity(res?.data?.validity);
        setSelectedValidityAr(res?.data?.validity_ar);
      }
    } catch (error: any) {
      console.log(error);
    }
  };

  const onFinish = async (values: any) => {
    const data = {
      planName: values.planName,
      planName_ar: values.planName_ar,
      price: values.price,
      validity: selectedValidity || "",
      validity_ar: selectedValidityAr || "",
      description: values.description,
      description_ar: values.description_ar,
      feature: feature,
      feature_ar: featureAr,
    };
    console.log(data);

    try {
      let encryptedBody = generateEncryptedKeyBody(data) as CommonBody;

      let res;
      id === "add"
        ? (res = await addPlan(encryptedBody).unwrap())
        : (res = await updatePlan({
            encryptedBody,
            planId: id,
          }).unwrap());

      if (res?.statusCode === 200) {
        navigate("/manage-membership-plans", { replace: true });
        successToast(
          id === "add" ? "Plan added successfully" : "Plan updated successfully"
        );
      } else {
        errorToast(res?.message);
      }
    } catch (error: any) {
      errorToast(error?.data?.message || error?.data?.error);
    }
  };

  const handleChangeForValidity = (value: any) => {
    form.setFieldsValue({
      validity: value,
    });
    setSelectedValidity(value);
    form.validateFields(["validity"]);
  };

  const handleChangeForValidityAr = (value: any) => {
    form.setFieldsValue({
      validity_ar: value,
    });
    setSelectedValidityAr(value);
    form.validateFields(["validity_ar"]);
  };

  useEffect(() => {
    if (id !== "add") {
      PlanDetailsByIdHandler();
    }
  }, []);

  console.log(feature, "feature");

  return (
    <div style={{ padding: 50 }}>
      <ContentHeader
        buttonText="Back"
        buttonNavigateTo="manage-membership-plans"
        heading={`${id === "add" ? "Add" : "Edit"} Plan`}
      />
      <Card loading={getPlanDetailsByIdQuery.isLoading}>
        <div>
          <Form
            name="basic"
            layout="vertical"
            form={form}
            style={{
              maxWidth: "100%",
              marginTop: 10,
            }}
            initialValues={{ remember: true }}
            autoComplete="off"
            onFinish={onFinish}
          >
            <Row gutter={16}>
              <Col xs={24} md={12}>
                <Form.Item
                  name="planName"
                  rules={[
                    {
                      required: true,
                      message: "Please input plan name!",
                      whitespace: true,
                    },
                    {
                      min: 3,
                      message: "Name must be at least 3 characters",
                    },
                  ]}
                >
                  <div>
                    <h4>Plan Name ( English )</h4>
                    <Input
                      maxLength={20}
                      value={values?.planName}
                      onChange={(e) => {
                        setValues({ ...values, planName: e.target.value });
                      }}
                      placeholder="Plan Name ( English )"
                      style={{ height: 55 }}
                    />
                  </div>
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item
                  name="planName_ar"
                  rules={[
                    // {
                    //   required: true,
                    //   message: "Please input plan name!",
                    //   whitespace: true,
                    // },
                    {
                      min: 3,
                      message: "Name must be at least 3 characters",
                    },
                  ]}
                >
                  <div>
                    <h4>Plan Name ( Arabic )</h4>
                    <Input
                      maxLength={20}
                      value={values?.planNameAr}
                      onChange={(e) => {
                        setValues({ ...values, planNameAr: e.target.value });
                      }}
                      placeholder="Plan Name ( Arabic )"
                      style={{ height: 55 }}
                    />
                  </div>
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item
                  name="price"
                  rules={[
                    { required: true, message: "Please input price!" },
                    {
                      pattern: /^[0-9]*$/,
                      message: "Please enter only numeric values",
                    },
                  ]}
                >
                  <div>
                    <h4>Price</h4>
                    <Input
                      maxLength={10}
                      value={price}
                      onChange={(e) => {
                        const input = Number(e.target.value);
                        if (!isNaN(input)) {
                          setPrice(input);
                        }
                      }}
                      placeholder="Price"
                      style={{ height: 55 }}
                    />
                  </div>
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item
                  name="validity"
                  rules={[
                    {
                      required: true,
                      message: "Please select plan validity!",
                    },
                  ]}
                >
                  <div>
                    <h4>Validity</h4>
                    <Select
                      allowClear
                      maxLength={20}
                      className="select_type"
                      onChange={(val) => handleChangeForValidity(val)}
                      value={selectedValidity ? selectedValidity : undefined}
                      style={{ height: 55, width: "100%" }}
                      placeholder="Validity"
                    >
                      {validity?.map((item, index) => (
                        <Option
                          key={index}
                          value={item?.value}
                          label={item?.name}
                        >
                          {item?.name}
                        </Option>
                      ))}
                    </Select>
                  </div>
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item
                  name="validity_ar"
                  rules={[
                    {
                      required: true,
                      message: "Please select plan validity!",
                    },
                  ]}
                >
                  <div>
                    <h4>Validity ( Arabic )</h4>
                    <Select
                      allowClear
                      maxLength={20}
                      className="select_type"
                      onChange={(val) => handleChangeForValidityAr(val)}
                      value={
                        selectedValidityAr ? selectedValidityAr : undefined
                      }
                      style={{ height: 55, width: "100%" }}
                      placeholder="Validity"
                    >
                      {validityAr?.map((item, index) => (
                        <Option
                          key={index}
                          value={item?.name}
                          label={item?.name}
                        >
                          {item?.name}
                        </Option>
                      ))}
                    </Select>
                  </div>
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col xs={24} md={12}>
                <Form.Item
                  name="description"
                  rules={[
                    {
                      required: true,
                      message: "Please input plan description!",
                    },
                    {
                      min: 10,
                      message: "Description must be at least 10 characters",
                    },
                  ]}
                >
                  <div>
                    <h4>Description ( English )</h4>
                    <Input
                      value={values?.description}
                      onChange={(e) => {
                        setValues({ ...values, description: e.target.value });
                      }}
                      placeholder="Description ( English )"
                      style={{ height: 55 }}
                    />
                  </div>
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item
                  name="description_ar"
                  rules={[
                    // {
                    //   required: true,
                    //   message: "Please input plan description!",
                    // },
                    {
                      min: 10,
                      message: "Description must be at least 10 characters",
                    },
                  ]}
                >
                  <div>
                    <h4>Description ( Arabic )</h4>
                    <Input
                      value={values?.descriptionAr}
                      onChange={(e) => {
                        setValues({ ...values, descriptionAr: e.target.value });
                      }}
                      placeholder="Description ( Arabic ) "
                      style={{ height: 55 }}
                    />
                  </div>
                </Form.Item>
              </Col>
            </Row>

            {/* <Row gutter={16}>
              <Col xs={24} md={12}>
                <Form.Item name="feature">
                  <div>
                    <h4>Add Feature (English) </h4>
                    {feature.length ? (
                      <EditText setContent={setFeature} content={feature} />
                    ) : null}
                  </div>
                </Form.Item>
              </Col>

              <Col xs={24} md={12}>
                <Form.Item name="feature_ar">
                  <div>
                    <h4>Add Feature ( Arabic )</h4>
                    {feature.length ? (
                      <EditText setContent={setFeatureAR} content={featureAr} />
                    ) : null}
                  </div>
                </Form.Item>
              </Col>
            </Row> */}

            <Form.Item>
              <div style={{ marginTop: 30 }}>
                <Button
                  loading={
                    addPlanMutation.isLoading || updatePlanMutation.isLoading
                  }
                  style={{
                    background: "#ECAC35",
                    width: 200,
                    height: 50,
                    margin: "auto",
                    color: "black",
                  }}
                  type="primary"
                  htmlType="submit"
                >
                  Submit
                </Button>
              </div>
            </Form.Item>
          </Form>
        </div>
      </Card>
    </div>
  );
};

export default MembershipForm;
