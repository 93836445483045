import { Button, Card, Col, Form, Input, Row } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import "react-phone-input-2/lib/style.css";
import { ContentHeader } from "../../../components";
import {
  useAddShopCategoryMutation,
  useLazyGetShopCategoryByIdQuery,
  usePutUpdateShopCategoryMutation,
} from "../../../services/category";
import { generateEncryptedKeyBody } from "../../../utils/crypto";
import { CommonBody } from "../../../types/General";
import { errorToast, successToast } from "../../../helpers";
import Upload, { RcFile } from "antd/es/upload";
import { UploadMedia } from "../../../utils/mediaUpload";
import { UploadOutlined } from "@ant-design/icons";

const ShopCategoryForm = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const url = window.location.pathname;
  const path = url.includes("add");
  const { view } = useParams();
  const [getShopCategory] = useLazyGetShopCategoryByIdQuery();
  const [categoryImage, setCategoryImage] = useState("");
  const [categoryName, setCategoryName] = useState("");
  const [addShopCategory] = useAddShopCategoryMutation();
  const [updateShopCategory] = usePutUpdateShopCategoryMutation();

  const getCategoryDetailById = async () => {
    try {
      const res = await getShopCategory(view).unwrap();

      if (res?.statusCode === 200) {
        form.setFieldsValue({
          image: res?.data?.image,
          fullname: res?.data?.name,
        });
        setCategoryName(res?.data?.name);
        setCategoryImage(res?.data?.image);
      }
    } catch (error: any) {
      console.log(error);
    }
  };

  const onFinish = async (values: any) => {
    const data = {
      name: values.fullname,
      image: path ? [categoryImage] : categoryImage,
    };

    try {
      let encryptedBody = generateEncryptedKeyBody(data) as CommonBody;

      let res;
      path
        ? (res = await addShopCategory(encryptedBody).unwrap())
        : (res = await updateShopCategory({
            encryptedBody,
            categoryId: view,
          }).unwrap());

      if (res?.statusCode === 200) {
        navigate("/shop-categories", { replace: true });
        successToast(
          path ? "Category added successfully" : "Category updated successfully"
        );
      } else {
        errorToast(res?.message);
      }
    } catch (error: any) {
      errorToast(error?.data?.message || error?.data?.error);
    }
  };

  const handleImageUpload = async (file: RcFile) => {
    if (file?.type.includes("image")) {
      try {
        const res = await UploadMedia(file);

        if (res?.statusCode === 200) {
          setCategoryImage(res?.data);
        }
      } catch (error: any) {
        console.log(error);
      }
    } else {
      errorToast("Only images are allowed");
    }

    return false;
  };

  useEffect(() => {
    if (!path) {
      getCategoryDetailById();
    }
  }, []);

  return (
    <div style={{ padding: 50 }}>
      <ContentHeader
        buttonText="Back"
        buttonNavigateTo="shop-categories"
        heading={`${path ? "Add" : "Edit"} Category`}
      />
      <Card>
        <div>
          <Form
            name="basic"
            layout="vertical"
            form={form}
            style={{
              maxWidth: "80%",
              marginTop: 10,
            }}
            onFinish={onFinish}
            initialValues={{ remember: true }}
            autoComplete="off"
          >
            <Row gutter={16}>
              <Col xs={24} md={12}>
                <Form.Item
                  name="image"
                  rules={[
                    {
                      required: true,
                      message: "Please upload an image",
                    },
                  ]}
                >
                  <Upload
                    beforeUpload={handleImageUpload}
                    showUploadList={false}
                    className="upload_imgD"
                    accept="image/*"
                  >
                    <div>
                      <h4>Upload Image</h4>
                      <Button icon={<UploadOutlined />}>Click to upload</Button>
                    </div>
                    {categoryImage ? (
                      <img
                        style={{
                          height: 110,
                          width: 110,
                          borderRadius: "50%",
                          marginTop: 20,
                        }}
                        src={categoryImage}
                        alt="profile"
                      />
                    ) : (
                      <></>
                    )}
                  </Upload>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col xs={24} md={12}>
                <Form.Item
                  name="fullname"
                  rules={[
                    {
                      required: true,
                      message: "Please input category name!",
                      whitespace: true,
                    },
                    {
                      min: 3,
                      message: "Name must be at least 3 characters",
                    },
                    // {
                    //   pattern: /^[A-Za-z\s]+$/,
                    //   message: "Please enter only alphabets",
                    // },
                  ]}
                >
                  <div>
                    <h4>Category Name</h4>
                    <Input
                      placeholder="Name"
                      style={{ height: 55 }}
                      value={categoryName}
                      onChange={(e) => {
                        setCategoryName(e.target.value);
                      }}
                    />
                  </div>
                </Form.Item>
              </Col>
            </Row>

            <Form.Item wrapperCol={{ offset: 4, span: 16 }}>
              <div
                style={{ display: "flex", marginTop: 20, alignItems: "center" }}
              >
                <Button
                  style={{
                    background: "#C69320",
                    width: 200,
                    height: 50,

                    margin: "auto",
                  }}
                  type="primary"
                  htmlType="submit"
                >
                  {path ? "Add" : "Save"}
                </Button>
              </div>
            </Form.Item>
          </Form>
        </div>
      </Card>
    </div>
  );
};

export default ShopCategoryForm;
