import { Breadcrumb, Button, Card } from "antd";
import { Link } from "react-router-dom"; // Import Link
import React from "react";

type ContentHeaderType = {
  heading: string;
  buttonText?: string;
  buttonNavigateTo?: string;
};

const ContentHeader = ({
  heading,
  buttonText,
  buttonNavigateTo,
}: ContentHeaderType) => {
  return (
    <div>
      <div
        style={{
          margin: "15px 0px",
        }}
      >
        <Breadcrumb
          style={{}}
          items={[
            {
              title: (
                <div className="breadcrumb_item">
                  <Link to="/dashboard">Dashboard</Link>{" "}
                  {/* Use Link component */}
                </div>
              ),
            },
            {
              title: <div className="breadcrumb_item">{heading}</div>,
            },
          ]}
        />
      </div>

      <Card className="content_header">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
          }}
        >
          <div
            style={{
              fontSize: 16,
              fontWeight: 600,
            }}
          >
            {heading}
          </div>
          {buttonText ? (
            <Link to={`/${buttonNavigateTo || "dashboard"}`}>
              {/* Use Link */}
              <Button
                style={{
                  background: "#ECAC35",
                  minWidth: 100,
                  height: 35,
                  color: "black",
                  fontWeight: 500,
                }}
                type="primary"
              >
                {buttonText || "Add"}
              </Button>
            </Link>
          ) : (
            <></>
          )}
        </div>
      </Card>
    </div>
  );
};

export default ContentHeader;
