import { Button, Card, Col, Form, Input, Row, Select, Spin } from "antd";
import { useEffect, useState } from "react";
import { ContentHeader } from "../../components";
import EditText from "../../components/textEditor";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useLazyGetCmsQuery, useUpdateCmsMutation } from "../../services/auth";
import { errorToast, successToast } from "../../helpers";
import { CommonBody } from "../../types/General";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import useAuth from "../../hooks/useAuth";

const ManageCms = () => {
  const userData = useAuth();

  const [cmsDetails, cmsDetailsData] = useLazyGetCmsQuery();
  const [updateCms, updateCmsData] = useUpdateCmsMutation();
  const [email, setEmail] = useState("");
  const [privacyPolicy, setPrivacyPolicy] = useState<string>("");
  const [privacyPolicyAr, setPrivacyPolicyAr] = useState<string>("");
  const [terms, setTerms] = useState("");
  const [termsAr, setTermsAr] = useState("");
  const [about, setAbout] = useState("");
  const [aboutAr, setAboutAr] = useState("");
  const [type, setType] = useState("PERCENTAGE");
  const [rights, setRights] = useState("");

  const [rightsAr, setRightsAr] = useState("");
  const [phoneCode, setPhoneCode] = useState("+971");
  const [phoneNo, setPhoneNo] = useState("");
  const [commission, setCommission] = useState<number>(0);
  const [tax, setTax] = useState<number>(0);
  const [error, setError] = useState(false);
  const [countryName, setCountryName] = useState("in");
  // const [phoneCode, setPhoneCode] = useState("+91");

  const [hidePermission, setHidePermission] = useState<any>({
    isAdd: true,
    isDelete: true,
  });

  // const handleChangePhone = (phone: any, country: any) => {
  //   setPhoneCode(country?.dialCode);
  //   // setPhoneNo(phone?.replace(country.dialCode, ""));
  // };

  const onFinish = async () => {
    if (!phoneNo) {
      setError(true);
      return;
    }
    if (phoneNo?.length < 6) {
      setError(true);
      return;
    }
    if (type === "PERCENTAGE" && commission > 100) {
      errorToast("In % , commission can't be greater then 100");
    }
    if (tax > 100) {
      errorToast("In % , tax can't be greater then 100");
    } else {
      let data = {
        email: email,
        phone: phoneNo,
        privacyPolicy:
          privacyPolicy?.replace(/target="_self"/g, 'target="_blank"') || "",
        privacyPolicy_ar:
          privacyPolicyAr?.replace(/target="_self"/g, 'target="_blank"') || "",
        termsAndCondition: about?.replace(/target="_self"/g, 'target="_blank"'),
        termsAndCondition_ar: aboutAr?.replace(
          /target="_self"/g,
          'target="_blank"'
        ),
        about_us: terms?.replace(/target="_self"/g, 'target="_blank"') || "",
        about_us_ar:
          termsAr?.replace(/target="_self"/g, 'target="_blank"') || "",
        countryCode: (phoneCode.includes("+") ? "" : "+") + phoneCode,
        eventCountryCode: (phoneCode.includes("+") ? "" : "+") + phoneCode,
        donationCountryCode: (phoneCode.includes("+") ? "" : "+") + phoneCode,
        commission,
        commissionType: type,
        eventEmail: email,
        eventPhone: phoneNo,
        donationEmail: email,
        donationPhone: phoneNo,
        tax,
        consumerRights:
          rights?.replace(/target="_self"/g, 'target="_blank"') || "",
        consumerRights_ar:
          rightsAr?.replace(/target="_self"/g, 'target="_blank"') || "",
      };

      console.log(data, "data");

      let encryptedBody = generateEncryptedKeyBody(data) as CommonBody;

      try {
        const res = await updateCms(encryptedBody).unwrap();

        if (res?.statusCode === 200) {
          successToast("CMS Updated Successfully");
          getCmsDetail();
        }
      } catch (error: any) {
        console.log(error, "error");
        errorToast(error?.message);
      }
    }
  };

  const checkPermission = () => {
    const permissions = userData?.permissions;
    if (permissions?.length) {
      let idx = -1;
      idx = permissions?.findIndex(
        (ele: any) => ele?.label === "Event Categories"
      );

      if (idx != -1) {
        let data = {
          isAdd: permissions[idx]?.isAdd,
          isDelete: permissions[idx]?.isDelete,
        };
        setHidePermission(data);
      }
    }
  };

  useEffect(() => {
    if (userData && userData?.permissions?.length) {
      checkPermission();
    }
  }, [userData]);

  const getCmsDetail = async () => {
    try {
      const res = await cmsDetails({}).unwrap();

      if (res?.statusCode === 200) {
        setPrivacyPolicy(res?.data?.privacyPolicy || "");
        setPrivacyPolicyAr(res?.data?.privacyPolicy_ar || "");
        setTerms(res?.data?.about_us || " ");
        setTermsAr(res?.data?.about_us_ar || " ");
        setEmail(res?.data?.email);
        setPhoneNo(res?.data?.phone);
        setPhoneCode(res?.data?.countryCode);
        setType(res?.data?.commissionType);
        setCommission(res?.data?.commission);
        setTax(res?.data?.tax);
        setAbout(res?.data?.termsAndCondition);
        setAboutAr(res?.data?.termsAndCondition_ar);
        setRights(res?.data?.consumerRights);
        setRightsAr(res?.data?.consumerRights_ar);
      }
    } catch (error: any) {
      console.log(error);
    }
  };
  const handleChangePhone = (phone: any, country: any) => {
    setPhoneCode(country?.dialCode);
    setPhoneNo(phone?.replace(country.dialCode, ""));
    setCountryName(country?.countryCode);
  };

  useEffect(() => {
    getCmsDetail();
  }, []);

  return (
    <div style={{ padding: 40 }}>
      <ContentHeader heading="Manage CMS" />
      <div style={{ marginBottom: 18 }}>
        <span style={{ fontSize: 17, fontWeight: 600 }}>
          Content Management System
        </span>
      </div>

      <Card>
        <Spin spinning={cmsDetailsData.isLoading}>
          <Row gutter={16}>
            <Col xs={24} md={12}>
              <h3>Privacy Policy ( English )</h3>
              {privacyPolicy ? (
                <EditText
                  content={privacyPolicy}
                  setContent={setPrivacyPolicy}
                />
              ) : null}
            </Col>

            <Col xs={24} md={12}>
              <h3>Privacy Policy ( Arabic )</h3>
              {privacyPolicy ? (
                <EditText
                  content={privacyPolicyAr}
                  setContent={setPrivacyPolicyAr}
                />
              ) : null}
            </Col>

            <Col xs={24} md={12}>
              <h3>About Us ( English )</h3>
              {terms ? (
                <EditText content={terms} setContent={setTerms} />
              ) : null}
            </Col>
            <Col xs={24} md={12}>
              <h3>About Us ( Arabic )</h3>
              {termsAr ? (
                <EditText content={termsAr} setContent={setTermsAr} />
              ) : null}
            </Col>

            <Col xs={24} md={12}>
              <h3>Terms and Conditions ( English )</h3>
              {about ? (
                <EditText content={about} setContent={setAbout} />
              ) : null}
            </Col>
            <Col xs={24} md={12}>
              <h3>Terms and Conditions ( Arabic )</h3>
              {about ? (
                <EditText content={aboutAr} setContent={setAboutAr} />
              ) : null}
            </Col>

            <Col xs={24} md={12}>
              <h3>Consumer Rights ( English )</h3>
              {rights ? (
                <EditText content={rights} setContent={setRights} />
              ) : null}
            </Col>
            <Col xs={24} md={12}>
              <h3>Consumer Rights ( Arabic )</h3>
              {rightsAr ? (
                <EditText content={rightsAr} setContent={setRightsAr} />
              ) : null}
            </Col>
          </Row>

          <Form
            onFinish={onFinish}
            name="basic"
            style={{
              maxWidth: "80%",
            }}
            initialValues={{ remember: true }}
            autoComplete="off"
          >
            <Row gutter={16} style={{ marginTop: 30 }}>
              <Col xs={24} md={12}>
                <Form.Item name="commissionType" rules={[]}>
                  <h4>Commission Type</h4>
                  <Select
                    value={type}
                    className="selector_type"
                    style={{ height: 75, width: "100%" }}
                    onChange={(val) => setType(val)}
                    placeholder="Type"
                    options={[
                      { value: "PERCENTAGE", label: "Percentage" },
                      { value: "FLAT", label: "Flat" },
                    ]}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item
                  name="commission"
                  rules={[
                    {
                      pattern: /^[0-9]*$/,
                      message: "Please enter only numeric values",
                    },
                  ]}
                >
                  <h4>Commission</h4>
                  <Input
                    value={commission}
                    prefix={type === "FLAT" ? <p>$</p> : null}
                    suffix={type === "PERCENTAGE" ? <p>%</p> : null}
                    placeholder="Commission"
                    style={{ height: 55 }}
                    onChange={(e) => {
                      const input = Number(e.target.value);
                      if (!isNaN(input)) {
                        setCommission(input);
                      }
                    }}
                    maxLength={20}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item
                  name="tax"
                  rules={[
                    {
                      pattern: /^[0-9]*$/,
                      message: "Please enter only numeric values",
                    },
                  ]}
                >
                  <h4>Tax</h4>
                  <Input
                    value={tax}
                    suffix={<p>%</p>}
                    placeholder="Tax"
                    style={{ height: 55 }}
                    onChange={(e) => {
                      const input = Number(e.target.value);
                      if (!isNaN(input)) {
                        setTax(input);
                      }
                    }}
                    maxLength={3}
                  />
                </Form.Item>
              </Col>
            </Row>

            <h3>Contact Us</h3>

            <Row gutter={16}>
              <Col xs={24} md={12}>
                <Form.Item
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: "Please input your E-mail!",
                    },
                    {
                      type: "email",
                      message: "Enter a valid email address!",
                    },
                  ]}
                >
                  <div>
                    <h4>Email</h4>
                    <Input
                      style={{ height: 55 }}
                      placeholder="E-mail"
                      value={email}
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                    />
                  </div>
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item
                  name="phone"
                  rules={[
                    {
                      required: true,
                      message: "Please input contact details",
                    },
                  ]}
                >
                  <h4>Phone Number</h4>
                  <div className="phn_cs">
                    <PhoneInput
                      enableSearch={true}
                      placeholder="Phone number"
                      containerClass={"cntn_telCls"}
                      inputClass={"cstm_cls"}
                      country={"us"}
                      buttonClass={"custm_btn"}
                      value={phoneCode + phoneNo}
                      onChange={(phone, country) =>
                        handleChangePhone(phone, country)
                      }
                    />
                  </div>
                  {error && !phoneNo ? (
                    <h3 className="error_msg">
                      Please input your phone number!
                    </h3>
                  ) : null}
                  {error && phoneNo?.length && phoneNo?.length < 6 ? (
                    <h3 className="error_msg">
                      Please input valid phone number!
                    </h3>
                  ) : null}
                </Form.Item>
              </Col>
            </Row>
          </Form>

          <Row gutter={4}>
            <Col xs={16} md={20}>
              {hidePermission?.isAdd ? (
                <Button
                  style={{
                    background: "#ECAC35",
                    width: 200,
                    height: 50,
                    margin: "auto",
                    marginTop: 20,
                    color: "black",
                  }}
                  type="primary"
                  onClick={onFinish}
                  loading={updateCmsData.isLoading}
                >
                  Save
                </Button>
              ) : (
                ""
              )}
            </Col>
          </Row>
        </Spin>
      </Card>
    </div>
  );
};

export default ManageCms;
