import { Card, Modal, TabsProps, Tabs } from "antd";
import { ContentHeader } from "../../components";

import Table, { ColumnsType } from "antd/es/table";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import {
  useForFeatureProductsMutation,
  // useLazyGetFeatureProductQuery,
  useLazyGetFeaturedProductQuery,
} from "../../services/product";

import useAuth from "../../hooks/useAuth";
import FeaturedProducts from "./featuredProducts";
import BoosterProduct from "./boosterProducts";

const RecommendedProducts = () => {
  const navigate = useNavigate();
  const [AllProductsData, setAllProductsData] = useState([]);
  const { state } = useLocation();
  console.log(state);
  const [getProduct, getProductQuery] = useLazyGetFeaturedProductQuery();
  // const [getFeaturedProductQuery] = useLazyGetFeatureProductQuery();
  const [debouncedQuery, setDebouncedQuery] = useState("");
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [activeTab, setActiveTab] = useState(state?.tab || "1");
  const [totalCount, setTotalCount] = useState<number>(0);
  const [deleteFeatureProduct] = useForFeatureProductsMutation();
  const [query, setQuery] = useState("");
  const [hidePermission, setHidePermission] = useState<any>({
    isAdd: true,
    isDelete: true,
  });
  // const [loading, setLoading] = useState(false);

  const userData = useAuth();
  const onChange = (key: string) => {
    setActiveTab(key);
  };

  const checkPermission = () => {
    const permissions = userData?.permissions;
    if (permissions?.length) {
      let idx = -1;
      idx = permissions?.findIndex((ele: any) => ele?.label === "Manage Users");
      if (idx != -1) {
        if (!permissions[idx]?.isAdd) {
          let data = {
            isAdd: false,
            isDelete: hidePermission?.isDelete,
          };
          setHidePermission(data);
        }
        if (!permissions[idx]?.isDelete) {
          let data = {
            isAdd: hidePermission?.isAdd,
            isDelete: false,
          };
          setHidePermission(data);
        }
      }
    }
  };

  const items: TabsProps["items"] = [
    {
      key: "1",
      label: `Admin`,
      children: <FeaturedProducts activeTab={activeTab} />,
    },
    {
      key: "2",
      label: `Booster`,
      children: <BoosterProduct activeTab={activeTab} />,
    },
  ];

  useEffect(() => {
    if (userData && userData?.permissions?.length) {
      checkPermission();
    }
  }, [userData]);

  const getProductDetails = async (page: number) => {
    try {
      const res = await getProduct({
        type: "booster",
        page: page,
        size: 10,
        search: query || "",
      }).unwrap();

      if (res?.statusCode === 200) {
        setAllProductsData(res?.data?.featuredProduct);
        setTotalCount(res?.data?.count);
      }
    } catch (error: any) {
      console.log(error);
    }
  };

  // useEffect(() => {
  //   getProductDetails(1);
  // }, [query]);

  useEffect(() => {
    const delay = 1000; // Debounce delay in milliseconds
    const timerId = setTimeout(() => {
      setQuery(debouncedQuery);
    }, delay);

    return () => {
      clearTimeout(timerId); // Clear the timeout on cleanup
    };
  }, [debouncedQuery]);

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
    getProductDetails(page);
  };

  return (
    <div style={{ padding: 50 }}>
      <ContentHeader
        heading="Featured Products"
        // buttonNavigateTo="recommended-products/add"
        // buttonText={hidePermission?.isAdd ? "Add Products" : ""}
      />
      <Card>
        <Tabs
          activeKey={activeTab}
          defaultActiveKey={"2"}
          items={items}
          onChange={onChange}
        />
      </Card>
    </div>
  );
};

export default RecommendedProducts;
