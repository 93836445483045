import { Button, Card, Col, Input, Row, Spin } from "antd";
import { useEffect, useState } from "react";
import { ContentHeader } from "../../components";
import {
  useAddFaqsMutation,
  useDeleteFaqMutation,
  useLazyGetAllFaqsQuery,
  useUpdateFaqsMutation,
} from "../../services/faqs";
import { successToast } from "../../helpers";
import { CommonBody, Faqs } from "../../types/General";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import useAuth from "../../hooks/useAuth";

const ManageFaq = () => {
  const [faqs, setFaqs] = useState<Faqs[]>([]);
  const [question, setQuestion] = useState("");
  const [answer, setAnswer] = useState("");
  const [questionAr, setQuestionAr] = useState("");
  const [answerAr, setAnswerAr] = useState("");
  const [editingFaq, setEditingFaq] = useState<Faqs | null>(null);
  const [faqsData, getFaqData] = useLazyGetAllFaqsQuery();
  const [addFaq, addFaqData] = useAddFaqsMutation();
  const [deleteFaq, deleteFaqData] = useDeleteFaqMutation();
  const [updateFaq] = useUpdateFaqsMutation();
  const [hidePermission, setHidePermission] = useState<any>({
    isAdd: true,
    isDelete: true,
  });

  const userData = useAuth();

  const getAllFaqsHandler = async () => {
    try {
      const res = await faqsData({}).unwrap();

      if (res?.statusCode === 200) {
        setFaqs(res?.data?.data);
      }
    } catch (error: any) {
      console.log(error);
    }
  };

  const handleAddFaq = async () => {
    let data = {
      question: question,
      answer: answer,
    };
    try {
      let encryptedBody = generateEncryptedKeyBody(data) as CommonBody;

      const res = await addFaq(encryptedBody).unwrap();

      if (res?.statusCode === 200) {
        successToast("Added Successfully");
        getAllFaqsHandler();
        setQuestion("");
        setAnswer("");
      }
    } catch (error: any) {
      console.log(error);
    }
  };

  const editFaqHandler = (id: string | undefined) => {
    const selectedFaq = faqs.find((item) => item._id === id);
    if (selectedFaq) {
      setEditingFaq(selectedFaq);
      setQuestion(selectedFaq.question);
      setAnswer(selectedFaq.answer);
    }
  };

  const updateFaqHandler = async () => {
    try {
      let data = {
        question: editingFaq ? editingFaq.question : question,
        answer: editingFaq ? editingFaq.answer : answer,
        question_ar: editingFaq ? editingFaq.question_ar : questionAr,
        answer_ar: editingFaq ? editingFaq.answer_ar : answerAr,
      };

      console.log(data, "data");

      let body = generateEncryptedKeyBody(data) as CommonBody;
      const res = await updateFaq({
        body,
        faq_id: editingFaq?._id,
      }).unwrap();

      if (res?.statusCode === 200) {
        successToast("Updated Successfully");
        getAllFaqsHandler();
        setEditingFaq(null);
        setQuestion("");
        setAnswer("");
      }
    } catch (error: any) {
      console.log(error);
    }
  };

  const removeFaqsHandler = async (id: string | undefined) => {
    try {
      const res = await deleteFaq({ faq_id: id }).unwrap();

      if (res?.statusCode === 200) {
        successToast("Deleted Successfully");

        getAllFaqsHandler();
      }
    } catch (error: any) {
      console.log(error);
    }
  };

  const checkPermission = () => {
    const permissions = userData?.permissions;
    console.log(
      userData?.permissions[userData?.permissions?.length - 1],
      "userData?.permissions"
    );

    const ManageFAQ = userData?.permissions?.filter(
      (item: any) => item?.label === "Manage FAQ's"
    );

    if (permissions) {
      let idx = -1;
      idx = permissions?.findIndex((ele: any) => ele?.label === "Manage FAQ's");
      if (idx !== -1) {
        if (!permissions[idx]?.isAdd) {
          let data = {
            isAdd: false,
            isDelete: hidePermission?.isDelete,
          };
          setHidePermission(data);
        }
        if (!permissions[idx]?.isDelete) {
          let data = {
            isAdd: ManageFAQ[0]?.isAdd,
            isDelete: false,
          };
          setHidePermission(data);
        }
      }
      console.log(!permissions[idx]?.isDelete, "INDEX");
    }
  };

  useEffect(() => {
    if (userData && userData?.permissions?.length) {
      checkPermission();
    }
  }, [userData]);

  useEffect(() => {
    getAllFaqsHandler();
  }, []);

  console.log(hidePermission?.isAdd, "PER");

  return (
    <div style={{ padding: 40 }}>
      <ContentHeader heading="Manage FAQ's" />

      <Card>
        <Spin spinning={getFaqData.isLoading || deleteFaqData.isLoading}>
          <Row gutter={4}>
            <Col xs={16} md={16}>
              <h3>FAQs</h3>

              {faqs?.map((item, index) => {
                return (
                  <div key={index}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "flex-end",
                        gap: "20px",
                        width: "100%",
                      }}
                    >
                      <div style={{ width: "100%" }}>
                        <div>
                          <h4>Question ( English ):</h4>
                          <Input
                            disabled={!!item?.question}
                            type={"text"}
                            name="name"
                            value={item?.question}
                            placeholder="Question"
                            style={{
                              height: 55,
                              marginLeft: 20,
                              color: "black",
                            }}
                          ></Input>
                          <h4>Question ( Arabic ):</h4>
                          <Input
                            disabled={!!item?.question_ar}
                            type={"text"}
                            name="name"
                            value={item?.question_ar}
                            placeholder="Question"
                            style={{
                              height: 55,
                              marginLeft: 20,
                              color: "black",
                            }}
                          ></Input>
                        </div>
                        <div style={{}}>
                          <h4>Answer ( English ):</h4>
                          <Input
                            disabled={!!item?.answer}
                            type={"text"}
                            name="name"
                            value={item?.answer}
                            placeholder="Answer"
                            style={{
                              height: 55,
                              marginLeft: 20,
                              color: "black",
                            }}
                          ></Input>
                          <h4>Answer ( Arabic ):</h4>
                          <Input
                            disabled={!!item?.answer_ar}
                            type={"text"}
                            name="name"
                            value={item?.answer_ar}
                            placeholder="Answer"
                            style={{
                              height: 55,
                              marginLeft: 20,
                              color: "black",
                            }}
                          ></Input>
                        </div>
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        marginTop: 20,
                      }}
                    >
                      {hidePermission?.isDelete ? (
                        <Button
                          style={{ left: 10 }}
                          onClick={() => removeFaqsHandler(item._id)}
                        >
                          Remove
                        </Button>
                      ) : (
                        ""
                      )}
                      {hidePermission?.isAdd ? (
                        <Button
                          style={{ left: 20 }}
                          onClick={() => editFaqHandler(item._id)}
                        >
                          Edit
                        </Button>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                );
              })}

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-end",
                  gap: "20px",
                }}
              >
                <div style={{ width: "100%" }}>
                  <div>
                    <h4>Question ( English ):</h4>
                    <Input
                      type={"text"}
                      name="name"
                      value={editingFaq ? editingFaq.question : question}
                      onChange={(val) => {
                        if (editingFaq) {
                          setEditingFaq({
                            ...editingFaq,
                            question: val.target.value,
                          });
                        } else {
                          setQuestion(val.target.value);
                        }
                      }}
                      placeholder="Question"
                      style={{ height: 55, marginLeft: 20 }}
                    ></Input>
                    <h4>Question ( Arabic ):</h4>
                    <Input
                      type={"text"}
                      name="name"
                      value={editingFaq ? editingFaq.question_ar : questionAr}
                      onChange={(val) => {
                        if (editingFaq) {
                          setEditingFaq({
                            ...editingFaq,
                            question_ar: val.target.value,
                          });
                        } else {
                          setQuestionAr(val.target.value);
                        }
                      }}
                      placeholder="Question"
                      style={{ height: 55, marginLeft: 20 }}
                    ></Input>
                  </div>
                  <div>
                    <h4>Answer ( English ):</h4>
                    <Input
                      type={"text"}
                      name="name"
                      value={editingFaq ? editingFaq.answer : answer}
                      onChange={(val) => {
                        if (editingFaq) {
                          setEditingFaq({
                            ...editingFaq,
                            answer: val.target.value,
                          });
                        } else {
                          setAnswer(val.target.value);
                        }
                      }}
                      placeholder="Answer"
                      style={{
                        height: 55,
                        marginLeft: 20,
                      }}
                    ></Input>
                    <h4>Answer ( Arabic ):</h4>
                    <Input
                      type={"text"}
                      name="name"
                      value={editingFaq ? editingFaq.answer_ar : answerAr}
                      onChange={(val) => {
                        if (editingFaq) {
                          setEditingFaq({
                            ...editingFaq,
                            answer_ar: val.target.value,
                          });
                        } else {
                          setAnswerAr(val.target.value);
                        }
                      }}
                      placeholder="Answer"
                      style={{
                        height: 55,
                        marginLeft: 20,
                      }}
                    ></Input>
                  </div>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginTop: 20,
                }}
              >
                {hidePermission?.isAdd ? (
                  <Button
                    style={{
                      background: "#7DBFDD",
                      width: 90,
                      height: 40,
                      color: "black",
                      left: 20,
                    }}
                    loading={addFaqData.isLoading}
                    onClick={editingFaq ? updateFaqHandler : handleAddFaq}
                    disabled={!question || !answer}
                  >
                    {editingFaq ? "Update" : "Add"}
                  </Button>
                ) : (
                  ""
                )}
              </div>
            </Col>
          </Row>
        </Spin>
      </Card>
    </div>
  );
};

export default ManageFaq;
