// @ts-nocheck
import {
  Button,
  Card,
  Checkbox,
  Col,
  Form,
  Input,
  Row,
  Select,
  Switch,
} from "antd";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import "react-phone-input-2/lib/style.css";
import { ContentHeader, MultipleImages } from "../../../components";
import { RcFile } from "antd/es/upload";
import { CloseCircleOutlined } from "@ant-design/icons";
import { UploadMedia } from "../../../utils/mediaUpload";
import { errorToast, successToast } from "../../../helpers";
import { generateEncryptedKeyBody } from "../../../utils/crypto";
import { CommonBody, category, SubCategory } from "../../../types/General";
import {
  usePutUpdateProductMutation,
  useLazyGetProductByIdQuery,
  useAddProductMutation,
  useLazyGetSubCategoryListQuery,
} from "../../../services/product";
import { useLazyGetCategoryQuery } from "../../../services/category";
import TextArea from "antd/es/input/TextArea";
import { Autocomplete } from "@react-google-maps/api";

const ProductForm = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;
  const [form] = Form.useForm();
  const [addProduct] = useAddProductMutation();
  const [updateProduct] = usePutUpdateProductMutation();
  const [getSubCatMethod] = useLazyGetSubCategoryListQuery();

  const [getProductDataById] = useLazyGetProductByIdQuery();
  const [imageObject, setImageObject] = useState<RcFile[] | number[] | any>([]);
  const [imagePreview, setImagePreview] = useState<string[]>([]);
  const [subCategoryData, setSubCategoryData] = useState<SubCategory[]>([]);
  const [selectedSubCategory, setSelectedSubCategory] = useState<
    string | undefined
  >(undefined);

  const [getCategory] = useLazyGetCategoryQuery();
  const [allCategoryData, setAllCategoryData] = useState<category[]>();

  const [productName, setProductName] = useState("");
  const [brand, setBrand] = useState("");
  const [price, setPrice] = useState("");
  const [qtn, setQtn] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [address, setAddress] = useState("");
  const [loading, setLoading] = useState(false);
  const [getImages, setGetImages] = useState([]);
  console.log(imageObject, "IMGGG");
  const [message, setMessage] = useState("");
  const { Option } = Select;
  const [autocomplete, setAutocomplete] = useState(null);
  const [userAddress, setUserAddress] = useState("");
  const [city, setCity] = useState("");
  const [states, setStates] = useState("");
  const [country, setCountry] = useState("");
  console.log(address, "---------");
  const [lat, setLat] = useState("");
  const [long, setLong] = useState("");
  const [makeAnOffer, setMakeAnOffer] = useState(false);

  const handleSwitchChange = (checked) => {
    setMakeAnOffer(checked);
  };

  const onKeypress = (e: any) => {
    if (e.key === "Enter") {
      e.preventDefault();
      return false;
    }
  };
  const handleChange = (event) => {
    const { value } = event.target;
    // Define regex patterns for email and phone number
    const emailPattern = /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}\b/;
    const phonePattern = /[0-9]{7}/;
    // Check if the entered value contains email or phone number pattern
    if (value.includes("@") || phonePattern.test(value)) {
      // If email or phone number is detected, clear the input
      // formik.setFieldValue(name, “”); // Clear the field value
      errorToast("You cannot enter an email or phone number here.");
      return;
    }
    // Update formik values for other inputs
    setMessage(value);
  };

  const [language, setLanguage] = useState("english"); // State to track the selected language

  const handleLanguageChange = (e) => {
    // Function to handle checkbox change
    setLanguage(e.target.checked ? e.target.value : "");
  };

  const onLoad = (autocompleteObj: any) => {
    setAutocomplete(autocompleteObj);
  };
  const onPlaceChanged = async () => {
    if (autocomplete) {
      const place = await autocomplete.getPlace();
      if (place) {
        form.setFieldsValue({
          address: place.formatted_address,
        });

        setAddress(place.formatted_address);
        setUserAddress(place.formatted_address);

        let city = "";
        let state = "";
        let country = "";

        // Loop through address components to find city, state, and country
        place.address_components.forEach((component) => {
          if (component.types.includes("locality")) {
            city = component.long_name;
          } else if (component.types.includes("administrative_area_level_1")) {
            state = component.long_name;
          } else if (component.types.includes("country")) {
            country = component.long_name;
          }
        });

        console.log("City:", city);
        setCity(city);
        console.log("State:", state);
        setStates(state);
        console.log("Country:", country);
        setCountry(country);

        const lat = place.geometry.location.lat();
        const lng = place.geometry.location.lng();

        setLat(lat);
        setLong(lng);
      }
    }
  };

  const getProductDetailById = async (id: string) => {
    try {
      const res = await getProductDataById(id).unwrap();

      if (res?.statusCode === 200) {
        form.setFieldsValue({
          image: res?.data?.image,
          productName: res?.data?.name,
          priceOfTheProduct: res?.data?.price,
          categoryOfTheProduct: res?.data?.categoryId?._id,
          addressOfTheUser: res?.data?.address,
          description: res?.data?.description,
          subCategoryField: res?.data?.subCategoryId?._id,
          brandField: res?.data?.brand,
        });
        setProductName(res?.data?.name);
        setPrice(res?.data?.price);
        setQtn(res?.data?.totalQuantity);
        setSelectedCategory(res?.data?.categoryId?._id);
        setSelectedSubCategory(res?.data?.subCategoryId?._id);
        setAddress(res?.data?.address);
        setUserAddress(res?.data?.address);
        setGetImages(res?.data?.images);
        setBrand(res?.data?.brand);
        setMessage(res?.data?.description);
        setMakeAnOffer(res?.data?.makeAnOffer);
      }
    } catch (error: any) {
      console.log(error);
    }
  };

  const handleImageDelete = (index: number) => {
    let previewImages = imagePreview.filter((_, i) => i !== index);
    setImagePreview(previewImages);
    let objectImages = (imageObject as any).filter(
      (_: any, i: number) => i !== index
    );
    setImageObject(objectImages as any);
  };

  const handleGetImageDelete = (index: number) => {
    let previewImages = getImages.filter((_, i) => i !== index);
    setGetImages(previewImages);
  };

  const handleImageUpload = async (file: RcFile) => {
    setLoading(true);

    if (file) {
      const allowedTypes = ["image/png", "image/jpeg", "image/jpg"];
      const invalidFiles = Array.from(file).filter(
        (file) => !allowedTypes.includes(file.type)
      );
      if (invalidFiles.length > 0) {
        errorToast(translation.Globals.invalidImg);
        return;
      }

      try {
        const res = await UploadMedia(file);
        setLoading(false);
        if (res?.statusCode === 200) {
          return res?.data;
        }
        return 0;
      } catch (error: any) {
        setLoading(false);
        console.log(error);
        return 0;
      }
    }
  };

  const onFinish = async (values: any) => {
    if (!imageObject?.length && !getImages?.length) {
      errorToast("Please provide images for product.");
      return;
    }

    if (language === "") {
      errorToast("Please select a language");
      return;
    }

    // Define an array to store the uploaded image URLs
    let uploadedImages = [] as string[];

    // Loop through imageObject to upload each image sequentially
    for (const item of imageObject) {
      // If the item is a number, it's already uploaded, so just push it to the uploadedImages array
      if (typeof item === "number") {
        uploadedImages.push(item.toString());
      } else {
        // If the item is a file, upload it and push the URL to the uploadedImages array
        const result = await handleImageUpload(item);
        uploadedImages.push(result);
      }
    }

    // Concatenate the uploaded image URLs with existing images from getImages
    const images = Array.from(getImages).concat(uploadedImages);

    // Construct the data object with the uploaded images
    const data = {
      name: values?.productName,
      images: images,
      price: values?.priceOfTheProduct,
      totalQuantity: values.quantityOfTheproduct,
      country: country,
      address: userAddress,
      categoryId: selectedCategory || "",
      subCategoryId: selectedSubCategory || "",
      brand: brand || "",
      description: message || "",
      state: states,
      city: city,
      latitude: lat?.toString(),
      longitude: long?.toString(),
      makeAnOffer: makeAnOffer,
      lang: language === "english" ? 1 : language === "arabic" ? 2 : "",
    };
    
    if (id) {
      console.log("edit");
      try {
        let body = generateEncryptedKeyBody(data) as CommonBody;

        const res = await updateProduct({
          body,
          productId: id,
        }).unwrap();

        if (res?.statusCode === 200) {
          navigate("/listed-products-posts", { replace: true });
          successToast(res?.message);
        } else {
          errorToast(res?.message);
        }
      } catch (error: any) {
        errorToast(error?.data?.message || error?.data?.error);
      }
    } else {
      console.log("add");
      try {
        let body = generateEncryptedKeyBody(data) as CommonBody;

        const res = await addProduct(body).unwrap();

        if (res?.statusCode === 200) {
          navigate("/listed-products-posts", { replace: true });
          successToast(res?.message);
        } else {
          errorToast(res?.message);
        }
      } catch (error: any) {
        errorToast(error?.data?.message || error?.data?.error);
      }
    }
  };

  const getCategoryDetails = async (page: number) => {
    try {
      setLoading(true);
      const res = await getCategory({
        page: page,
        size: 50,
        search: "",
      }).unwrap();
      setLoading(false);

      if (res?.statusCode === 200) {
        setAllCategoryData(res?.data?.category);
      }
    } catch (error: any) {
      setLoading(false);
      console.log(error);
    }
  };

  const handleChangeForSelectedSubCategory = (value: any) => {
    form.setFieldsValue({
      subCategoryField: value,
    });
    setSelectedSubCategory(value ? value : undefined);

    form.validateFields(["subCategoryField"]);
  };

  const handleChangeForSelectedCategory = (value: any) => {
    form.setFieldsValue({
      categoryOfTheProduct: value,
    });
    setSelectedCategory(value);

    form.validateFields(["categoryOfTheProduct"]);
  };

  const handleBrandSelect = (value: any) => {
    setBrand(value);
  };

  const getSubCategoryHandler = async () => {
    try {
      setLoading(true);
      const res = await getSubCatMethod({
        categoryId: selectedCategory,
        page: 1,
        size: 50,
        search: "",
        isAll: true,
      }).unwrap();
      setLoading(false);

      if (res?.statusCode === 200) {
        setSubCategoryData(res?.data?.subCategory);
      }
    } catch (error: any) {
      setLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    getCategoryDetails(1);
    getProductDetailById(id as string);
  }, []);

  useEffect(() => {
    if (selectedCategory?.length) {
      getSubCategoryHandler();
    }
  }, [selectedCategory]);

  return (
    <div style={{ padding: 50 }}>
      {/* <ContentHeader
        buttonText="Back"
        buttonNavigateTo="listed-products-posts"
        heading={id ? "Edit Product" : "Add Product"}
      /> */}

      <Card>
        <div
          style={{
            alignItems: "center",
            display: "flex",
            justifyContent: "space-between",
            flexWrap: "wrap",
          }}
        >
          <h2>{id ? "Edit Product" : "Add Product"}</h2>
          <Button
            style={{
              background: "#ECAC35",
              width: 100,
              height: 40,
              color: "black",
            }}
            type="primary"
            // htmlType="submit"
            onClick={() => {
              navigate("/listed-products-posts", {
                replace: true,
                state: {
                  page: state?.page,
                },
              });
            }}
          >
            Back
          </Button>
        </div>
        <div>
          <Form
            name="basic"
            layout="vertical"
            form={form}
            // labelCol={{ span: 8 }}
            // wrapperCol={{ span: 16 }}
            style={{
              maxWidth: "80%",
              marginTop: 10,
            }}
            onFinish={onFinish}
            initialValues={{ remember: true }}
            // onFinish={onFinish}
            //   onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <h3>Add images for the product</h3>

            <Row gutter={16}>
              <Col xs={24} md={24}>
                <Row gutter={16}>
                  {getImages?.map((item, index) => (
                    <div
                      key={index}
                      onClick={() => handleGetImageDelete(index)}
                      className="img_contnr"
                    >
                      <CloseCircleOutlined
                        style={{
                          color: "red",
                          position: "absolute",
                          right: 4,
                          top: 4,
                          fontSize: 20,
                          cursor: "pointer",
                        }}
                      />
                      {item ? (
                        <figure>
                          {/* @ts-ignore */}

                          <img alt="" src={item} />
                        </figure>
                      ) : null}
                    </div>
                  ))}
                  {imagePreview?.map((item, index) => (
                    <div key={index} className="img_contnr">
                      <CloseCircleOutlined
                        onClick={() => handleImageDelete(index)}
                        style={{
                          color: "red",
                          position: "absolute",
                          right: 4,
                          top: 4,
                          fontSize: 20,
                          cursor: "pointer",
                        }}
                      />
                      <figure>
                        <img alt="" src={item} />
                      </figure>
                    </div>
                  ))}
                </Row>

                {imageObject?.length < 6 ? (
                  <div className="img_contnr2">
                    <MultipleImages
                      imageObject={imageObject}
                      setImageObject={setImageObject}
                      imagePreview={imagePreview}
                      setImagePreview={setImagePreview}
                    />
                  </div>
                ) : (
                  ""
                )}
              </Col>
            </Row>
            <Row gutter={16} style={{ paddingBottom: 20 }}>
              <Col xs={24} md={12}>
                <h4>Language</h4>
                {/* English Checkbox */}
                <Checkbox
                  value="english"
                  checked={language === "english"}
                  onChange={handleLanguageChange}
                >
                  English
                </Checkbox>
                {/* Arabic Checkbox */}
                <Checkbox
                  value="arabic"
                  checked={language === "arabic"}
                  onChange={handleLanguageChange}
                >
                  Arabic
                </Checkbox>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col xs={24} md={8}>
                <Form.Item
                  name="productName"
                  rules={[
                    {
                      required: true,
                      message: "Please input Product name!",
                      whitespace: true,
                    },
                    {
                      min: 3,
                      message: "Name must be at least 3 characters",
                    },
                    {
                      max: 100,
                      message: "Name can have maximum 100 characters",
                    },
                  ]}
                >
                  <div>
                    <h4>Product Name</h4>
                    <Input
                      placeholder="Full Name"
                      style={{ height: 55 }}
                      onChange={(val) => {
                        const newValue = val.target.value;
                        const phonePattern = /[0-9]{6}/;
                        // Check if the entered value contains email or phone number pattern
                        if (phonePattern.test(newValue)) {
                          errorToast(
                            "You cannot enter more than 5 digits at a time"
                          );
                          return;
                        }
                        // Update formik values for other inputs
                        setProductName(newValue);
                      }}
                      value={productName}
                      maxLength={100}
                    />
                  </div>
                </Form.Item>
              </Col>
              <Col xs={24} md={8}>
                <Form.Item
                  name="priceOfTheProduct"
                  rules={[
                    {
                      required: true,
                      message: "Please input Price of the Product!",
                    },
                  ]}
                >
                  <div>
                    <h4>Price of the Product</h4>
                    <Input
                      placeholder="Price of the Product"
                      style={{ height: 55 }}
                      value={price}
                      onChange={(e) => {
                        let inputPrice = e.target.value;

                        // Remove non-numeric characters except for dot
                        inputPrice = inputPrice.replace(/[^\d.]/g, "");

                        // Allow only one dot
                        const dotIndex = inputPrice.indexOf(".");
                        if (dotIndex !== -1) {
                          inputPrice = inputPrice.slice(0, dotIndex + 3);
                        }

                        setPrice(inputPrice);
                      }}
                      maxLength={12}
                    />
                  </div>
                </Form.Item>
              </Col>
              <Col xs={24} md={8}>
                <Form.Item
                  name="quantityOfTheproduct"
                  rules={[
                    {
                      required: true,
                      message: "Please input minimum number of quantity",
                    },
                  ]}
                >
                  <div>
                    <h4>Minimum Quantity</h4>
                    <Input
                      placeholder="Minimum Number of quantity"
                      style={{ height: 55 }}
                      value={qtn}
                      onChange={(e) => {
                        let inputPrice = e.target.value;

                        setQtn(inputPrice);
                      }}
                      maxLength={15}
                    />
                  </div>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col xs={24} md={12}>
                <Form.Item
                  name="categoryOfTheProduct"
                  rules={[
                    {
                      required: true,
                      message: "Please select Category of the Product!",
                    },
                  ]}
                >
                  <div>
                    <h4>Category of the Product</h4>
                    <Select
                      allowClear
                      maxLength={20}
                      className="select_type"
                      onChange={(val) => handleChangeForSelectedCategory(val)}
                      value={selectedCategory}
                      // style={{ height: 75, width: "100%" }}
                      placeholder="Select Category"
                      showSearch
                      optionFilterProp="label" // Set the property to use for filtering options
                      filterOption={(input, option) =>
                        (
                          option?.label?.toString()?.toLowerCase() || ""
                        ).indexOf(input.toLowerCase()) !== -1
                      }
                    >
                      {allCategoryData?.map((item, index) => (
                        <Option
                          key={index}
                          value={item?._id}
                          label={item?.name}
                        >
                          {item?.name}
                        </Option>
                      ))}
                    </Select>
                  </div>
                </Form.Item>
              </Col>

              <Col xs={24} md={12}>
                <Form.Item
                  name="subCategoryField"
                  rules={[
                    {
                      required: true,
                      message: "Please select Sub Category of the product!",
                    },
                  ]}
                >
                  <div>
                    <h4>Select Sub Category of the Product</h4>
                    <Select
                      allowClear
                      maxLength={20}
                      placeholder="Select Category"
                      className="select_type"
                      disabled={selectedCategory ? false : true}
                      onChange={(val) =>
                        handleChangeForSelectedSubCategory(val)
                      }
                      value={selectedSubCategory}
                      style={{ height: 60, width: "100%" }}
                      showSearch
                      optionFilterProp="label"
                      filterOption={(input, option) =>
                        (
                          option?.label?.toString()?.toLowerCase() || ""
                        ).indexOf(input.toLowerCase()) !== -1
                      }
                    >
                      {subCategoryData?.map((item, index) => (
                        <Option
                          key={index}
                          value={item?._id}
                          label={item?.name}
                        >
                          {item?.name}
                        </Option>
                      ))}
                    </Select>
                  </div>
                </Form.Item>
              </Col>

              <Col xs={24} md={12}>
                <Form.Item
                  name="brandField"
                  // rules={[
                  //   {
                  //     required: true,
                  //     message: "Please select Brand of the product!",
                  //   },
                  // ]}
                >
                  <div>
                    <h4>Select Brand of the Product</h4>
                    <Select
                      allowClear
                      maxLength={20}
                      placeholder="Select Category"
                      className="select_type"
                      disabled={selectedSubCategory ? false : true}
                      onChange={(val) => handleBrandSelect(val)}
                      value={brand}
                      style={{ height: 60, width: "100%" }}
                      showSearch
                      optionFilterProp="label"
                      filterOption={(input, option) =>
                        (
                          option?.label?.toString()?.toLowerCase() || ""
                        ).indexOf(input.toLowerCase()) !== -1
                      }
                    >
                      {subCategoryData[0]?.brand?.map((item, index) => (
                        <Option key={index} value={item} label={item}>
                          {item}
                        </Option>
                      ))}
                    </Select>
                  </div>
                </Form.Item>
              </Col>

              {/* <Col xs={24} md={12}>
                <Form.Item
                  name="addressOfTheUser"
                  rules={[
                    {
                      required: true,
                      message: "Please input Address of the User!",
                    },
                  ]}
                >
                  <div>
                    <h4>Address of the User</h4>
                 
                    <Autocomplete
                      onLoad={onLoad}
                      onPlaceChanged={onPlaceChanged}
                    >
                      <div>
                        <Input
                          allowClear
                          value={address}
                          onChange={(e) => {
                            setAddress(e.target.value);
                          }}
                          style={{ height: 55 }}
                          placeholder="Address"
                          onKeyDown={onKeypress}
                        />
                      </div>
                    </Autocomplete>
                  </div>
                </Form.Item>
              </Col> */}

              <Col xs={24} md={12}>
                <Form.Item
                  name="description"
                  rules={[
                    {
                      required: true,
                      message: "Please input Description!",
                      whitespace: true,
                    },
                    {
                      max: 700,
                      message: "Description can have maximum 700 characters",
                    },
                  ]}
                >
                  <div>
                    <h4>Description</h4>
                    <TextArea
                      rows={8}
                      placeholder="Description"
                      value={message}
                      onChange={handleChange}
                      maxLength={700}
                    />
                  </div>
                </Form.Item>
              </Col>
            </Row>
            <Col xs={24} md={12}>
              <div style={{ display: "flex", alignItems: "center", gap: 15 }}>
                <h4>Make an offer</h4>
                <Switch onChange={handleSwitchChange} checked={makeAnOffer} />
              </div>
            </Col>
            <Form.Item wrapperCol={{ offset: 4, span: 16 }}>
              <div style={{ display: "flex" }}>
                <Button
                  style={{
                    background: "#ECAC35",
                    width: 200,
                    height: 50,
                    margin: "auto",
                    color: "black",
                  }}
                  type="primary"
                  htmlType="submit"
                  loading={loading}
                >
                  Submit
                </Button>
              </div>
            </Form.Item>
          </Form>
        </div>
      </Card>
    </div>
  );
};

export default ProductForm;
