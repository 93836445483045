// @ts-nocheck

import { Button, Card, Col, Form, Input, Row, Upload } from "antd";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { UploadOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { ContentHeader } from "../../../components";
import { UploadMedia } from "../../../utils/mediaUpload";
import { errorToast, successToast } from "../../../helpers";
import { RcFile } from "antd/es/upload";
import { Autocomplete } from "@react-google-maps/api";

import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import {
  useLazyGetUserDetailsIdQuery,
  usePutChangeSellerStatusMutation,
  usePutUpdateUserMutation,
} from "../../../services/user";
import { CommonBody } from "../../../types/General";
import { generateEncryptedKeyBody } from "../../../utils/crypto";

const UsersForm = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const { id } = useParams();
  const [getUser, getUserData] = useLazyGetUserDetailsIdQuery();
  const [error, setError] = useState(false);
  const [isSeller, setIsSeller] = useState(false);

  const [values, setValues] = useState({
    fullName: "",
    lastName: "",
    userName: "",
    email: "",
    address: "",
  });
  const [profileImage, setProfileImage] = useState("");
  const [editUser, editUserData] = usePutUpdateUserMutation();
  const [countryName, setCountryName] = useState("in");
  const [phoneCode, setPhoneCode] = useState("+91");
  const [businessProfile, setBusinessProfile] = useState("");
  const [businessName, setBusinessName] = useState("");
  const [businessLandline, setBusinessLandline] = useState("");
  const [businessAddress, setBusinessAddress] = useState("");
  const [phoneNo, setphoneNo] = useState("");
  const [updateSeller] = usePutChangeSellerStatusMutation();

  const [businessLicense, setBusinessLicense] = useState("");
  const [businessPhoneCode, setBusinessPhoneCode] = useState("+91");
  const [businessPhone, setBusinessPhone] = useState("");
  const [businessEmail, setBusinessEmail] = useState("");
  const [autocomplete, setAutocomplete] = useState(null);
  const [lat, setLat] = useState("");
  const [long, setLong] = useState("");
  const location = useLocation();
  const { state } = location;

  const handleChangePhone = (phone: any, country: any) => {
    setPhoneCode(country?.dialCode);
    setphoneNo(phone?.replace(country.dialCode, ""));
    setCountryName(country?.countryCode);
  };

  const handleChangeBusinessPhone = (phone: any, country: any) => {
    setBusinessPhoneCode(country?.dialCode);
    setBusinessPhone(phone?.replace(country.dialCode, ""));
    // setCountryName(country?.countryCode);
  };

  const onKeypress = (e: any) => {
    if (e.key === "Enter") {
      e.preventDefault();
      return false;
    }
  };

  const onLoad = (autocompleteObj: any) => {
    setAutocomplete(autocompleteObj);
  };
  const onPlaceChanged = async () => {
    if (autocomplete) {
      var place = await autocomplete.getPlace();
      if (place) {
        form.setFieldsValue({
          address: place.formatted_address,
        });
        setValues({
          ...values,
          address: place.formatted_address,
        });

        const lat = place.geometry.location.lat();
        const lng = place.geometry.location.lng();
        setLat(lat);
        setLong(lng);
      }
    }
  };

  const handleUpdateSeller = async () => {
    const data = {
      logoImage: businessProfile,
      businessName: businessName,
      address: businessAddress,
      phone: businessPhone,
      countryCode: businessPhoneCode,
      landline: businessLandline,
      email: businessEmail,
      licenseNo: businessLicense,
    };
    console.log(data);
    let body = generateEncryptedKeyBody(data) as CommonBody;

    try {
      const response = await updateSeller({
        userId: id,
        encryptedBody: body,
      }).unwrap();
      if (response?.statusCode === 200) {
        successToast("Business details updated");
        getUserDetails();
      }
    } catch (error: any) {
      errorToast(error?.data?.message || "");
    }
  };

  const onFinish = async (values: any) => {
    if (!phoneNo) {
      setError(true);
      return;
    }
    if (phoneNo?.length < 6) {
      setError(true);
      return;
    }
    try {
      let data = {
        fullName: values.fullName,
        lastName: values.lastName,
        email: values.email,
        countryCode: (phoneCode.includes("+") ? "" : "+") + phoneCode,
        countryName: countryName,
        phone: phoneNo,
        image: profileImage,
        address: values.address,
        latitude: lat?.toString(),
        longitude: long?.toString(),
      };
      console.log(data, "data");

      let encryptedBody = generateEncryptedKeyBody(data) as CommonBody;
      console.log(encryptedBody, "encryptedBody");

      if (id) {
        const res = await editUser({ encryptedBody, userId: id }).unwrap();
        if (res?.statusCode === 200) {
          navigate("/manageusers", {
            replace: true,
            state: {
              page: state?.page,
              type: state?.type === "customer" ? "first" : "second",
            },
          });
          successToast("User Updated Successfully");
        }
      }
    } catch (error: any) {
      errorToast(error?.data?.message);
    }
  };

  const getUserDetails = async () => {
    try {
      const res = await getUser(id).unwrap();

      if (res?.statusCode === 200) {
        form.setFieldsValue({
          email: res?.data?.email,
          phone: res?.data?.phone,
          fullName: res?.data?.fullName,
          lastName: res?.data?.lastName,
          address: res?.data?.address,
          businessName: res?.data?.sellerForm?.businessName,
          businessPhone: res?.data?.sellerForm?.phone,
          businessAddress: res?.data?.sellerForm?.address,
          businessEmail: res?.data?.sellerForm?.email,
          // tokens: parseInt(res?.data?.tokens),
        });

        setphoneNo(res?.data?.phone || "");
        setPhoneCode(res?.data?.countryCode || "");
        setProfileImage(res?.data?.image || "");

        setLat(res?.data?.latitude);
        setLong(res?.data?.longitude);
        setIsSeller(res?.data?.sellerForm);
        setBusinessProfile(res?.data?.sellerForm?.logoImage);
        setBusinessName(res?.data?.sellerForm?.businessName);
        setBusinessAddress(res?.data?.sellerForm?.address);
        setBusinessPhone(res?.data?.sellerForm?.phone);
        setBusinessPhoneCode(res?.data?.sellerForm?.countryCode);
        setBusinessLandline(res?.data?.sellerForm?.landline);
        setBusinessEmail(res?.data?.sellerForm?.email);
        setBusinessLicense(res?.data?.sellerForm?.licenseNo);
        setValues({
          ...values,
          fullName: res?.data?.fullName || "",
          lastName: res?.data?.lastName || "",
          email: res?.data?.email || "",
          address: res?.data?.address || "",
        });
      }
    } catch (error: any) {
      console.log(error);
    }
  };

  useEffect(() => {
    getUserDetails();
  }, []);

  const handleImageUpload = async (file: RcFile) => {
    if (file?.type.includes("image")) {
      try {
        const res = await UploadMedia(file);

        if (res?.statusCode === 200) {
          setProfileImage(res?.data);
        }
      } catch (error: any) {
        console.log(error);
      }
    } else {
      errorToast("Only images are allowed");
    }

    return false; // Prevent Ant Design from automatically uploading the file
  };

  const handleImage = async (file: RcFile) => {
    if (file?.type.includes("image")) {
      try {
        const res = await UploadMedia(file);

        if (res?.statusCode === 200) {
          setBusinessProfile(res?.data);
        }
      } catch (error: any) {
        console.log(error);
      }
    } else {
      errorToast("Only images are allowed");
    }

    return false; // Prevent Ant Design from automatically uploading the file
  };

  return (
    <div style={{ padding: 50 }}>
      {/* <ContentHeader
        buttonText="Back"
        buttonNavigateTo="manageusers"
        heading="Edit User"
        
      /> */}
      <Card loading={getUserData.isLoading}>
        <div
          style={{
            alignItems: "center",
            display: "flex",
            justifyContent: "space-between",
            flexWrap: "wrap",
          }}
        >
          <h2>Edit User</h2>
          <Button
            style={{
              background: "#ECAC35",
              width: 100,
              height: 40,
              color: "black",
            }}
            type="primary"
            // htmlType="submit"
            onClick={() => {
              navigate("/manageusers", {
                replace: true,
                state: {
                  page: state?.page,
                  type: state?.type === "customer" ? "first" : "second",
                },
              });
            }}
          >
            Back
          </Button>
        </div>
        <div>
          <Form
            name="basic"
            layout="vertical"
            form={form}
            // labelCol={{ span: 8 }}
            // wrapperCol={{ span: 16 }}
            style={{
              maxWidth: "100%",
              marginTop: 10,
            }}
            initialValues={{ remember: true }}
            onFinish={onFinish}
            //   onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <Row gutter={16}>
              <Col xs={24} md={12}>
                <Form.Item name="image">
                  <Upload
                    beforeUpload={handleImageUpload}
                    showUploadList={false}
                    className="upload_imgD"
                    accept="image/*"
                  >
                    <div>
                      <h4>Upload Profile Image</h4>
                      <Button icon={<UploadOutlined />}>Click to upload</Button>
                    </div>
                    {profileImage ? (
                      <LazyLoadImage
                        alt="profile"
                        style={{
                          height: 110,
                          width: 110,
                          borderRadius: "50%",
                          marginTop: 20,
                        }}
                        src={profileImage || ""}
                        effect="blur"
                      />
                    ) : (
                      <></>
                    )}
                  </Upload>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col xs={24} md={12}>
                <Form.Item
                  name="fullName"
                  rules={[
                    {
                      required: true,
                      message: "Please input your first name!",
                      whitespace: true,
                    },
                    {
                      min: 3,
                      message: "Name must be at least 3 characters",
                    },
                    {
                      pattern: /^[A-Za-z\s]+$/,
                      message: "Please enter only alphabets",
                    },
                  ]}
                >
                  <div>
                    <h4>Full Name</h4>
                    <Input
                      value={values?.fullName}
                      onChange={(e) =>
                        setValues({ ...values, fullName: e.target.value })
                      }
                      placeholder="Full Name"
                      style={{ height: 55 }}
                      maxLength={40}
                    />
                  </div>
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: "Please input email!",
                    },
                    {
                      type: "email",
                      message: "The input is not valid email!",
                    },
                  ]}
                >
                  <div>
                    <h4>Email</h4>
                    <Input
                      value={values.email}
                      onChange={(e) =>
                        setValues({ ...values, email: e.target.value })
                      }
                      style={{ height: 55 }}
                      placeholder="Email"
                      maxLength={40}
                    />
                  </div>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col xs={24} md={12}>
                <Form.Item
                  name="phone"
                  rules={[
                    {
                      required: true,
                      message: "Please input your phone number!",
                    },
                  ]}
                >
                  <div>
                    <h4>Phone Number</h4>
                    <div className="phn_cs">
                      <PhoneInput
                        value={phoneCode + phoneNo}
                        enableSearch={true}
                        placeholder="Phone number"
                        containerClass={"cntn_telCls"}
                        inputClass={"cstm_cls"}
                        buttonClass={"custm_btn"}
                        onChange={(phone, country) =>
                          handleChangePhone(phone, country)
                        }
                      />
                    </div>
                    {error && !phoneNo ? (
                      <h3 className="error_msg">
                        Please input your phone number!
                      </h3>
                    ) : null}
                    {error && phoneNo?.length && phoneNo?.length < 6 ? (
                      <h3 className="error_msg">
                        Please input valid phone number!
                      </h3>
                    ) : null}
                  </div>
                </Form.Item>
              </Col>
            </Row>

            {isSeller ? (
              <>
                <h2>Business Details</h2>

                <Row gutter={16}>
                  <Col xs={24} md={12}>
                    <Form.Item name="image">
                      <Upload
                        beforeUpload={handleImage}
                        showUploadList={false}
                        className="upload_imgD"
                        accept="image/*"
                      >
                        <div>
                          <h4>Image</h4>
                          <Button icon={<UploadOutlined />}>
                            Click to upload
                          </Button>
                        </div>
                        {businessProfile ? (
                          <LazyLoadImage
                            alt="profile"
                            style={{
                              height: 110,
                              width: 110,
                              borderRadius: "50%",
                              marginTop: 20,
                            }}
                            src={businessProfile || ""}
                            effect="blur"
                          />
                        ) : (
                          <></>
                        )}
                      </Upload>
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={16}>
                  <Col xs={24} md={12}>
                    <Form.Item
                      name="businessName"
                      rules={[
                        {
                          required: true,
                          message: "Please input your Business name",
                          whitespace: true,
                        },
                        {
                          min: 3,
                          message: "Name must be at least 3 characters",
                        },
                        {
                          pattern: /^[A-Za-z\s]+$/,
                          message: "Please enter only alphabets",
                        },
                      ]}
                    >
                      <div>
                        <h4>Business Name</h4>
                        <Input
                          value={businessName}
                          onChange={(e) => setBusinessName(e.target.value)}
                          placeholder="Business Name"
                          style={{ height: 55 }}
                          maxLength={40}
                        />
                      </div>
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={12}>
                    <Form.Item
                      name="businessPhone"
                      rules={[
                        {
                          required: true,
                          message: "Please input your phone number!",
                        },
                      ]}
                    >
                      <div>
                        <h4>Business Phone</h4>
                        <div className="phn_cs">
                          <PhoneInput
                            value={businessPhoneCode + businessPhone}
                            enableSearch={true}
                            placeholder="Phone number"
                            containerClass={"cntn_telCls"}
                            inputClass={"cstm_cls"}
                            buttonClass={"custm_btn"}
                            onChange={(phone, country) =>
                              handleChangeBusinessPhone(phone, country)
                            }
                          />
                        </div>
                        {error && !businessPhone ? (
                          <h3 className="error_msg">
                            Please input your phone number!
                          </h3>
                        ) : null}
                        {error &&
                        businessPhone?.length &&
                        businessPhone?.length < 6 ? (
                          <h3 className="error_msg">
                            Please input valid phone number!
                          </h3>
                        ) : null}
                      </div>
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={16}>
                  <Col xs={24} md={12}>
                    <Form.Item
                      name="businessLandline"
                      rules={[
                        {
                          min: 3,
                          message: "Name must be at least 3 characters",
                        },
                      ]}
                    >
                      <div>
                        <h4>Business Landline</h4>
                        <Input
                          value={businessLandline}
                          onChange={(e) => setBusinessLandline(e.target.value)}
                          placeholder="Business Landline"
                          style={{ height: 55 }}
                          maxLength={40}
                        />
                      </div>
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={12}>
                    <Form.Item
                      name="businessEmail"
                      rules={[
                        {
                          required: true,
                          message: "Please input email!",
                        },
                        {
                          type: "email",
                          message: "The input is not valid email!",
                        },
                      ]}
                    >
                      <div>
                        <h4>Business Email</h4>
                        <Input
                          value={businessEmail}
                          onChange={(e) => setBusinessEmail(e.target.value)}
                          style={{ height: 55 }}
                          placeholder="Business Email"
                          maxLength={40}
                        />
                      </div>
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col xs={24} md={12}>
                    <Form.Item
                      name="businessAddress"
                      rules={[
                        {
                          required: true,
                          message: "Please input your Business address",
                          whitespace: true,
                        },
                        {
                          min: 3,
                          message: "Name must be at least 3 characters",
                        },
                      ]}
                    >
                      <div>
                        <h4>Business Address</h4>
                        <Input
                          value={businessAddress}
                          onChange={(e) => setBusinessAddress(e.target.value)}
                          placeholder="Business Address"
                          style={{ height: 55 }}
                          maxLength={40}
                        />
                      </div>
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={12}>
                    <Form.Item name="businessLicense" rules={[]}>
                      <div>
                        <h4>License Number</h4>
                        <Input
                          value={businessLicense}
                          onChange={(e) => setBusinessLicense(e.target.value)}
                          style={{ height: 55 }}
                          placeholder="License Number"
                          maxLength={40}
                        />
                      </div>
                    </Form.Item>
                  </Col>
                </Row>
              </>
            ) : (
              ""
            )}

            {isSeller ? (
              <Form.Item wrapperCol={{}}>
                <div style={{ marginTop: 20 }}>
                  <Button
                    style={{
                      background: "#ECAC35",
                      width: 180,
                      height: 50,
                      margin: "auto",
                      color: "black",
                      fontWeight: 500,
                    }}
                    type="primary"
                    onClick={handleUpdateSeller}
                  >
                    Update Seller Form
                  </Button>
                </div>
              </Form.Item>
            ) : (
              ""
            )}

            <Form.Item wrapperCol={{}}>
              <div style={{ marginTop: 20 }}>
                <Button
                  style={{
                    background: "#ECAC35",
                    width: 150,
                    height: 50,
                    margin: "auto",
                    color: "black",
                    fontWeight: 500,
                  }}
                  type="primary"
                  htmlType="submit"
                  loading={editUserData.isLoading}
                >
                  Submit
                </Button>
              </div>
            </Form.Item>
          </Form>
        </div>
      </Card>
    </div>
  );
};

export default UsersForm;
