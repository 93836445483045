import {
  Card,
  Col,
  Input,
  Row,
  Button,
  Space,
  Modal,
  TabsProps,
  Tabs,
} from "antd";
import { ContentHeader } from "../../components";
import {
  DeleteOutlined,
  DownloadOutlined,
  EditFilled,
  EyeOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import Table, { ColumnsType } from "antd/es/table";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import {
  useLazyGetNewsLetterCsvQuery,
  useLazyGetNewsLetterQuery,
} from "../../services/memberships";
import { errorToast, successToast } from "../../helpers";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import { CommonBody } from "../../types/General";
import useAuth from "../../hooks/useAuth";

const NewsLetter = () => {
  const navigate = useNavigate();
  const [AllProductsData, setAllProductsData] = useState([]);
  const [getUserCsvMethod] = useLazyGetNewsLetterCsvQuery();
  const [newsLetter, newsLetterQuery] = useLazyGetNewsLetterQuery();
  // const [getFeaturedProductQuery] = useLazyGetFeatureProductQuery();
  const [debouncedQuery, setDebouncedQuery] = useState("");
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [activeTab, setActiveTab] = useState("1");
  const [totalCount, setTotalCount] = useState<number>(0);
  const [query, setQuery] = useState("");
  const [hidePermission, setHidePermission] = useState<any>({
    isAdd: true,
    isDelete: true,
  });
  const [loading, setLoading] = useState(false);

  const userData = useAuth();
  const onChange = (key: string) => {
    setActiveTab(key);
  };
  interface DataType {
    email: string;
    user: any;
    subCategoryName: any;
    categoryName: any;
    key: string;
    price: string;
    subCategory: string;
    name: any;
    category: string;
  }
  const { confirm } = Modal;

  const checkPermission = () => {
    const permissions = userData?.permissions;
    if (permissions?.length) {
      let idx = -1;
      idx = permissions?.findIndex((ele: any) => ele?.label === "Manage Users");
      if (idx != -1) {
        if (!permissions[idx]?.isAdd) {
          let data = {
            isAdd: false,
            isDelete: hidePermission?.isDelete,
          };
          setHidePermission(data);
        }
        if (!permissions[idx]?.isDelete) {
          let data = {
            isAdd: hidePermission?.isAdd,
            isDelete: false,
          };
          setHidePermission(data);
        }
      }
    }
  };

  const handleExportCsv = async () => {
    try {
      const res = await getUserCsvMethod({}).unwrap();
      if (res?.statusCode === 200) {
        if (res?.data) {
          window.open(res?.data);
        }
      }
    } catch (error: any) {
      errorToast(error?.data?.message);
    }
  };

  // const data: DataType[] = [
  //   {
  //     key: "1",
  //     name: "John Brown",

  //     category: "New York No. 1 Lake Park",
  //     price: "john@gmail.com",
  //     subCategory: "sdff",
  //   },
  //   {
  //     key: "2",
  //     name: "Jim Green",

  //     category: "London No. 1 Lake Park",
  //     price: "jim@gmail.com",
  //     subCategory: "sfsd",
  //   },
  //   {
  //     key: "3",
  //     name: "Joe Black",

  //     category: "London No. 1 Lake Park",
  //     price: "joe@gmail.com",
  //     subCategory: "dfsf",
  //   },
  // ];

  useEffect(() => {
    if (userData && userData?.permissions?.length) {
      checkPermission();
    }
  }, [userData]);

  const columns: ColumnsType<DataType> = [
    {
      title: "Image",
      dataIndex: "image",
      key: "image",
      align: "center",
      render: (_, record) => (
        <div>
          <img
            alt="img"
            style={{ width: 40, height: 40, borderRadius: 50 }}
            src={
              record?.user?.image
                ? record?.user?.image
                : "/static/images/dummy.png"
            }
          />
        </div>
      ),
    },
    {
      title: "User name",
      dataIndex: "name",
      key: "name",
      align: "center",
      render: (_, record) => (
        <div>
          <span>{`${record?.user?.fullName || "-"}`}</span>
        </div>
      ),
    },
    {
      title: "Email",
      key: "price",
      dataIndex: "price",
      align: "center",
      render: (_, record) => (
        <div>
          <span>{`${record?.email || "-"}`}</span>
        </div>
      ),
    },
    {
      title: "Phone",
      dataIndex: "category",
      key: "category",
      align: "center",
      render: (_, record) => (
        <div>
          <span>{`${record?.user?.countryCode} ${
            record?.user?.phone || "-"
          }`}</span>
        </div>
      ),
    },
  ];

  const getProductDetails = async (page: number) => {
    try {
      const res = await newsLetter({
        page: page,
        size: 10,
        search: query,
      }).unwrap();

      if (res?.statusCode === 200) {
        setAllProductsData(res?.data?.data);
        setTotalCount(res?.data?.count);
      }
    } catch (error: any) {
      console.log(error);
    }
  };

  useEffect(() => {
    getProductDetails(1);
  }, [query]);

  useEffect(() => {
    const delay = 1000; // Debounce delay in milliseconds
    const timerId = setTimeout(() => {
      setQuery(debouncedQuery);
    }, delay);

    return () => {
      clearTimeout(timerId); // Clear the timeout on cleanup
    };
  }, [debouncedQuery]);

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
    getProductDetails(page);
  };
  const pagination = {
    pageSize: 10,
    current: currentPage,
    total: totalCount,
    onChange: handlePageChange,
  };

  return (
    <div style={{ padding: 50 }}>
      <ContentHeader
        heading="Newsletter"
        // buttonNavigateTo="recommended-products/add"
        // buttonText={hidePermission?.isAdd ? "Add Products" : ""}
      />
      <Card>
        <div className="srch">
          <Row
            style={{
              justifyContent: "space-between",
              paddingTop: 10,
              paddingBottom: 10,
            }}
            gutter={16}
          >
            <Col xs={24} md={7}>
              <Input
                placeholder="Search"
                prefix={<SearchOutlined style={{ fontSize: "24px" }} />}
                style={{ height: 50, borderRadius: 10 }}
                value={debouncedQuery}
                onChange={(e) => setDebouncedQuery(e.target.value)}
                maxLength={20}
                allowClear
              />
            </Col>
            <Col
              style={{
                margin: 1,
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Button
                icon={<DownloadOutlined />}
                style={{
                  background: "#ECAC35",
                  height: 35,
                  color: "black",
                }}
                type="primary"
                onClick={handleExportCsv}
              >
                Export CSV
              </Button>
            </Col>
          </Row>

          <Table
            columns={columns}
            dataSource={AllProductsData}
            rowKey="_id"
            loading={newsLetterQuery?.isLoading}
            pagination={pagination}
          />
        </div>
      </Card>
    </div>
  );
};

export default NewsLetter;
