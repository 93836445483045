import {
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  Input,
  Row,
  Select,
  TimePicker,
  Upload,
} from "antd";
import { useNavigate, useParams } from "react-router-dom";
import "react-phone-input-2/lib/style.css";
import TextArea from "antd/es/input/TextArea";
import { UploadMedia } from "../../../utils/mediaUpload";
import { errorToast, successToast } from "../../../helpers";
import { useEffect, useState } from "react";
import { RcFile } from "antd/es/upload";
import { UploadOutlined } from "@ant-design/icons";
import { ContentHeader } from "../../../components";
import {
  useAddBannerMutation,
  useLazyGetBannerByIdQuery,
  useUpdateBannerMutation,
} from "../../../services/banner";
import { generateEncryptedKeyBody } from "../../../utils/crypto";
import { CommonBody, SubCategory, category } from "../../../types/General";
import { useLazyGetCategoryQuery } from "../../../services/category";
import { useLazyGetSubCategoryListQuery } from "../../../services/product";

const BannerForm = () => {
  const { activeTab, id } = useParams();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const { Option } = Select;

  const notificationTypes = [
    { name: "Main", id: "1" },
    { name: "Advertisement", id: "2" },
    { name: "New Arrival", id: "3" },
    { name: "Other", id: "4" },
  ];

  const [image, setImage] = useState("");
  const [webImage, setWebImage] = useState("");
  const [selectedType, setSelectedType] = useState("");
  console.log(selectedType, "980898");
  const [feature, setFeature] = useState("");
  const [title, setTitle] = useState("");
  const [featureAr, setFeatureAr] = useState("");
  const [titleAr, setTitleAr] = useState("");
  const [allCategoryData, setAllCategoryData] = useState<category[]>();
  const [loading, setLoading] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState<string | undefined>(
    undefined
  );
  const [link, setLink] = useState("");
  const [allSubCategoryData, setAllSubCategoryData] = useState<SubCategory[]>();
  const [selectedSubCategory, setSelectedSubCategory] = useState("");

  const [addBanner] = useAddBannerMutation();
  const [updateBanner] = useUpdateBannerMutation();
  const [getBannerById] = useLazyGetBannerByIdQuery();
  const [getCategory] = useLazyGetCategoryQuery();
  const [getSubCategoryMethod] = useLazyGetSubCategoryListQuery();

  console.log(selectedCategory);

  const getBannerDetailById = async () => {
    try {
      const res = await getBannerById({
        bannerId: id as string,
        // activeTab: activeTab === "1" ? 1 : activeTab === "2" ? 2 : 3,
      }).unwrap();

      if (res?.statusCode === 200) {
        form.setFieldsValue({
          image: res?.data?.appImage,
          webImage: res?.data?.webImage,
          title: res?.data?.title || "",
          feature: res?.data?.feature || "",
          title_ar: res?.data?.title_ar || "",
          feature_ar: res?.data?.feature_ar || "",
          categoryOfTheProduct: res?.data?.categoryId || "",
          subCategoryField: res?.data?.subCategory?._id || "",
          type: res?.data?.type || "",
        });

        setImage(res?.data?.appImage);
        setWebImage(res?.data?.webImage);
        setFeature(res?.data?.feature);
        setTitle(res?.data?.title);
        setFeatureAr(res?.data?.feature_ar);
        setTitleAr(res?.data?.title_ar);
        setSelectedCategory(res?.data?.categoryId);
        setSelectedType(res?.data?.type.toString());
        setLink(res?.data?.link);
      }
    } catch (error: any) {
      console.log(error);
    }
  };

  const onFinish = async (values: any) => {
    const data = {
      title: values.title,
      title_ar: values.title_ar,
      appImage: image,
      webImage: webImage,
      type: selectedType,
      bannerType: activeTab === "1" ? 1 : activeTab === "2" ? 2 : 3,
      ...(activeTab === "1"
        ? { feature: values.feature, feature_ar: values.feature_ar }
        : {}),
      link: link,
    };

    console.log(data, "BODY");

    try {
      let encryptedBody = generateEncryptedKeyBody(data) as CommonBody;
      let res;
      id === "add"
        ? (res = await addBanner(encryptedBody).unwrap())
        : (res = await updateBanner({
            encryptedBody,
            bannerId: id ? id : "",
            activeTab: activeTab === "1" ? 1 : activeTab === "2" ? 2 : 3,
          }).unwrap());

      if (res?.statusCode === 200) {
        navigate("/manage-banners", { replace: true });
        successToast(
          id === "add"
            ? "Banner added successfully"
            : "Banner updated successfully"
        );
      } else {
        // errorToast(res?.message);
      }
    } catch (error: any) {
      errorToast(error?.data?.message || error?.data?.error);
    }
  };

  const handleImageUpload = async (file: RcFile) => {
    // const getImageDimensions = (
    //   file: File
    // ): Promise<{ width: number; height: number }> => {
    //   return new Promise((resolve, reject) => {
    //     const reader = new FileReader();
    //     reader.onload = (event) => {
    //       if (event.target) {
    //         const img = new Image();
    //         img.onload = () => {
    //           resolve({ width: img.width, height: img.height });
    //         };
    //         img.onerror = reject;
    //         img.src = event.target.result as string;
    //       }
    //     };
    //     reader.onerror = reject;
    //     reader.readAsDataURL(file);
    //   });
    // };
    // const { width, height } = await getImageDimensions(file);
    if (file?.type.includes("image")) {
      try {
        const res = await UploadMedia(file);

        if (res?.statusCode === 200) {
          setImage(res?.data);
        }
      } catch (error: any) {
        console.log(error);
      }
    } else {
      errorToast("Only images are allowed");
    }

    return false; // Prevent Ant Design from automatically uploading the file
  };

  const handleImageUpload2 = async (file: RcFile) => {
    const getImageDimensions = (
      file: File
    ): Promise<{ width: number; height: number }> => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (event) => {
          if (event.target) {
            const img = new Image();
            img.onload = () => {
              resolve({ width: img.width, height: img.height });
            };
            img.onerror = reject;
            img.src = event.target.result as string;
          }
        };
        reader.onerror = reject;
        reader.readAsDataURL(file);
      });
    };

    try {
      const { width, height } = await getImageDimensions(file);

      if (
        (activeTab === "2" || activeTab === "3") &&
        (width < 1170 || height < 240)
      ) {
        errorToast("Banner image minimum dimensions must be 1170×240");
      } else if (file?.type.includes("image")) {
        const res = await UploadMedia(file);
        if (res?.statusCode === 200) {
          setWebImage(res?.data);
        }
      } else {
        errorToast("Only images are allowed");
      }
    } catch (error: any) {
      console.error(error);
      errorToast("Please add valid format of image");
    }

    return false; // Prevent Ant Design from automatically uploading the file
  };

  const getCategoryDetails = async () => {
    try {
      setLoading(true);
      const res = await getCategory({
        page: 1,
        size: 50,
        search: "",
      }).unwrap();
      setLoading(false);

      if (res?.statusCode === 200) {
        setAllCategoryData(res?.data?.category);
      }
    } catch (error: any) {
      setLoading(false);
      console.log(error);
    }
  };

  const getSubCategoryDetails = async () => {
    try {
      setLoading(true);
      const res = await getSubCategoryMethod({
        page: 1,
        size: 50,
        search: "",
        categoryId: selectedCategory,
      }).unwrap();
      setLoading(false);

      if (res?.statusCode === 200) {
        setAllSubCategoryData(res?.data?.subCategory);
      }
    } catch (error: any) {
      setLoading(false);
      console.log(error);
    }
  };

  const handleChangeForSelectedCategory = (value: any) => {
    form.setFieldsValue({
      type: value,
    });
    setSelectedType(value);

    form.validateFields(["type"]);
  };

  useEffect(() => {
    if (activeTab === "1") {
      getCategoryDetails();
    }
  }, []);

  useEffect(() => {
    if (selectedCategory) {
      getSubCategoryDetails();
    }
  }, [selectedCategory]);

  useEffect(() => {
    if (id !== "add") {
      getBannerDetailById();
    }
  }, []);

  return (
    <div style={{ padding: 50 }}>
      <ContentHeader
        buttonText="Back"
        buttonNavigateTo="manage-banners"
        heading={`${id === "add" ? "Add" : "Edit"} Banner`}
      />
      <Card>
        <div>
          <Form
            name="basic"
            layout="vertical"
            form={form}
            // labelCol={{ span: 8 }}
            // wrapperCol={{ span: 16 }}
            style={{
              maxWidth: "80%",
              marginTop: 10,
            }}
            initialValues={{ remember: true }}
            onFinish={onFinish}
            //   onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <Row gutter={16}>
              <Col xs={24} md={12}>
                <Form.Item
                  name="image"
                  rules={[
                    {
                      required: true,
                      message: "Please upload an image",
                    },
                  ]}
                >
                  <Upload
                    beforeUpload={handleImageUpload}
                    showUploadList={false}
                    className="upload_imgD"
                    accept="image/*"
                  >
                    <div>
                      <h4>Banner image for Application</h4>
                      <Button icon={<UploadOutlined />}>Click to upload</Button>
                    </div>
                    {image ? (
                      <img
                        style={{
                          height: 200,
                          width: 300,
                          // borderRadius: "50%",
                          marginTop: 20,
                        }}
                        src={image}
                        alt="profile"
                      />
                    ) : (
                      <></>
                    )}
                  </Upload>
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item
                  name="webImage"
                  rules={[
                    {
                      required: true,
                      message: "Please upload an image",
                    },
                  ]}
                >
                  <Upload
                    beforeUpload={handleImageUpload2}
                    showUploadList={false}
                    className="upload_imgD"
                    accept="image/*"
                  >
                    <div>
                      <h4>Banner image for website</h4>
                      <Button icon={<UploadOutlined />}>Click to upload</Button>
                    </div>
                    {webImage ? (
                      <img
                        style={{
                          height: 200,
                          width: 300,
                          // borderRadius: "50%",
                          marginTop: 20,
                        }}
                        src={webImage}
                        alt="profile"
                      />
                    ) : (
                      <></>
                    )}
                  </Upload>
                </Form.Item>
              </Col>
            </Row>

            {/* {activeTab === "1" ? (
              <Row gutter={16}>
                <Col xs={24} md={12}>
                  <Form.Item
                    name="categoryOfTheProduct"
                    rules={[
                      {
                        required: true,
                        message: "Please select Category of the product!",
                      },
                    ]}
                  >
                    <div>
                      <h4>Select Category of the Product</h4>
                      <Select
                        allowClear
                        maxLength={20}
                        placeholder="Select Category"
                        className="select_type"
                        onChange={(val) => handleChangeForSelectedCategory(val)}
                        value={selectedCategory}
                        style={{ height: 60, width: "100%" }}
                        showSearch
                        optionFilterProp="label"
                        filterOption={(input, option) =>
                          (
                            option?.label?.toString()?.toLowerCase() || ""
                          ).indexOf(input.toLowerCase()) !== -1
                        }
                      >
                        {allCategoryData?.map((item, index) => (
                          <Option
                            key={index}
                            value={item?._id}
                            label={item?.name}
                          >
                            {item?.name}
                          </Option>
                        ))}
                      </Select>
                    </div>
                  </Form.Item>
                </Col>
              </Row>
            ) : null} */}

            <Row gutter={16}>
              <Col xs={24} md={12}>
                <Form.Item
                  name="title"
                  rules={[
                    {
                      required: true,
                      message: "Please input title of banner!",
                      whitespace: true,
                    },
                    {
                      min: 3,
                      message: "Title must be at least 3 characters",
                    },
                  ]}
                >
                  <div>
                    <h4>Title ( English )</h4>
                    <Input
                      placeholder="Title ( English )"
                      style={{ height: 55 }}
                      value={title}
                      onChange={(e) => {
                        setTitle(e.target.value);
                      }}
                    />
                  </div>
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item
                  name="title_ar"
                  rules={[
                    {
                      required: true,
                      message: "Please input title of banner!",
                      whitespace: true,
                    },
                    {
                      min: 3,
                      message: "Title must be at least 3 characters",
                    },
                  ]}
                >
                  <div>
                    <h4>Title ( Arabic )</h4>
                    <Input
                      placeholder="Title ( Arabic )"
                      style={{ height: 55 }}
                      value={titleAr}
                      onChange={(e) => {
                        setTitleAr(e.target.value);
                      }}
                    />
                  </div>
                </Form.Item>
              </Col>

              {activeTab === "1" ? (
                <>
                  <Col xs={24} md={12}>
                    <Form.Item
                      name="feature"
                      rules={[
                        {
                          required: true,
                          message: "Please input feature of banner!",
                          whitespace: true,
                        },
                        {
                          min: 5,
                          message: "Feature must be at least 5 characters",
                        },
                      ]}
                    >
                      <div>
                        <h4>Feature ( English )</h4>
                        <Input
                          placeholder="Feature ( English )"
                          style={{ height: 55 }}
                          value={feature}
                          onChange={(e) => {
                            setFeature(e.target.value);
                          }}
                        />
                      </div>
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={12}>
                    <Form.Item
                      name="feature_ar"
                      rules={[
                        {
                          required: true,
                          message: "Please input feature of banner!",
                          whitespace: true,
                        },
                        {
                          min: 5,
                          message: "Feature must be at least 5 characters",
                        },
                      ]}
                    >
                      <div>
                        <h4>Feature ( Arabic )</h4>
                        <Input
                          placeholder="Feature ( Arabic )"
                          style={{ height: 55 }}
                          value={featureAr}
                          onChange={(e) => {
                            setFeatureAr(e.target.value);
                          }}
                        />
                      </div>
                    </Form.Item>
                  </Col>
                </>
              ) : null}

              <Col xs={24} md={12}>
                <Form.Item
                  name="type"
                  rules={[
                    {
                      required: true,
                      message: "Please select a Type",
                    },
                  ]}
                >
                  <h4> Banner Type</h4>
                  <Select
                    allowClear
                    maxLength={20}
                    className="selector_type"
                    onChange={(val) => handleChangeForSelectedCategory(val)}
                    value={
                      selectedType === "1"
                        ? "Main"
                        : selectedType === "2"
                        ? "Advertisement"
                        : selectedType === "3"
                        ? "New Arrival"
                        : selectedType === "4"
                        ? "Other"
                        : ""
                    }
                    style={{ height: 75, width: "100%" }}
                    placeholder="Target"
                  >
                    {notificationTypes?.map((item, index) => (
                      <Option key={index} value={item?.id} label={item?.name}>
                        {item?.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item
                  name="link"
                  rules={[
                    {
                      whitespace: true,
                    },
                    {
                      min: 5,
                      message: "Feature must be at least 5 characters",
                    },
                  ]}
                >
                  <div>
                    <h4>Website Link</h4>
                    <Input
                      placeholder="Link"
                      style={{ height: 55 }}
                      value={link}
                      onChange={(e) => {
                        setLink(e.target.value);
                      }}
                    />
                  </div>
                </Form.Item>
              </Col>
            </Row>

            <Form.Item wrapperCol={{ offset: 4, span: 16 }}>
              <div style={{ display: "flex" }}>
                <Button
                  style={{
                    background: "#ECAC35",
                    width: 200,
                    height: 50,
                    margin: "auto",
                    color: "black",
                  }}
                  type="primary"
                  htmlType="submit"
                  loading={loading}
                  // onClick={() => {
                  //   navigate("/manageUsers", { replace: true });
                  // }}
                >
                  Save
                </Button>
              </div>
            </Form.Item>
          </Form>
        </div>
      </Card>
    </div>
  );
};

export default BannerForm;
