import { Button, Card, Col, Row, Spin } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { EventBooking } from "../../../types/General";
import { errorToast } from "../../../helpers";
import moment from "moment";
import { useLazyGetEventBookingByIdQuery } from "../../../services/events";

const EventBookingDetails = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const [bookingDetail, setBookingDetail] = useState<EventBooking>();
  const [loading, setLoading] = useState(false);

  const [getDetailMethod] = useLazyGetEventBookingByIdQuery();

  const getDetailByIdData = async () => {
    try {
      setLoading(true);
      if (id) {
        const res = await getDetailMethod({ eventId: id }).unwrap();
        setLoading(false);
        if (res?.statusCode === 200) {
          setBookingDetail(res?.data);
        }
      }
    } catch (error: any) {
      setLoading(false);
      errorToast(error?.data?.message || error?.data?.error);
    }
  };

  useEffect(() => {
    getDetailByIdData();
  }, []);

  return (
    <div style={{ padding: 50, gap: 20, display: "grid" }}>
      <Spin spinning={loading}>
        <Card>
          <div
            style={{
              alignItems: "center",
              display: "flex",
              justifyContent: "space-between",
              flexWrap: "wrap",
            }}
          >
            <h2>Event Booking Details</h2>
            <Button
              style={{
                background: "#ECAC35",
                width: 100,
                height: 40,
                color: "black",
              }}
              type="primary"
              // htmlType="submit"
              onClick={() => {
                navigate("/booking-order-management", { replace: true });
              }}
            >
              Back
            </Button>
          </div>
        </Card>
        <Card>
          <h2>Customer Details</h2>
          <div>
            <Row gutter={16}>
              <Col xs={20} md={8}>
                <h4>Customer Name</h4>
                <h5>{bookingDetail?.name || ""}</h5>
              </Col>
              <Col xs={20} md={8}>
                <h4>Total Attendees</h4>
                <h5>{bookingDetail?.noOfTickets || 0}</h5>
              </Col>
              <Col xs={20} md={8}>
                <h4>Contact number</h4>
                <h5>
                  {bookingDetail?.countryCode + " " + bookingDetail?.phoneNo}
                </h5>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col xs={20} md={8}>
                <h4>Total purchased tickets</h4>
                <h5>{bookingDetail?.noOfTickets || 0}</h5>
              </Col>
              <Col xs={20} md={8}>
                <h4>Price Per Attendee</h4>
                <h5>${bookingDetail?.ticketPrice || 0}</h5>
              </Col>
              <Col xs={20} md={8}>
                <h4>Tax Charges</h4>
                <h5>${bookingDetail?.tax || 0}</h5>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col xs={20} md={8}>
                <h4>Total Price</h4>
                <h5>${bookingDetail?.amount || 0}</h5>
              </Col>
              <Col xs={20} md={8}>
                <h4>Ticket Category</h4>
                <h5>{bookingDetail?.ticketCategoryName || ""}</h5>
              </Col>
            </Row>
          </div>
        </Card>

        <Card>
          <h2>Event Details</h2>
          <div>
            <Row gutter={16}>
              <Col xs={20} md={8}>
                <h4>Event Name</h4>
                <h5>{bookingDetail?.event?.name || ""}</h5>
              </Col>
              <Col xs={20} md={8}>
                <h4>Event Category</h4>
                <h5>{bookingDetail?.event?.description || ""}</h5>
              </Col>
              <Col xs={20} md={8}>
                <h4>Event Description</h4>
                <h5>{bookingDetail?.event?.description || ""}</h5>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col xs={20} md={8}>
                <h4>Event Start Date Time</h4>
                <h5>
                  {moment(bookingDetail?.event?.time).format(
                    "MMM Do YYYY, h:mm a"
                  ) || ""}
                </h5>
              </Col>
              <Col xs={20} md={8}>
                <h4>Event End Date Time</h4>
                <h5>
                  {moment(bookingDetail?.event?.endTime).format(
                    "MMM Do YYYY, h:mm a"
                  ) || ""}
                </h5>
              </Col>
              {/* <Col xs={20} md={8}>
                <h4>Host Name</h4>
                <h5>{bookingDetail?.event?.hostName || ""}</h5>
              </Col> */}
              <Col xs={20} md={8}>
                <h4>Location</h4>
                <h5>{bookingDetail?.event?.address || ""}</h5>
              </Col>
            </Row>
            <Row gutter={16}></Row>
          </div>
        </Card>

        <Card>
          <h2>Event Rules</h2>
          <div>
            <Row gutter={16}>
              {bookingDetail?.event?.bookingRules ? (
                <Col xs={24} md={12}>
                  <h4>Booking Rules</h4>
                  <h5
                    dangerouslySetInnerHTML={{
                      __html: bookingDetail?.event?.bookingRules || "-",
                    }}
                  ></h5>
                </Col>
              ) : undefined}
              {bookingDetail?.event?.vendorRules ? (
                <Col xs={24} md={12}>
                  <h4>Vendor Rules</h4>
                  <h5
                    dangerouslySetInnerHTML={{
                      __html: bookingDetail?.event?.vendorRules || "-",
                    }}
                  ></h5>
                </Col>
              ) : undefined}
            </Row>
            <Row gutter={16}>
              {" "}
              {bookingDetail?.event?.cancellationRules ? (
                <Col xs={24} md={12}>
                  <h4>Cancellation Rules</h4>
                  <h5
                    dangerouslySetInnerHTML={{
                      __html: bookingDetail?.event?.cancellationRules || "-",
                    }}
                  ></h5>
                </Col>
              ) : undefined}
            </Row>
          </div>
        </Card>
        <Card>
          <h2>Images, and videos for the event</h2>
          <div>
            <Row gutter={16}>
              <Col xs={24} md={12}>
                <div
                  style={{
                    display: "flex",
                    gap: 20,
                  }}
                >
                  {bookingDetail?.event?.images?.length
                    ? bookingDetail?.event?.images?.map((item, index) => {
                        return (
                          // <img
                          //   key={index}
                          //   style={{
                          //     height: 200,
                          //     borderRadius: 10,
                          //     width: 200,
                          //     // margin: "auto",
                          //   }}
                          //   src={item}
                          //   alt=""
                          // />
                          <figure key={index}>
                            {/* @ts-ignore */}
                            {item?.split(".").pop() === "mp4" ? (
                              <video
                                muted
                                src={item}
                                autoPlay
                                style={{
                                  height: 150,
                                  borderRadius: 10,
                                  width: 150,
                                  // margin: "auto",
                                }}
                              />
                            ) : (
                              <img
                                alt=""
                                src={item}
                                style={{
                                  height: 150,
                                  borderRadius: 10,
                                  width: 150,
                                  // margin: "auto",
                                }}
                              />
                            )}
                          </figure>
                        );
                      })
                    : undefined}
                </div>
              </Col>
            </Row>
          </div>
        </Card>
      </Spin>
    </div>
  );
};

export default EventBookingDetails;
