import {
  Card,
  Col,
  Input,
  Row,
  Button,
  Space,
  Modal,
  TabsProps,
  Tabs,
} from "antd";
import { ContentHeader } from "../../components";
import { DeleteOutlined, EditFilled, SearchOutlined } from "@ant-design/icons";
import Table, { ColumnsType } from "antd/es/table";

import { useNavigate } from "react-router-dom";
import ProductBanner from "./ProductBanner";
import { useEffect, useState } from "react";
import { Banners, Reviews } from "../../types/General";

import { errorToast } from "../../helpers";
import EventBanner from "./EventBanner";
import DonationBanner from "./DonationBanner";
import { useLazyGetBannersQuery } from "../../services/banner";

const ManageBanners = () => {
  const [getBanners] = useLazyGetBannersQuery();
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState("1");
  const [loading, setLoading] = useState(false);
  const [productBanners, setProductBanners] = useState<Banners[]>([]);
  const [eventBanners, setEventBanners] = useState<Banners[]>([]);
  const [donationBanners, setDonationBanners] = useState<Banners[]>([]);
  const [totalCount, setTotalCount] = useState<number>();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [query, setQuery] = useState("");
  console.log(productBanners, "allBanners");

  interface DataType {
    key: string;
    price: string;
    feature: string;
    title: string;
    name: any;
  }
  const { confirm } = Modal;

  const showDeleteConfirm = () => {
    confirm({
      title: "Hold on",
      content: "Are you sure delete this Banner?",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        console.log("OK");
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };
  const getAllBannersData = async (page: number) => {
    try {
      setLoading(true);
      const res = await getBanners({
        page,
        search: query,
      }).unwrap();
      setLoading(false);
      if (res?.statusCode === 200) {
        console.log(res, "rreee");
        setProductBanners(res?.data?.banner || []);
      }
    } catch (error: any) {
      setLoading(false);
      errorToast(error?.data?.message || error?.data?.error);
    }
  };

  const items: TabsProps["items"] = [
    {
      key: "1",
      label: `Product Banner`,
      children: (
        <ProductBanner
          hidePermission={{
            isAdd: true,
            isDelete: true,
          }}
          allBanners={productBanners}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          loading={loading}
          setLoading={setLoading}
          query={query}
          setQuery={setQuery}
          totalCount={totalCount}
          getAllBannersData={getAllBannersData}
          activeTab={activeTab}
        />
      ),
    },
    {
      key: "2",
      label: `Event Banner`,
      children: (
        <EventBanner
          hidePermission={{
            isAdd: true,
            isDelete: true,
          }}
          allBanners={eventBanners}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          loading={loading}
          setLoading={setLoading}
          query={query}
          setQuery={setQuery}
          totalCount={totalCount}
          getAllBannersData={getAllBannersData}
          activeTab={activeTab}
        />
      ),
    },
    {
      key: "3",
      label: `Donation Banner`,
      children: (
        <DonationBanner
          hidePermission={{
            isAdd: false,
            isDelete: false,
          }}
          allBanners={donationBanners}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          loading={loading}
          setLoading={setLoading}
          query={query}
          setQuery={setQuery}
          totalCount={totalCount}
          getAllBannersData={getAllBannersData}
          activeTab={activeTab}
        />
      ),
    },
  ];

  useEffect(() => {
    getAllBannersData(1);
  }, [activeTab, query]);

  const columns: ColumnsType<DataType> = [
    {
      title: "Banner Image",
      dataIndex: "name",
      key: "name",
      align: "center",
    },
    {
      title: "Title",
      key: "title",
      dataIndex: "title",
      align: "center",
      width: "10%",
    },

    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      align: "center",
    },
    {
      title: "Feature",
      dataIndex: "feature",
      key: "feature",
      align: "center",
    },

    {
      title: "Action",
      key: "action",
      align: "center",
      render: (_, record) => (
        <Space size="middle">
          <Button
            onClick={(e) => {
              e.preventDefault();
              navigate(`/manage-banners/edit`);
            }}
          >
            <EditFilled style={{ color: "#C69320" }} />
          </Button>
          <Button
            onClick={(e) => {
              e.preventDefault();
              showDeleteConfirm();
            }}
          >
            <DeleteOutlined style={{ color: "#d4380d" }} />
          </Button>
        </Space>
      ),
    },
  ];

  const data: DataType[] = [
    {
      key: "1",
      name: (
        <img src="/static/images/selfie.png" alt="" height={70} width={90} />
      ),
      price: "$100",
      feature: "iBike 7speed",

      title: "Lorem ....",
    },
    {
      key: "2",
      name: (
        <img src="/static/images/selfie.png" alt="" height={70} width={90} />
      ),
      price: "$100",
      feature: "iBike 7speed",

      title: "Lorem ....",
    },
    {
      key: "3",
      name: (
        <img src="/static/images/selfie.png" alt="" height={70} width={90} />
      ),
      price: "$100",
      feature: "iBike 7speed",

      title: "Lorem ....",
    },
  ];

  const onChange = (key: string) => {
    setActiveTab(key);
  };
  return (
    // <div style={{ padding: 50 }}>
    //   <ContentHeader
    //     heading="Manage Banners"
    //     buttonNavigateTo="manage-banners/add"
    //     buttonText="Add Banner"
    //   />
    //   <Card>
    //     <div className="srch">
    //       <Row style={{ paddingTop: 10, paddingBottom: 10 }} gutter={16}>
    //         <Col xs={24} md={7}>
    //           <Input
    //             placeholder="Search"
    //             prefix={<SearchOutlined style={{ fontSize: "24px" }} />}
    //             style={{ height: 55, borderRadius: 10 }}
    //           />
    //         </Col>
    //       </Row>

    //       <Table columns={columns} dataSource={data} />
    //     </div>
    //   </Card>
    // </div>
    <div style={{ padding: 50 }}>
      <ContentHeader
        heading="Manage Banners"
        // buttonNavigateTo={`manage-banners/add/${activeTab}`}
        // buttonText="Add Banner"
      />
      <Card style={{ overflowX: "scroll" }}>
        {/* <Tabs
          activeKey={activeTab}
          defaultActiveKey="1"
          items={items}
          onChange={onChange}
        /> */}
        <ProductBanner
          hidePermission={{
            isAdd: true,
            isDelete: true,
          }}
          allBanners={productBanners}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          loading={loading}
          setLoading={setLoading}
          query={query}
          setQuery={setQuery}
          totalCount={totalCount}
          getAllBannersData={getAllBannersData}
          activeTab={activeTab}
        />
      </Card>
    </div>
  );
};

export default ManageBanners;
