import { Modal, Button } from "antd";
import { useState } from "react";

import { usePostReplyToPostMutation } from "../../services/support";
import { errorToast, successToast } from "../../helpers";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import { CommonBody } from "../../types/General";
import TextArea from "antd/es/input/TextArea";

type ModalTypes = {
  id: string;
  getAllReviewsData: (page: number) => void;
};

const ReplyToPostModal = ({ id, getAllReviewsData }: ModalTypes) => {
  const [visible, setVisible] = useState(false);
  const [reply, setReply] = useState<string>();
  const [replyToPost] = usePostReplyToPostMutation();

  const handleReply = async () => {
    if (!reply?.length) {
      errorToast("Please input reply message");
    } else {
      let data = {
        reply: reply,
      };
      try {
        let encryptedBody = generateEncryptedKeyBody(data) as CommonBody;

        const res = await replyToPost({ reviewId: id, encryptedBody }).unwrap();

        if (res?.statusCode === 200) {
          setVisible(false);
          setReply("");
          getAllReviewsData(1);
          successToast("Reply Send successfully");
        }
      } catch (error: any) {
        console.log(error);
      }
    }
  };

  const handleCancel = () => {
    setVisible(false);
  };

  return (
    <div>
      <Button onClick={() => setVisible(true)}>
        <img
          style={{ height: 20, width: 20 }}
          src="/static/images/reply.png"
          alt="action"
        />
      </Button>
      <Modal
        style={{}}
        title=" Query reply "
        visible={visible}
        onCancel={handleCancel}
        footer={[
          <Button
            style={{
              background: "#C69320",
              height: 35,
              margin: "auto",
              color: "black",
            }}
            onClick={() => handleReply()}
          >
            Send
          </Button>,
        ]}
      >
        <TextArea
          rows={4}
          placeholder="Enter message"
          onChange={(e) => setReply(e.target.value)}
        />
      </Modal>
    </div>
  );
};

export default ReplyToPostModal;
