import { END_POINTS } from "../helpers";
import { CommonBody, SubCategory } from "../types/General";
import emptySplitApi from "../utils/rtk";

type CommonResponse = {
  statusCode: number;
  data?: any;
  message: string;
};

type PutUpdateCategoryParams = {
  productId: string | undefined;
  body: CommonBody;
};

type PutBlockUserParams = {
  userId: string;
  encryptedBody: CommonBody;
};

export const categoryApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getProduct: builder.query<
      CommonResponse,
      {
        page: number;
        size: number;
        search: string;
        type: string;
        userType: any;
      }
    >({
      query: ({ page, size, search, type, userType }) => ({
        url:
          END_POINTS.product +
          "?page=" +
          page +
          "&size=" +
          size +
          "&search=" +
          search +
          "&type=" +
          type +
          "&userType=" +
          userType,
        method: "GET",
      }),
    }),

    getProductById: builder.query<CommonResponse, string | undefined>({
      query: (productId) => ({
        url: `${END_POINTS.product}${productId}`,
        method: "GET",
      }),
    }),

    deleteProduct: builder.mutation<CommonResponse, { productId: string }>({
      query: ({ productId }) => ({
        url: `${END_POINTS.product}${productId}`,
        method: "DELETE",
      }),
    }),

    putUpdateProduct: builder.mutation<CommonResponse, PutUpdateCategoryParams>(
      {
        query: ({ body, productId }) => ({
          url: `${END_POINTS.product}${productId}`,
          method: "PUT",
          body,
        }),
      }
    ),
    getProductByCategory: builder.query<CommonResponse, string | undefined>({
      query: (categoryId) => ({
        url: `${END_POINTS.getProductByCategory}/${categoryId}`,
        method: "GET",
      }),
    }),
    getFeatureProduct: builder.query<
      CommonResponse,
      { page: number; size: number; search: string }
    >({
      query: ({ page, size, search }) => ({
        url:
          END_POINTS.featureProducts +
          "?page=" +
          page +
          "&size=" +
          size +
          "&search=" +
          search,
        method: "GET",
      }),
    }),
    forFeatureProducts: builder.mutation<CommonResponse, CommonBody>({
      query: (body) => ({
        url: END_POINTS.cms,
        method: "PUT",
        body,
      }),
    }),

    getSubCategoryList: builder.query<
      CommonResponse & { data: { subCategory: SubCategory[]; count: number } },
      {
        categoryId: string | undefined;
        page: number;
        size: number;
        search: string;
        isAll?: boolean | undefined;
      }
    >({
      query: ({ page, size, search, categoryId, isAll }) => ({
        url: `${
          END_POINTS.productSubCategory
        }/${categoryId}?page=${page}&size=${size}&search=${search}${
          isAll ? "&all=true" : ""
        }`,
        method: "GET",
      }),
    }),

    getSubCategoryById: builder.query<
      CommonResponse & { data: SubCategory },
      string | undefined
    >({
      query: (subCatId) => ({
        url: `${END_POINTS.productSubCatById}/${subCatId}`,
        method: "GET",
      }),
    }),

    putChangeProductStatus: builder.mutation<
      CommonResponse,
      PutBlockUserParams
    >({
      query: ({ encryptedBody, userId }) => ({
        url: `${END_POINTS.product}/${userId}`,
        method: "PUT",
        body: encryptedBody,
      }),
    }),

    addSubCategory: builder.mutation<CommonResponse, { body: CommonBody }>({
      query: ({ body }) => ({
        url: `${END_POINTS.productSubCategory}`,
        method: "POST",
        body,
      }),
    }),

    updateSubCategory: builder.mutation<
      CommonResponse,
      { subCatId: string | undefined; body: CommonBody }
    >({
      query: ({ body, subCatId }) => ({
        url: `${END_POINTS.productSubCategory}/${subCatId}`,
        method: "PUT",
        body,
      }),
    }),

    deleteSubCategory: builder.mutation<
      CommonResponse,
      { subCatId: string | undefined }
    >({
      query: ({ subCatId }) => ({
        url: `${END_POINTS.productSubCategory}/${subCatId}`,
        method: "DELETE",
      }),
    }),

    featureProduct: builder.mutation<
      CommonResponse,
      { productId: string | undefined }
    >({
      query: ({ productId }) => ({
        url: `${END_POINTS.featuredProduct}/${productId}`,
        method: "POST",
      }),
    }),
    featureProductUpdate: builder.mutation<
      CommonResponse,
      { productId: string | undefined,body:any }
    >({
      query: ({ productId,body }) => ({
        url: `${END_POINTS.featuredProduct}/${productId}`,
        method: "POST",
        body
      }),
    }),

    getFeaturedProduct: builder.query<
      CommonResponse,
      { page: number; size: number; search: string; type: string }
    >({
      query: ({ page, size, search, type }) => ({
        url:
          END_POINTS.featuredProduct +
          "?page=" +
          page +
          "&size=" +
          size +
          "&search=" +
          search +
          "&type=" +
          type,
        method: "GET",
      }),
    }),

    AddProduct: builder.mutation<CommonResponse, CommonBody>({
      query: (body) => ({
        url: END_POINTS.addProduct,
        method: "POST",
        body,
      }),
    }),
  }),
});

export const {
  useLazyGetProductQuery,
  useLazyGetProductByIdQuery,
  useDeleteProductMutation,
  usePutUpdateProductMutation,
  useLazyGetProductByCategoryQuery,
  // useLazyGetFeatureProductQuery,
  useForFeatureProductsMutation,
  usePutChangeProductStatusMutation,
  useLazyGetSubCategoryByIdQuery,
  useLazyGetSubCategoryListQuery,
  useAddSubCategoryMutation,
  useUpdateSubCategoryMutation,
  useDeleteSubCategoryMutation,
  useFeatureProductMutation,
  useLazyGetFeaturedProductQuery,
  useAddProductMutation,
  useFeatureProductUpdateMutation
} = categoryApi;
