import AuthWrapper from "../../../layout/authWrapper";
import "../login/login.css";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { Button, Card, Form, FormInstance } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import OTPInput from "otp-input-react";
import { useEffect, useRef, useState } from "react";
import {
  usePostForgotPasswordMutation,
  usePostVerifyOtpMutation,
} from "../../../services/auth";
import {
  STORAGE_KEYS,
  errorToast,
  setToStorage,
  successToast,
} from "../../../helpers";
import { temporaryToken } from "../../../reducer/authSlice";
import { useAppDispatch } from "../../../hooks/store";
import { generateEncryptedKeyBody } from "../../../utils/crypto";
import { CommonBody } from "../../../types/General";

const VerifyOtp = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { state } = location;

  const formRef = useRef<FormInstance>(null);

  const [OTP, setOTP] = useState("");
  const [countDown, setCountDown] = useState(59);

  const [verifyOptMethod, verifyOptMutation] = usePostVerifyOtpMutation();
  const [forgotPasswordMethod, forgotPasswordMutation] =
    usePostForgotPasswordMutation();

  const onFinish = async (values: any) => {
    console.log(values, "valuessss");

    const data = {
      key: state.email,
      code: values.otp,
    };

    const encryptedBody = generateEncryptedKeyBody(data) as CommonBody;

    try {
      const res = await verifyOptMethod(encryptedBody).unwrap();

      if (res?.statusCode === 200) {
        if (state?.type === "signUp") {
          navigate("/profile");
        } else if (state?.type === "login") {
          navigate("/dashboard", { replace: true });
        } else {
          setToStorage(
            STORAGE_KEYS.tempToken,
            JSON.stringify(res?.data?.token)
          );
          dispatch(
            temporaryToken({
              tempToken: res?.data?.token || null,
            })
          );
          navigate("/reset-password");
        }
      }
    } catch (error: any) {
      errorToast(error?.data?.message || error?.data?.error);
    }
  };

  const handleResend = async () => {
    try {
      let body = {
        email: state.email,
      };

      const encryptedBody = generateEncryptedKeyBody(body) as CommonBody;

      const res = await forgotPasswordMethod(encryptedBody).unwrap();
      if (res?.statusCode === 200) {
        successToast("Otp sent successfully");
        setCountDown(59);
      }
    } catch (error: any) {
      errorToast(error?.data?.message || error?.data?.error);
    }
  };

  useEffect(() => {
    if (countDown > 0) {
      setTimeout(() => {
        setCountDown(countDown - 1);
      }, 1000);
    } else {
      setCountDown(0);
    }
  }, [countDown]);

  return (
    <AuthWrapper>
      <Card className="login_card">
        <ArrowLeftOutlined
          style={{ fontSize: 20, cursor: "pointer" }}
          className="bck_arrw"
          onClick={() => navigate(-1)}
        />
        <div style={{ display: "flex", justifyContent: "center" }}>
          <figure className="logo">
            <img src="/static/images/logo.png" alt="" />
          </figure>
        </div>
        <div className="bck_arrw_div">
          <h3>Verify Otp</h3>
        </div>

        <Form
          name="basic"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          style={{
            maxWidth: 600,
            marginTop: 50,
            justifyContent: "center",
          }}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          autoComplete="off"
          ref={formRef}
        >
          <Form.Item
            style={{ padding: 10 }}
            className="otp_inpy"
            rules={[
              {
                required: true,
                message: "Please input your OTP!",
              },
              {
                min: 4,
                message: "",
              },
            ]}
            name="otp"
          >
            <OTPInput
              value={OTP}
              onChange={setOTP}
              style={{
                width: "460px",
                height: "50px",
                justifyContent: "center",
              }}
              autoFocus={false}
              OTPLength={4}
              otpType="number"
              disabled={false}
              className="otp_inpt"
              inputClassName="inpt_otpcls"
            />
          </Form.Item>

          <Form.Item wrapperCol={{ offset: 4, span: 16 }}>
            <div style={{ display: "flex" }}>
              <Button
                style={{
                  background: "#ECAC35",
                  width: 200,
                  height: 50,
                  margin: "auto",
                  color: "black",
                  fontWeight: 600,
                }}
                type="primary"
                htmlType="submit"
                loading={verifyOptMutation?.isLoading}
              >
                Verify Otp
              </Button>
            </div>
          </Form.Item>
        </Form>
        {countDown === 0 ? (
          <div style={{ display: "flex", alignItems: "center" }}>
            <Button
              style={{
                background: "#ffffff",
                width: 160,
                height: 40,
                margin: "auto",
              }}
              type="text"
              loading={forgotPasswordMutation?.isLoading}
              onClick={handleResend}
            >
              Resend
            </Button>
          </div>
        ) : (
          <h5 style={{ textAlign: "center" }}>
            The verify code will expire in
            <span className="count"> 00 : {countDown}</span>
          </h5>
        )}
      </Card>
    </AuthWrapper>
  );
};

export default VerifyOtp;
