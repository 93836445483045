import AuthWrapper from "../../../layout/authWrapper";
import "../login/login.css";
import { Button, Card, Form, Input } from "antd";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { toast } from "react-toastify";
import { usePostChangePasswordMutation } from "../../../services/auth";
import { CommonBody } from "../../../types/General";
import { generateEncryptedKeyBody } from "../../../utils/crypto";
import { ArrowLeftOutlined } from "@ant-design/icons";

const ChangePassword = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [password, setPassword] = useState("");

  const [changePassword, changePasswordData] = usePostChangePasswordMutation();

  const onFinish = async (values: any) => {
    const data = {
      oldPassword: values.oldPassword,
      password: values.password,
    };
    try {
      let encryptedBody = generateEncryptedKeyBody(data) as CommonBody;
      const res = await changePassword(encryptedBody).unwrap();
      if (res?.statusCode === 200) {
        toast.dismiss();
        toast.success("Password changed successfully", { autoClose: 2000 });
        navigate("/dashboard", { replace: true });
      }
    } catch (error: any) {
      toast.dismiss();
      toast.error(error?.data?.message || error?.data?.error);
    }
  };

  const onFinishFailed = ({ values, errorFields, outOfDate }: any) => {
    console.log(
      values,
      errorFields,
      outOfDate,
      "values, errorFields, outOfDate"
    );
  };

  const validateConfirmPassword = ({
    getFieldValue,
  }: {
    getFieldValue: any;
  }) => ({
    validator(_: any, value: any) {
      if (!value || getFieldValue("password") === value) {
        return Promise.resolve();
      }
      return Promise.reject(new Error("Passwords do not match."));
    },
  });

  const handleNewPassword = ({ getFieldValue }: { getFieldValue: any }) => ({
    validator(_: any, value: any) {
      if (!value || getFieldValue("oldPassword") !== value) {
        return Promise.resolve();
      }
      return Promise.reject(
        new Error("Old and new password need not to be same.")
      );
    },
  });

  return (
    <AuthWrapper>
      <Card className="login_card">
        <ArrowLeftOutlined
          style={{ fontSize: 20, cursor: "pointer" }}
          className="bck_arrw"
          onClick={() => navigate(-1)}
        />
        <div style={{ display: "flex", justifyContent: "center" }}>
          <figure className="logo">
            <img src="/static/images/logo.png" alt="" />
          </figure>
        </div>
        <div className="bck_arrw_div">
          <h3>Change Password</h3>
        </div>

        <Form
          name="basic"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          style={{
            maxWidth: 460,
            marginTop: 20,
            minWidth: 400,
          }}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          form={form}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item
            name="oldPassword"
            style={{ width: "550px" }}
            rules={[
              { required: true, message: "Please input your old password!" },
            ]}
          >
            <Input.Password
              style={{ width: "460px", height: "50px" }}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Old Password"
            />
          </Form.Item>
          <Form.Item
            name="password"
            style={{ width: "550px", paddingTop: 8 }}
            dependencies={["oldPassword"]}
            rules={[
              { required: true, message: "Please input your password!" },

              handleNewPassword,
              // {
              //   validator: handleNewPassword,
              // },
            ]}
          >
            <Input.Password
              style={{ width: "460px", height: "50px" }}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="New Password"
            />
          </Form.Item>

          <Form.Item
            name="confirmPassword"
            style={{ paddingTop: 8 }}
            dependencies={["password"]}
            rules={[
              {
                required: true,
                message: "Please confirm your password!",
              },
              validateConfirmPassword,
              // {
              //    validator: validateConfirmPassword, dependencies: ['password']
              // },
            ]}
          >
            <Input.Password
              style={{ width: "460px", height: "50px" }}
              placeholder="Confirm New Password"
            />
          </Form.Item>

          <Form.Item wrapperCol={{ offset: 4, span: 16 }}>
            <div style={{ display: "flex", paddingTop: 5 }}>
              <Button
                style={{
                  background: "#ECAC35",
                  width: 180,
                  height: 50,
                  margin: "auto",
                  color: "black",
                  fontWeight: 600,
                }}
                type="primary"
                htmlType="submit"
                loading={changePasswordData.isLoading}
              >
                Change Password
              </Button>
            </div>
          </Form.Item>
        </Form>
      </Card>
    </AuthWrapper>
  );
};

export default ChangePassword;
