import { Button, Card, Col, Row, Spin } from "antd";
import Table, { ColumnsType } from "antd/es/table";
import { useNavigate, useParams } from "react-router-dom";

import moment from "moment";
import { useEffect, useState } from "react";
import { useLazyGetEventByIdQuery } from "../../../../services/events";
import { Events, tickets } from "../../../../types/General";
import { errorToast } from "../../../../helpers";

const columns: ColumnsType<tickets> = [
  {
    title: "Ticket Category",
    dataIndex: "category",
    key: "category",
    // align: "center",
  },
  {
    title: "Ticket Count",
    dataIndex: "count",
    key: "count",
    // align: "center",
  },
  {
    title: "Ticket Price",
    dataIndex: "price",
    key: "price",
    // align: "center",
  },
];

const EventDetails = () => {
  const navigate = useNavigate();
  const { id, view } = useParams();

  const [loading, setLoading] = useState(false);
  const [eventData, setEventData] = useState<Events>();

  const [getEventByIdMethod] = useLazyGetEventByIdQuery();

  const getEventData = async () => {
    try {
      setLoading(true);
      if (id) {
        const res = await getEventByIdMethod({ eventId: id }).unwrap();
        setLoading(false);
        if (res?.statusCode === 200) {
          setEventData(res?.data);
        }
      }
    } catch (error: any) {
      setLoading(false);
      errorToast(error?.data?.message || error?.data?.error);
    }
  };

  useEffect(() => {
    getEventData();
  }, []);

  return (
    <div style={{ padding: 50, gap: 20, display: "grid" }}>
      <Spin spinning={loading}>
        <Card>
          <div
            style={{
              alignItems: "center",
              display: "flex",
              justifyContent: "space-between",
              flexWrap: "wrap",
            }}
          >
            <h2>Event Details</h2>
            <Button
              style={{
                background: "#ECAC35",
                width: 100,
                height: 40,
                color: "black",
              }}
              type="primary"
              // htmlType="submit"
              onClick={() => {
                navigate(`/manage-event-owner/details/${view}`, {
                  replace: true,
                });
              }}
            >
              Back
            </Button>
          </div>

          <div>
            <Row gutter={16}>
              <Col xs={20} md={8}>
                <h4>Event Name</h4>
                <h5>{eventData?.name || ""}</h5>
              </Col>
              <Col xs={20} md={8}>
                <h4>Event Category</h4>
                <h5>{eventData?.eventCategory?.name || ""}</h5>
              </Col>
              <Col xs={20} md={8}>
                <h4>Event Description</h4>
                <h5>{eventData?.description || ""}</h5>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col xs={20} md={8}>
                <h4>Event Date Time</h4>
                <h5>{moment(eventData?.time).format("MMM Do YYYY, h:mm a")}</h5>
              </Col>
              {/* <Col xs={20} md={8}>
                <h4>Host Name</h4>
                <h5>{eventData?.hostName || ""}</h5>
              </Col> */}
              <Col xs={20} md={8}>
                <h4>Location</h4>
                <h5>{eventData?.address || ""}</h5>
              </Col>
            </Row>
          </div>
        </Card>

        {eventData?.ticket?.length ? (
          <Card>
            <h2>Ticket Details</h2>
            <Table
              columns={columns}
              dataSource={eventData?.ticket}
              pagination={false}
              rowKey="_id"
            />
          </Card>
        ) : undefined}

        <Card>
          <h2>Images, and videos for the event</h2>
          <div>
            <Row gutter={16}>
              <Col xs={24} md={12}>
                <div
                  style={{
                    display: "flex",
                    gap: 20,
                  }}
                >
                  {eventData?.images?.length
                    ? eventData?.images?.map((item, index) => {
                        return (
                          <figure key={index}>
                            {/* @ts-ignore */}
                            {item?.split(".").pop() === "mp4" ? (
                              <video
                                muted
                                src={item}
                                autoPlay
                                style={{
                                  height: 150,
                                  borderRadius: 10,
                                  width: 150,
                                  // margin: "auto",
                                }}
                              />
                            ) : (
                              <img
                                alt=""
                                src={item}
                                style={{
                                  height: 150,
                                  borderRadius: 10,
                                  width: 150,
                                  // margin: "auto",
                                }}
                              />
                            )}
                          </figure>
                        );
                      })
                    : undefined}
                </div>
              </Col>
            </Row>
          </div>
        </Card>
        <Card>
          <h2>Event Rules</h2>

          <Row gutter={16}>
            <Col xs={24} md={12}>
              <h4>Booking Rules</h4>
              <h5
                dangerouslySetInnerHTML={{
                  __html: eventData?.bookingRules || "",
                }}
              ></h5>
            </Col>
            <Col xs={24} md={12}>
              <h4>Vendor Rules</h4>
              <h5
                dangerouslySetInnerHTML={{
                  __html: eventData?.vendorRules || "",
                }}
              ></h5>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col xs={24} md={12}>
              <h4>Cancellation Rules</h4>
              <h5
                dangerouslySetInnerHTML={{
                  __html: eventData?.cancellationRules || "",
                }}
              ></h5>
            </Col>
          </Row>
        </Card>
      </Spin>
    </div>
  );
};

export default EventDetails;
