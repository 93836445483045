import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Dropdown, Layout, MenuProps, Modal, Spin } from "antd";
import {
  BellOutlined,
  CaretDownOutlined,
  UserOutlined,
  LogoutOutlined,
  KeyOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";

import {
  STORAGE_KEYS,
  errorToast,
  getFromStorage,
  removeFromStorage,
} from "../helpers";
import { useAppDispatch } from "../hooks/store";

import {
  useLazyGetProfileQuery,
  useLazyLogoutApiQuery,
} from "../services/auth";
import useAuth from "../hooks/useAuth";
import { setCredentials } from "../reducer/authSlice";
import { useLazyGetNotificationsCountQuery } from "../services/notifications";

const HeaderBar = ({
  collapsed,
  setCollapsed,
}: {
  collapsed: boolean;
  setCollapsed: Dispatch<SetStateAction<boolean>>;
}) => {
  const [getProfile, getProfileData] = useLazyGetProfileQuery();
  const [handleLogoutMethod] = useLazyLogoutApiQuery();
  // const { language, changeLanguage } = useLanguageContext();

  const [notificationCount] = useLazyGetNotificationsCountQuery();
  const [countData, setCountData] = useState<any>();

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const userData = useAuth();
  console.log(userData);

  const { confirm } = Modal;

  const items: MenuProps["items"] = [
    {
      key: "1",
      label: <span>Profile</span>,
      icon: <UserOutlined />,
      onClick: () => navigate("/profile"),
    },
    {
      key: "2",
      label: <span>Change Password</span>,
      icon: <KeyOutlined />,
      onClick: () => navigate("/change-password"),
    },
    {
      key: "3",
      label: <span>Logout</span>,
      icon: <LogoutOutlined />,
      onClick: () => {
        confirmLogout();
      },
    },
  ];

  const handleLogout = async () => {
    try {
      const res = await handleLogoutMethod({}).unwrap();

      if (res?.statusCode === 200) {
        setTimeout(() => {
          dispatch(
            setCredentials({
              user: null,
              token: null,
            })
          );
          removeFromStorage(STORAGE_KEYS.token);

          navigate("/", { replace: true });
        }, 500);
      }
    } catch (error: any) {
      setTimeout(() => {
        dispatch(
          setCredentials({
            user: null,
            token: null,
          })
        );
        removeFromStorage(STORAGE_KEYS.token);

        navigate("/", { replace: true });
      }, 500);
      errorToast(error?.data?.message || error?.data?.error);
    }
  };

  const confirmLogout = () => {
    confirm({
      title: "Alert",
      content: "Are you sure you want to Logout?",
      okText: "Yes",
      okType: "primary",
      cancelText: "No",
      centered: true,
      onOk() {
        handleLogout();
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const getNotificationsDetails = async () => {
    try {
      const res = await notificationCount({}).unwrap();

      if (res?.statusCode === 200) {
        setCountData(res?.data?.notificationCount);
      }
    } catch (error: any) {
      console.log(error);
    }
  };

  const getProfileDetails = async (token: string) => {
    const res = await getProfile({}).unwrap();
    dispatch(
      setCredentials({
        user: res?.data || null,
        token: token || null,
      })
    );
  };

  const handleNotificationClick = () => {
    navigate("/receive-notifications");
    setCountData(0);
  };

  useEffect(() => {
    const token = getFromStorage(STORAGE_KEYS.token);
    const userData = getFromStorage(STORAGE_KEYS.userData);

    if (token) {
      dispatch(
        setCredentials({
          token: JSON.parse(`${token}`),
          user: JSON.parse(`${userData}`),
        })
      );
    }
    getProfileDetails(JSON.parse(`${token}`));
  }, []);

  const { Header } = Layout;
  useEffect(() => {
    getNotificationsDetails();
  }, []);

  return (
    <Header style={{ padding: 0, height: 45 }}>
      <div
        style={{
          background: "white",
          display: "flex",
          justifyContent: "space-between",
          height: "75px",
          padding: "10px 50px",
        }}
      >
        <div
          style={{ cursor: "pointer" }}
          onClick={() => setCollapsed(!collapsed)}
        ></div>

        <div
          style={{
            display: "flex",
            // alignItems: "center",
            // width: 350,
            justifyContent: "flex-end",
            gap: "50px",
            cursor: "pointer",
          }}
        >
          <div onClick={handleNotificationClick}>
            {countData > 0 ? (
              <img
                src="/static/images/notiOn.png"
                alt=""
                style={{ height: "30px", width: "30px", marginTop: "12px" }}
              />
            ) : (
              <img
                src="/static/images/notiOff.png"
                alt=""
                style={{ height: "30px", width: "30px", marginTop: "12px" }}
              />
            )}
            {/* <BellOutlined style={{ fontSize: 25 }} /> */}
          </div>

          <Dropdown menu={{ items }}>
            <div
              style={{
                display: "flex",
                cursor: "pointer",
              }}
            >
              <img
                style={{
                  height: "40px",
                  margin: "auto",
                  marginRight: "14px",
                  width: "40px",
                  borderRadius: "100%",
                }}
                src={userData?.image || "/static/images/dummy.png"}
                // src={"/static/images/dummy.png"}
                alt=""
              />
              <div
                onClick={(e) => e.preventDefault()}
                style={{
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                }}
              >
                <div>
                  <div style={{ height: 20 }}>
                    {userData?.firstName || ""} {userData?.lastName || ""}
                    <CaretDownOutlined style={{ paddingLeft: "7px" }} />
                  </div>
                  <div>
                    {userData?.role === "COADMIN"
                      ? "Sub Admin"
                      : userData?.role?.toLowerCase() || "Admin"}
                  </div>
                </div>
              </div>
            </div>
          </Dropdown>
        </div>
      </div>
    </Header>
  );
};

export default HeaderBar;
