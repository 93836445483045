import React, { useEffect, useState } from "react";
import { Layout, Menu } from "antd";
import {
  UserOutlined,
  TeamOutlined,
  PieChartOutlined,
  FileImageOutlined,
  AlignLeftOutlined,
  LikeOutlined,
  PicLeftOutlined,
  ContainerOutlined,
  BellOutlined,
  SolutionOutlined,
  FormOutlined,
  SwitcherOutlined,
  DollarCircleOutlined,
} from "@ant-design/icons";
import { Link, useLocation } from "react-router-dom";
import useAuth from "../hooks/useAuth";
console.log(Link, "KLISTTT");

const { Sider } = Layout;

const SiderBar = ({ collapsed }: { collapsed: boolean }) => {
  const location = useLocation();
  const userData = useAuth();
  console.log(userData, "userrrr");

  const [selectedKey, setSelectedKey] = useState("1");
  const [list, setList] = useState<any>([]);
  console.log(list);

  const items = [
    {
      key: "1",
      icon: <PieChartOutlined />,
      label: "Dashboard",
      to: "/dashboard",
    },
    {
      key: "21",
      icon: <FileImageOutlined />,
      label: "Manage Banners",
      to: "/manage-banners",
    },
    {
      key: "2",
      icon: <TeamOutlined />,
      label: "Manage Sub-Admin",
      to: "/managesubadmins",
    },
    {
      key: "3",
      icon: <UserOutlined />,
      label: "Manage Users",
      to: "/manageusers",
    },
    {
      key: "4",
      icon: <AlignLeftOutlined />,
      label: "Listed Products Posts",
      to: "/listed-products-posts",
    },
    {
      key: "5",
      icon: <LikeOutlined />,
      label: "Featured Products",
      to: "/recommended-products",
    },
    {
      key: "6",
      icon: <PicLeftOutlined />,
      label: "Product Categories",
      to: "/product-categories",
    },
    {
      key: "7",
      icon: <ContainerOutlined />,
      label: "Manage Membership Plans",
      to: "/manage-membership-plans",
    },
    {
      key: "8",
      icon: <ContainerOutlined />,
      label: "Booster Subscriptions",
      to: "/booster-subscriptions",
    },
    {
      key: "9",
      icon: <BellOutlined />,
      label: "Notifications",
      to: "/notifications",
    },
    {
      key: "10",
      icon: <DollarCircleOutlined />,
      label: "Newsletter",
      to: "/manage-newsletter",
    },
    {
      key: "11",
      icon: <SolutionOutlined />,
      label: "Customer Support",
      to: "/customersupport",
    },
    {
      key: "12",
      icon: <FormOutlined />,
      label: "Manage CMS",
      to: "/managecms",
    },
    {
      key: "13",
      icon: <SwitcherOutlined />,
      label: "Manage FAQ's",
      to: "/manage-faq",
    },
  ];

  useEffect(() => {
    const selectedItem = items.find((item) =>
      location.pathname.includes(item.to)
    );
    if (selectedItem) setSelectedKey(selectedItem.key);
    else setSelectedKey("");
  }, [location.pathname]);

  // useEffect(() => {
  //   let arr = [];

  //   if (userData && userData?.permissions?.length) {
  //     const permissions = userData?.permissions;
  //     arr = items?.map((item) => {
  //       let idx = -1;
  //       idx = permissions?.findIndex((ele: any) => ele?.label === item?.label);
  //       if (idx != -1) {
  //         if (permissions[idx].isView) {
  //           return item;
  //         }
  //         return false;
  //       }
  //     });
  //   } else {
  //     arr = items;
  //   }
  //   setList(arr);
  // }, [userData]);

  useEffect(() => {
    let arr = [];
    if (userData?.role === "ADMIN") {
      setList(items);
    } else if (userData?.permissions?.length) {
      const permission = userData?.permissions;
      arr = items.map((item) => {
        let idx = -1;
        idx = permission?.findIndex((ele: any) => ele?.label === item?.label);
        if (idx > -1) {
          if (permission[idx].isView) {
            return item;
          }
          return false;
        }
      });
      setList(arr);
    }
  }, [userData]);

  return (
    <Sider trigger={null} collapsible collapsed={collapsed}>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <figure className="logo_sidebar">
          <img src={"/static/images/logo.png"} className="logo_img" alt="" />
        </figure>
      </div>
      <Menu
        style={{ marginTop: 16 }}
        theme="dark"
        mode="inline"
        defaultSelectedKeys={[selectedKey]}
        selectedKeys={[selectedKey]}
      >
        {list
          ?.filter((ele: any) => ele !== undefined)
          ?.map((item: any) => (
            <Menu.Item key={item?.key} icon={item?.icon}>
              <Link to={item?.to}>{item?.label}</Link>
            </Menu.Item>
          ))}
      </Menu>
    </Sider>
  );
};

export default SiderBar;
