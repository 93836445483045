import AuthWrapper from "../../../layout/authWrapper";
import "./login.css";
import { Button, Card, Checkbox, Form, Input } from "antd";
import { useNavigate } from "react-router-dom";

import {
  STORAGE_KEYS,
  errorToast,
  getFromStorage,
  removeFromStorage,
  setToStorage,
  successToast,
} from "../../../helpers";
import { useAppDispatch } from "../../../hooks/store";

import { useEffect, useState } from "react";
import { usePostLoginMutation } from "../../../services/auth";
import { setCredentials } from "../../../reducer/authSlice";
import { generateEncryptedKeyBody } from "../../../utils/crypto";
import { CommonBody } from "../../../types/General";
import { getMessaging, getToken } from "firebase/messaging";
import app from "../../../utils/firebaseKeys";

const Login = () => {
  const [postLogin, postLoginData] = usePostLoginMutation();
  const [isRemember, setIsRemember] = useState(false);

  const items = [
    {
      key: "1",
      label: "Dashboard",
      selected: "/dashboard",
    },
    {
      key: "21",
      label: "Manage Banners",
      selected: "/manage-banners",
    },
    {
      key: "2",
      label: "Manage Sub-Admin",
      selected: "/managesubadmins",
    },
    {
      key: "3",
      label: "Manage Users",
      selected: "/manageusers",
    },
    {
      key: "4",
      label: "Listed Products Posts",
      selected: "/listed-products-posts",
    },
    {
      key: "5",
      label: "Featured Products",
      selected: "/recommended-products",
    },
    {
      key: "6",
      label: "Product Categories",
      selected: "/product-categories",
    },
    {
      key: "7",
      label: "Manage Membership Plans",
      selected: "/manage-membership-plans",
    },
    {
      key: "8",
      label: "Booster Subscriptions",
      selected: "/booster-subscriptions",
    },
    {
      key: "9",
      label: "Notifications",
      selected: "/notifications",
    },
    {
      key: "10",
      label: "Newsletter",
      selected: "/manage-newsletter",
    },
    {
      key: "11",
      label: "Customer Support",
      selected: "/customersupport",
    },

    {
      key: "12",
      label: "Manage CMS",
      selected: "/managecms",
    },
    {
      key: "13",

      label: "Manage FAQ's",
      selected: "/manage-faq",
    },

    // {
    //   key: "18",
    //   icon: <SettingOutlined />,
    //   label: "Revenue Management",
    //   onClick: () => navigate("/revenue-management"),
    // },
  ];

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const onFinish = async (values: any) => {
    const fcmToken = await getFromStorage(STORAGE_KEYS.fcmToken);

    let fcm_token = "1234";
    const messaging = getMessaging(app);
    const status = await Notification.requestPermission();
    if (status && status === "granted") {
      fcm_token = await getToken(messaging, {
        vapidKey:
          "BI-w6jbH5r8t4S-Ak1bGacEnen_r7nOtPz2JEa9MkTRDfsDq0I0JvFp1lJRDiKkt6kkzizWG-JIeLQkwKn-dczQ",
      });
      if (fcm_token) {
        setToStorage(STORAGE_KEYS.fcmToken, fcm_token);
      }
    }

    const data = {
      key: values.email,
      password: values.password,
      deviceType: "WEB",
      deviceToken: fcmToken ? fcmToken : fcm_token ? fcm_token : "1234",
    };
    console.log(data, "bodyyyyy");

    if (isRemember) {
      setToStorage(STORAGE_KEYS.credentials, JSON.stringify(data));
    } else {
      await removeFromStorage(STORAGE_KEYS.credentials);
    }

    try {
      let encryptedBody = generateEncryptedKeyBody(data) as CommonBody;
      const res = await postLogin(encryptedBody).unwrap();

      if (res?.statusCode === 200) {
        setToStorage(STORAGE_KEYS.token, JSON.stringify(res?.data?.token));
        setToStorage(STORAGE_KEYS.userData, JSON.stringify(res?.data));
        dispatch(
          setCredentials({
            user: res?.data || null,
            token: res?.data?.token || null,
          })
        );
        if (res?.data?.role === "COADMIN") {
          const permission = res?.data?.permissions;

          if (res?.data?.role === "COADMIN" && permission?.length > 0) {
            const firstPermissionLabel = permission[0]?.label;
            const redirectPath = items.find(
              (item) => item.label === firstPermissionLabel
            )?.selected;

            if (redirectPath) {
              navigate(redirectPath);
            } else {
              console.error("Invalid permission label for redirection");
              // Handle the case where the label doesn't match any item in the array
            }
          }
        } else {
          // Default redirect for other roles or when no permissions are available
          navigate("/dashboard");
        }
        successToast("Login Successfully");
        console.log(res?.data?.token, "+++SD+FS+");
      } else {
        errorToast(res?.message);
      }
    } catch (error: any) {
      errorToast(error?.data?.message);
    }
  };

  const onSelectChange = async () => {
    const creds = getFromStorage(STORAGE_KEYS.credentials);
    console.log(creds, "creds");

    if (creds) {
      const credentials = await JSON.parse(`${creds}`);
      form.setFieldsValue({
        email: credentials?.key,
        password: credentials?.password,
      });
      setIsRemember(true);
    }
  };

  useEffect(() => {
    onSelectChange();
  }, []);

  return (
    <AuthWrapper>
      <Card className="login_card">
        <div className="bck_arrw_div">
          {/* <h3>Krafo Admin Panel</h3> */}
          <figure className="logo">
            <img src="/static/images/logo.png" alt="" />
          </figure>
        </div>
        <div className="bck_arrw_div">
          <h3>Login to Krafo Admin Panel</h3>
        </div>
        <Form
          name="basic"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          style={{
            maxWidth: 600,
            // marginTop: 50,
            // display: "flex",
            justifyContent: "center",
          }}
          form={form}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          //   onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item
            name="email"
            rules={[
              {
                required: true,
                message: "Please input your email!",
              },
              {
                type: "email",
                message: "The input is not valid email!",
              },
            ]}
          >
            <Input
              style={{ width: "460px", height: "50px" }}
              placeholder="Email"
            />
          </Form.Item>

          <Form.Item
            // label="Password"

            name="password"
            rules={[{ required: true, message: "Please input your password!" }]}
          >
            <Input.Password
              style={{ width: "460px", height: "50px" }}
              placeholder="Password"
            />
          </Form.Item>

          <Form.Item name="remember" valuePropName="checked">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "460px",
              }}
            >
              <Checkbox
                onChange={() => setIsRemember(!isRemember)}
                checked={isRemember}
                style={{ color: "#000", fontWeight: 600 }}
              >
                Remember me
              </Checkbox>
              <div
                style={{ cursor: "pointer", color: "#000", fontWeight: 600 }}
                onClick={() => {
                  navigate("/forgotpassword");
                }}
              >
                Forgot password?
              </div>
            </div>
          </Form.Item>

          <Form.Item wrapperCol={{ offset: 4, span: 16 }}>
            <div style={{ display: "flex" }}>
              <Button
                style={{
                  background: "#7DBFDD",
                  width: 200,
                  height: 50,
                  margin: "auto",
                }}
                type="primary"
                htmlType="submit"
                loading={postLoginData.isLoading}
              >
                Login
              </Button>
            </div>
          </Form.Item>
        </Form>
      </Card>
    </AuthWrapper>
  );
};

export default Login;
