import { END_POINTS } from "../helpers";
import { CommonBody, MembershipPlan } from "../types/General";
import emptySplitApi from "../utils/rtk";

type CommonResponse = {
  data: any;
  statusCode: number;
  message: string;
};

type PutPlanParams = {
  planId: string | undefined;
  encryptedBody: CommonBody;
};

export const membershipApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getPlanDetails: builder.query<
      CommonResponse & {
        data: {
          membership: MembershipPlan[];
          count: number;
        };
      },
      { page: number; size: number; search: string }
    >({
      query: ({ page, size, search }) => ({
        url:
          END_POINTS.plans +
          "?page=" +
          page +
          "&size=" +
          size +
          "&search=" +
          search,

        method: "GET",
      }),
    }),

    getPlanDetailsId: builder.query<
      CommonResponse & {
        data: MembershipPlan;
      },
      string | undefined
    >({
      query: (planId) => ({
        url: `${END_POINTS.plans}/${planId}`,
        method: "GET",
      }),
    }),
    postAddPlan: builder.mutation<CommonResponse, CommonBody>({
      query: (body) => ({
        url: END_POINTS.plans,
        method: "POST",
        body,
      }),
    }),
    putBlockMembership: builder.mutation<CommonResponse, PutPlanParams>({
      query: ({ encryptedBody, planId }) => ({
        url: `${END_POINTS.plans}/${planId}`,
        method: "PUT",
        body: encryptedBody,
      }),
    }),
    putUpdatePlan: builder.mutation<CommonResponse, PutPlanParams>({
      query: ({ encryptedBody, planId }) => ({
        url: `${END_POINTS.plans}/${planId}`,
        method: "PUT",
        body: encryptedBody,
      }),
    }),

    deletePlan: builder.mutation<CommonResponse, { planId: string }>({
      query: ({ planId }) => ({
        url: `${END_POINTS.plans}/${planId}`,
        method: "DELETE",
      }),
    }),
    getNewsLetter: builder.query<
      CommonResponse & {
        data: any;
      },
      { page: number; size: number; search: string }
    >({
      query: ({ page, size, search }) => ({
        url:
          END_POINTS.subscribeNewsletter +
          "?page=" +
          page +
          "&size=" +
          size +
          "&search=" +
          search,

        method: "GET",
      }),
    }),

    boosterSubscription: builder.query<
      CommonResponse & {
        data: {
          boosterSusbcribedPlans: any;
          count: number;
        };
      },
      { page: number; size: number; search: string }
    >({
      query: ({ page, size, search }) => ({
        url:
          END_POINTS.boosterSubscriptions +
          "?page=" +
          page +
          "&size=" +
          size +
          "&search=" +
          search,

        method: "GET",
      }),
    }),

    getNewsLetterCsv: builder.query<CommonResponse, {}>({
      query: ({}) => ({
        url: `${END_POINTS.subscribeNewsletterExport}`,
        method: "GET",
      }),
    }),
  }),
});

export const {
  useLazyGetPlanDetailsQuery,
  useDeletePlanMutation,
  usePutBlockMembershipMutation,
  useLazyGetPlanDetailsIdQuery,
  usePostAddPlanMutation,
  usePutUpdatePlanMutation,
  useLazyGetNewsLetterQuery,
  useLazyBoosterSubscriptionQuery,
  useLazyGetNewsLetterCsvQuery,
} = membershipApi;
