import { Button, Card, Col, Form, Input, Row, Select, Spin } from "antd";
import { useNavigate } from "react-router-dom";
import { Key, useEffect, useState } from "react";
import "react-phone-input-2/lib/style.css";
import { ContentHeader } from "../../../components";
import { CommonBody, SubCategory, category } from "../../../types/General";
import { useLazyGetCategoryQuery } from "../../../services/category";
import {
  useForFeatureProductsMutation,
  useLazyGetProductByCategoryQuery,
  useLazyGetProductByIdQuery,
  useLazyGetSubCategoryListQuery,
} from "../../../services/product";
import { errorToast, successToast } from "../../../helpers";
import { generateEncryptedKeyBody } from "../../../utils/crypto";
import { LazyLoadImage } from "react-lazy-load-image-component";

const RecommendedProductsForm = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [getCategory] = useLazyGetCategoryQuery();
  const [getProductsByCategory] = useLazyGetProductByCategoryQuery();
  const [getSubCatMethod] = useLazyGetSubCategoryListQuery();

  const [getProductDataById] = useLazyGetProductByIdQuery();
  const [price, setPrice] = useState("");

  const [productDetail, setProductDetail] = useState([]);

  const [selectedCategory, setSelectedCategory] = useState<string | undefined>(
    undefined
  );
  const [selectedSubCategory, setSelectedSubCategory] = useState<
    string | undefined
  >(undefined);
  const [selectedProduct, setSelectedProduct] = useState<string | undefined>(
    undefined
  );
  const [allCategoryData, setAllCategoryData] = useState<category[]>();
  const [productsData, setProductsData] = useState<category[]>();
  const [subCategoryData, setSubCategoryData] = useState<SubCategory[]>();

  const [addFeatureProduct, addFeatureProductMethode] =
    useForFeatureProductsMutation();

  const { Option } = Select;

  const getCategoryDetails = async (page: number) => {
    try {
      setLoading(true);
      const res = await getCategory({
        page: page,
        size: 50,
        search: "",
      }).unwrap();
      setLoading(false);

      if (res?.statusCode === 200) {
        setAllCategoryData(res?.data?.category);
      }
    } catch (error: any) {
      setLoading(false);
      console.log(error);
    }
  };
  const getProductsCategoryHandler = async () => {
    try {
      setLoading(true);
      const res = await getProductsByCategory(selectedCategory).unwrap();
      setLoading(false);

      if (res?.statusCode === 200) {
        setProductsData(res?.data?.category);
      }
    } catch (error: any) {
      setLoading(false);
      console.log(error);
    }
  };

  const getSubCategoryHandler = async () => {
    try {
      setLoading(true);
      const res = await getSubCatMethod({
        categoryId: selectedCategory,
        page: 1,
        size: 50,
        search: "",
        isAll: true,
      }).unwrap();
      setLoading(false);

      if (res?.statusCode === 200) {
        setSubCategoryData(res?.data?.subCategory);
      }
    } catch (error: any) {
      setLoading(false);
      console.log(error);
    }
  };

  const getProductDetailById = async () => {
    try {
      setLoading(true);
      const res = await getProductDataById(selectedProduct).unwrap();
      setLoading(false);
      if (res?.statusCode === 200) {
        form.setFieldsValue({
          priceOfTheProduct: res?.data?.price,
        });
        setProductDetail(res?.data?.images);

        setPrice(res?.data?.price);
      }
    } catch (error: any) {
      setLoading(true);
      console.log(error);
    }
  };

  const handleChangeForSelectedCategory = (value: any) => {
    form.setFieldsValue({
      categoryOfTheProduct: value,
    });
    setSelectedCategory(value ? value : undefined);

    form.validateFields(["categoryOfTheProduct"]);
  };

  const handleChangeForSelectedSubCategory = (value: any) => {
    form.setFieldsValue({
      subCategoryField: value,
    });
    setSelectedSubCategory(value ? value : undefined);

    form.validateFields(["subCategoryField"]);
  };

  const handleChangeForSelectedProduct = (value: any) => {
    form.setFieldsValue({
      productName: value,
    });
    setSelectedProduct(value ? value : undefined);

    form.validateFields(["productName"]);
  };

  useEffect(() => {
    getCategoryDetails(1);
  }, []);

  useEffect(() => {
    if (selectedCategory?.length) {
      getSubCategoryHandler();
    }
  }, [selectedCategory]);

  useEffect(() => {
    if (selectedSubCategory?.length) {
      getProductsCategoryHandler();
    }
  }, [selectedSubCategory]);

  useEffect(() => {
    if (selectedProduct?.length) {
      getProductDetailById();
    }
  }, [selectedProduct]);

  const onFinish = async () => {
    let data = {
      objectId: selectedProduct ? selectedProduct : "",
      operation: "push",
    };

    try {
      let encryptedBody = generateEncryptedKeyBody(data) as CommonBody;

      const res = await addFeatureProduct(encryptedBody).unwrap();

      if (res?.statusCode === 200) {
        successToast("Added Successfully");
        navigate("/recommended-products");
      }
    } catch (error: any) {
      console.log(error);
      errorToast(error?.data?.message);
    }
  };

  return (
    <div style={{ padding: 50 }}>
      <ContentHeader
        buttonText="Back"
        buttonNavigateTo="recommended-products"
        heading="Add Feature Product"
      />
      <Card>
        <Spin spinning={loading}>
          <div>
            <Form
              name="basic"
              layout="vertical"
              form={form}
              style={{
                maxWidth: "100%",
                marginTop: 10,
              }}
              initialValues={{ remember: true }}
              onFinish={onFinish}
              autoComplete="off"
            >
              <Row gutter={16}>
                <Col xs={24} md={12}>
                  <Form.Item
                    name="categoryOfTheProduct"
                    rules={[
                      {
                        required: true,
                        message: "Please select Category of the product!",
                      },
                    ]}
                  >
                    <div>
                      <h4>Select Category of the Product</h4>
                      <Select
                        allowClear
                        maxLength={20}
                        placeholder="Select Category"
                        className="select_type"
                        onChange={(val) => handleChangeForSelectedCategory(val)}
                        value={selectedCategory}
                        style={{ height: 60, width: "100%" }}
                        showSearch
                        optionFilterProp="label"
                        filterOption={(input, option) =>
                          (
                            option?.label?.toString()?.toLowerCase() || ""
                          ).indexOf(input.toLowerCase()) !== -1
                        }
                      >
                        {allCategoryData?.map((item, index) => (
                          <Option
                            key={index}
                            value={item?._id}
                            label={item?.name}
                          >
                            {item?.name}
                          </Option>
                        ))}
                      </Select>
                    </div>
                  </Form.Item>
                </Col>

                <Col xs={24} md={12}>
                  <Form.Item
                    name="subCategoryField"
                    rules={[
                      {
                        required: true,
                        message: "Please select Sub Category of the product!",
                      },
                    ]}
                  >
                    <div>
                      <h4>Select Sub Category of the Product</h4>
                      <Select
                        allowClear
                        maxLength={20}
                        placeholder="Select Category"
                        className="select_type"
                        disabled={selectedCategory ? false : true}
                        onChange={(val) =>
                          handleChangeForSelectedSubCategory(val)
                        }
                        value={selectedSubCategory}
                        style={{ height: 60, width: "100%" }}
                        showSearch
                        optionFilterProp="label"
                        filterOption={(input, option) =>
                          (
                            option?.label?.toString()?.toLowerCase() || ""
                          ).indexOf(input.toLowerCase()) !== -1
                        }
                      >
                        {subCategoryData?.map((item, index) => (
                          <Option
                            key={index}
                            value={item?._id}
                            label={item?.name}
                          >
                            {item?.name}
                          </Option>
                        ))}
                      </Select>
                    </div>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col xs={24} md={12}>
                  <Form.Item
                    name="productName"
                    rules={[
                      {
                        required: true,
                        message: "Please select Product name!",
                        whitespace: true,
                      },
                    ]}
                  >
                    <div>
                      <h4>Select Product Name</h4>
                      <Select
                        allowClear
                        maxLength={20}
                        placeholder="Select Product"
                        className="select_type"
                        onChange={(val) => handleChangeForSelectedProduct(val)}
                        value={selectedProduct}
                        disabled={selectedSubCategory ? false : true}
                        style={{ height: 60, width: "100%" }}
                        showSearch
                        optionFilterProp="label"
                        filterOption={(input, option) =>
                          (
                            option?.label?.toString()?.toLowerCase() || ""
                          ).indexOf(input.toLowerCase()) !== -1
                        }
                      >
                        {productsData?.map((item, index) => (
                          <Option
                            key={index}
                            value={item?._id}
                            label={item?.name}
                          >
                            {item?.name}
                          </Option>
                        ))}
                      </Select>
                    </div>
                  </Form.Item>
                </Col>

                <Col xs={24} md={12}>
                  <Form.Item
                    name="priceOfTheProduct"
                    rules={[
                      {
                        required: true,
                        message: "Please input Price of the Product!",
                      },
                    ]}
                  >
                    <div>
                      <h4>Price of the Product</h4>
                      <Input
                        disabled
                        placeholder="Price of the Product"
                        style={{
                          color: "black",
                          opacity: 1,
                          height: 55,
                        }}
                        value={price}
                        onChange={(e) => {
                          setPrice(e.target.value);
                        }}
                      />
                    </div>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col xs={24} md={24}>
                  <Row gutter={16}>
                    <Col xs={24} md={24}>
                      <div
                        style={{
                          display: "flex",
                          gap: 20,
                          flexWrap: "wrap",
                          paddingBottom: 20,
                          //
                        }}
                      >
                        {productDetail?.map(
                          (
                            item: string | undefined,
                            index: Key | null | undefined
                          ) => (
                            <LazyLoadImage
                              effect="blur"
                              key={index}
                              style={{
                                height: 200,
                                borderRadius: 10,
                                width: 200,
                              }}
                              src={item}
                              alt="img"
                            />
                          )
                        )}
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>

              <Form.Item wrapperCol={{ offset: 4, span: 16 }}>
                <div style={{ display: "flex" }}>
                  <Button
                    loading={addFeatureProductMethode?.isLoading}
                    style={{
                      background: "#ECAC35",
                      width: 200,
                      height: 50,
                      margin: "auto",
                      color: "black",
                    }}
                    type="primary"
                    htmlType="submit"
                    // onClick={() => {
                    //   navigate("/recommended-products", { replace: true });
                    // }}
                  >
                    Submit
                  </Button>
                </div>
              </Form.Item>
            </Form>
          </div>
        </Spin>
      </Card>
    </div>
  );
};

export default RecommendedProductsForm;
