// eslint-disable-next-line import/no-extraneous-dependencies
import crypto from "crypto-js";

export function generateSEKAndHash(token: string | null) {
  const iv = "5YPcLSBvFcGcamF2".slice(0, 16);
  try {
    const key = crypto.lib.WordArray.random(32);
    let dataPayload = {};
    if (token === "" || token === null || token === undefined) {
      // const currentTimestamp = new Date().getTime();
      // const currentTimestampInSeconds = Math.floor(new Date().getTime() / 1000);
      dataPayload = {
        date: new Date().toISOString(),
      };
    } else {
      dataPayload = {
        authorization: `Bearer ${token}`,
        date: new Date().toISOString(),
      };
    }
    let appkey = JSON.stringify(dataPayload);

    const encrypted = crypto.AES.encrypt(appkey, key, {
      iv: crypto.enc.Utf8.parse(iv),
      mode: crypto.mode.CBC,
    });
    const encryptedHex = encrypted.ciphertext.toString();
    const keyHash = key.toString();

    return {
      hash: keyHash,
      sek: encryptedHex,
    };
  } catch (error) {
    console.error("", error);
    return null;
  }
}

export function generateEncryptedKeyBody(body: any) {
  const iv = "5YPcLSBvFcGcamF2".slice(0, 16);
  try {
    const key = crypto.lib.WordArray.random(32);
    let dataPayload = JSON.stringify(body);

    const encrypted = crypto.AES.encrypt(dataPayload, key, {
      iv: crypto.enc.Utf8.parse(iv),
      mode: crypto.mode.CBC,
    });
    const encryptedHex = encrypted.ciphertext.toString();
    const keyHash = key.toString();

    return {
      hash: keyHash,
      sek: encryptedHex,
      // deviceType: "WEB",
    };
  } catch (error) {
    console.error("", error);
    return null;
  }
}
