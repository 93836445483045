// @ts-nocheck
import { Button, Card, Col, DatePicker, Form, Input, Row, Upload } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { UploadOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { ContentHeader } from "../../../components";
import { generateEncryptedKeyBody } from "../../../utils/crypto";
import { CommonBody } from "../../../types/General";
import { errorToast, successToast } from "../../../helpers";
import {
  useLazyGetOwnerDetailsIdQuery,
  usePostAddOwnerMutation,
  usePutUpdateOwnerMutation,
} from "../../../services/owner";
import { UploadMedia } from "../../../utils/mediaUpload";
import { RcFile } from "antd/es/upload";
import dayjs, { Dayjs } from "dayjs";

const OwnerForm = () => {
  const [phoneCode, setPhoneCode] = useState("+91");
  const [phoneNo, setPhoneNo] = useState("");
  const [profileImage, setProfileImage] = useState("");
  const [frontImage, setFrontImage] = useState("");
  const [docName, setDocName] = useState("");
  const [backImage, setBackImage] = useState("");
  const [expiryDate, setExpiryDate] = useState<dayjs.Dayjs | null>(null);
  const { id } = useParams();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [addOwner, addOwnerMutation] = usePostAddOwnerMutation();
  const [getOwnerDataById, getOwnerDataByIdQuery] =
    useLazyGetOwnerDetailsIdQuery();
  const [updateOwner, updateOwnerMutation] = usePutUpdateOwnerMutation();
  const [error, setError] = useState(false);

  const [values, setValues] = useState({
    firstName: "",
    lastName: "",
    about: "",
    email: "",
  });

  const onFinish = async (values: any) => {
    if (!phoneNo) {
      setError(true);
      return;
    }
    if (phoneNo?.length < 6) {
      setError(true);
      return;
    }
    setError(false);
    console.log("Received values of form: ", values);
    let data = {
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      countryCode: (phoneCode.includes("+") ? "" : "+") + phoneCode,
      phone: phoneNo,
      image: profileImage || "",
      about: values.about,
      docName: values.docName,
      docFrontImage: frontImage,
      docBackImage: backImage,
      docExpireDate: expiryDate ? expiryDate.toISOString() : null,
    };
    if (profileImage) {
      data.image = profileImage;
    }

    const encryptedBody = generateEncryptedKeyBody(data) as CommonBody;
    if (id === "add") {
      const res = await addOwner(encryptedBody).unwrap();
      try {
        if (res?.statusCode === 200) {
          successToast("Owner added successfully");
          navigate("/manage-event-owner", { replace: true });
        }
      } catch (error: any) {
        errorToast(error?.data?.message || error?.data?.error);
      }
    } else {
      const res = await updateOwner({ encryptedBody, ownerId: id }).unwrap();
      try {
        if (res?.statusCode === 200) {
          successToast("Owner updated successfully");
          navigate("/manage-event-owner", { replace: true });
        }
      } catch (error: any) {
        errorToast(error?.data?.message || error?.data?.error);
      }
    }
  };

  const getOwnerDetailsById = async () => {
    try {
      const res = await getOwnerDataById(id).unwrap();

      if (res?.statusCode === 200) {
        setValues({
          ...values,
          email: res?.data?.email || "",
          firstName: res?.data?.firstName || "",
          lastName: res?.data?.lastName || "",
          about: res?.data?.about || "",
        });
        setPhoneCode(res?.data?.countryCode);
        setPhoneNo(res?.data?.phone);
        setProfileImage(res?.data?.image);
        setBackImage(res?.data?.docBackImage);
        setFrontImage(res?.data?.docFrontImage);
        setDocName(res?.data?.docName);
        setExpiryDate(dayjs(res?.data?.docExpireDate));

        form.setFieldsValue({
          email: res?.data?.email,
          firstName: res?.data?.firstName,
          lastName: res?.data?.lastName,
          phone: res?.data?.phone,
          backImage: res?.data?.docBackImage,
          frontImage: res?.data?.docFrontImage,
          docName: res?.data?.docName,
          about: res?.data?.about,
          expiryDate: res?.data?.docExpireDate,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  console.log(values?.email, ";;;;;;");

  useEffect(() => {
    if (id !== "add") {
      getOwnerDetailsById();
    }
  }, []);

  const handleChangePhone = (phone: any, country: any) => {
    setPhoneCode(country?.dialCode);
    setPhoneNo(phone?.replace(country.dialCode, ""));
  };

  const handleImageUpload = async (file: RcFile) => {
    if (file?.type.includes("image")) {
      try {
        const res = await UploadMedia(file);

        if (res?.statusCode === 200) {
          setProfileImage(res?.data);
        }
      } catch (error: any) {
        console.log(error);
      }
    } else {
      errorToast("Only images are allowed");
    }

    return false; // Prevent Ant Design from automatically uploading the file
  };

  const handleDocumentUpload = async (file: any, key: string) => {
    if (file?.type.includes("image")) {
      try {
        const res = await UploadMedia(file);
        if (res?.statusCode === 200) {
          if (key === "front") {
            setFrontImage(res?.data);
          } else {
            setBackImage(res?.data);
          }
        }
      } catch (error: any) {
        console.log(error);
      }
    } else {
      errorToast("Only images are allowed");
    }
  };

  const handleDateChange = (date: dayjs.Dayjs | null, dateString: string) => {
    setExpiryDate(date);
  };
  const disabledDate = (current: Dayjs | null) => {
    return current && current.isBefore(dayjs(), "day");
  };

  useEffect(() => {
    form.setFieldsValue({
      expiryDate: expiryDate,
    });
  }, [expiryDate]);

  return (
    <div style={{ padding: 50 }}>
      <ContentHeader
        buttonText="Back"
        buttonNavigateTo="manage-event-owner"
        heading="Add Owner"
      />
      <Card loading={getOwnerDataByIdQuery.isLoading}>
        <div>
          <Form
            name="basic"
            layout="vertical"
            form={form}
            style={{
              maxWidth: "100%",
              marginTop: 10,
            }}
            initialValues={{ remember: true }}
            autoComplete="off"
            onFinish={onFinish}
          >
            <Row gutter={16}>
              <Col xs={24} md={12}>
                <Form.Item name="image">
                  <Upload
                    beforeUpload={handleImageUpload}
                    showUploadList={false}
                    className="upload_imgD"
                    accept="image/*"
                  >
                    <div>
                      <h4>Upload Profile Image</h4>
                      <Button icon={<UploadOutlined />}>Click to upload</Button>
                    </div>
                    {profileImage ? (
                      <img
                        style={{
                          height: 110,
                          width: 110,
                          borderRadius: "50%",
                          marginTop: 20,
                        }}
                        src={profileImage}
                        alt="profile"
                      />
                    ) : (
                      <></>
                    )}
                  </Upload>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col xs={24} md={12}>
                <Form.Item
                  name="firstName"
                  rules={[
                    {
                      required: true,
                      message: "Please input owner name!",
                      whitespace: true,
                    },
                    {
                      min: 3,
                      message: "Name must be at least 3 characters",
                    },
                    {
                      pattern: /^[A-Za-z\s]+$/,
                      message: "Please enter only alphabets",
                    },
                  ]}
                >
                  <div>
                    <h4>First Name</h4>
                    <Input
                      value={values.firstName}
                      onChange={(e) =>
                        setValues({ ...values, firstName: e.target.value })
                      }
                      placeholder="First Name"
                      style={{ height: 55 }}
                    />
                  </div>
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item
                  name="lastName"
                  rules={[
                    {
                      min: 3,
                      message: "Name must be at least 3 characters",
                    },
                    {
                      pattern: /^[A-Za-z\s]+$/,
                      message: "Please enter only alphabets",
                    },
                  ]}
                >
                  <div>
                    <h4>Last Name</h4>
                    <Input
                      value={values.lastName}
                      onChange={(e) =>
                        setValues({ ...values, lastName: e.target.value })
                      }
                      placeholder="Last Name"
                      style={{ height: 55 }}
                    />
                  </div>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col xs={24} md={12}>
                <Form.Item
                  // label="Username"
                  name="about"
                  rules={[
                    {
                      required: true,
                      message: "Please input your description!",
                    },
                    {
                      min: 10,
                      message: "Description must be at least 10 characters",
                    },
                  ]}
                >
                  <div>
                    <h4>About</h4>
                    <Input
                      value={values.about}
                      onChange={(e) =>
                        setValues({ ...values, about: e.target.value })
                      }
                      placeholder="About"
                      style={{ height: 55 }}
                    />
                  </div>
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: "Please input your E-mail!",
                    },
                    {
                      type: "email",
                      message: "The input is not valid E-mail!",
                    },
                  ]}
                >
                  <div>
                    <h4>Email</h4>
                    <Input
                      style={{ height: 55 }}
                      placeholder="E-mail"
                      value={values.email}
                      onChange={(e) =>
                        setValues({ ...values, email: e.target.value })
                      }
                    />
                  </div>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col xs={24} md={12}>
                <Form.Item
                  name="phone"
                  // rules={[
                  //   {
                  //     required: true,
                  //     message: "Please input your phone number!",
                  //   },
                  // ]}
                >
                  <div>
                    <h4>Phone Number</h4>
                    <div className="phn_cs">
                      <PhoneInput
                        value={phoneCode + phoneNo}
                        enableSearch={true}
                        placeholder="Phone number"
                        containerClass={"cntn_telCls"}
                        inputClass={"cstm_cls"}
                        buttonClass={"custm_btn"}
                        onChange={(phone, country) =>
                          handleChangePhone(phone, country)
                        }
                      />
                    </div>
                    {error && !phoneNo ? (
                      <h3 className="error_msg">
                        Please input your phone number!
                      </h3>
                    ) : null}
                    {error && phoneNo?.length && phoneNo?.length < 6 ? (
                      <h3 className="error_msg">
                        Please input valid phone number!
                      </h3>
                    ) : null}
                  </div>
                </Form.Item>
              </Col>
            </Row>
            <div style={{ marginTop: 20, marginBottom: 50 }}>
              <h3>Upload Document</h3>
            </div>
            <Row gutter={16}>
              <Col xs={24} md={12}>
                <Form.Item
                  name="frontImage"
                  rules={[
                    {
                      required: true,
                      message: "This field is compulsory",
                    },
                  ]}
                >
                  <Upload
                    beforeUpload={(file) => handleDocumentUpload(file, "front")}
                    showUploadList={false}
                    className="upload_imgD"
                    accept="image/*"
                  >
                    <div>
                      <h4>Upload Front Image</h4>
                      <Button icon={<UploadOutlined />}>Click to upload</Button>
                    </div>
                    {frontImage ? (
                      <img
                        className="edt_prlimg"
                        src={frontImage}
                        alt="profile"
                      />
                    ) : (
                      <></>
                    )}
                  </Upload>
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item
                  name="backImage"
                  rules={[
                    {
                      required: true,
                      message: "This field is compulsory",
                    },
                  ]}
                >
                  <Upload
                    beforeUpload={(file) => handleDocumentUpload(file, "back")}
                    showUploadList={false}
                    className="upload_imgD"
                    accept="image/*"
                  >
                    <div>
                      <h4>Upload Back Image</h4>
                      <Button icon={<UploadOutlined />}>Click to upload</Button>
                    </div>
                    {backImage ? (
                      <img
                        className="edt_prlimg"
                        src={backImage}
                        alt="profile"
                      />
                    ) : (
                      <></>
                    )}
                  </Upload>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col xs={24} md={12}>
                <Form.Item
                  name="docName"
                  rules={[
                    {
                      required: true,
                      message: "Please input your document name!",
                    },
                    {
                      min: 2,
                      message: "Document name must be at least 2 characters",
                    },
                  ]}
                >
                  <div>
                    <h4>Name of document</h4>
                    <Input
                      // disabled
                      style={{ height: 55 }}
                      placeholder="Name of document"
                      value={docName}
                      onChange={(e) => setDocName(e.target.value)}
                    />
                  </div>
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item
                  name="expiryDate"
                  rules={[
                    {
                      required: true,
                      message: "This field is compulsory",
                    },
                  ]}
                  validateTrigger={["onChange", "onBlur", "allowClear"]}
                >
                  <div>
                    <h4>Expiry Date</h4>
                    <DatePicker
                      value={expiryDate}
                      onChange={handleDateChange}
                      style={{ width: "100%", height: 55 }}
                      allowClear={false}
                      disabledDate={disabledDate}
                    />
                  </div>
                </Form.Item>
              </Col>
            </Row>

            <Form.Item wrapperCol={{ offset: 4, span: 16 }}>
              <div style={{ display: "flex" }}>
                <Button
                  loading={
                    updateOwnerMutation?.isLoading ||
                    addOwnerMutation?.isLoading
                  }
                  style={{
                    background: "#ECAC35",
                    width: 200,
                    height: 50,
                    margin: "auto",
                    color: "black",
                  }}
                  type="primary"
                  htmlType="submit"
                  onClick={() => {
                    setError(true);
                  }}
                >
                  Submit
                </Button>
              </div>
            </Form.Item>
          </Form>
        </div>
      </Card>
    </div>
  );
};

export default OwnerForm;
