import { Button, Card, Col, Form, Input, Row } from "antd";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { useEffect, useState } from "react";

import "react-phone-input-2/lib/style.css";
import { ContentHeader } from "../../../components";
import {
  useAddCategoryMutation,
  useLazyGetCategoryByIdQuery,
  usePutUpdateCategoryMutation,
} from "../../../services/category";
import { generateEncryptedKeyBody } from "../../../utils/crypto";
import { errorToast, successToast } from "../../../helpers";
import { CommonBody } from "../../../types/General";
import Upload, { RcFile } from "antd/es/upload";
import { UploadMedia } from "../../../utils/mediaUpload";
import { UploadOutlined } from "@ant-design/icons";

const AddProductCategoryForm = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const url = window.location.pathname;
  const path = url.includes("add");
  const { view } = useParams();
  const location = useLocation();
  const { state } = location;
  console.log(state, "STATE");

  const [addCategory] = useAddCategoryMutation();
  const [getCategoryById, getCategoryByIdData] = useLazyGetCategoryByIdQuery();
  const [updateCategory] = usePutUpdateCategoryMutation();

  const [categoryImage, setCategoryImage] = useState("");

  const [categoryName, setCategoryName] = useState("");
  const [categoryNameAr, setCategoryNameAr] = useState("");
  const [metaTitle, setMetaTitle] = useState("");
  const [metaDescription, setMetaDescription] = useState("");

  const getCategoryDetailById = async () => {
    try {
      const res = await getCategoryById(view).unwrap();

      if (res?.statusCode === 200) {
        form.setFieldsValue({
          image: res?.data?.image,
          categoryName: res?.data?.name,
          categoryName_ar: res?.data?.name_ar,
          metaTitle: res?.data?.metaTitle,
          metaDescription: res?.data?.metaDescription,
        });
        setCategoryName(res?.data?.name);
        setCategoryNameAr(res?.data?.name_ar);
        setCategoryImage(res?.data?.image);
        setMetaTitle(res?.data?.metaTitle);
        setMetaDescription(res?.data?.metaDescription);
      }
    } catch (error: any) {
      console.log(error);
    }
  };

  const onFinish = async (values: any) => {
    const data = {
      name: values.categoryName,
      name_ar: values.categoryName_ar,
      metaTitle: metaTitle,
      metaDescription: metaDescription,
      image: path ? [categoryImage] : categoryImage,
    };

    try {
      let encryptedBody = generateEncryptedKeyBody(data) as CommonBody;

      let res;
      path
        ? (res = await addCategory(encryptedBody).unwrap())
        : (res = await updateCategory({
            encryptedBody,
            categoryId: view,
          }).unwrap());
      navigate("/product-categories", { replace: true });
      if (res?.statusCode === 200) {
        successToast(
          path ? "Category added successfully" : "Category updated successfully"
        );
      } else {
        errorToast(res?.message);
      }
    } catch (error: any) {
      errorToast(error?.data?.message || error?.data?.error);
    }
  };

  const handleImageUpload = async (file: RcFile) => {
    if (file?.type.includes("image")) {
      try {
        const res = await UploadMedia(file);

        if (res?.statusCode === 200) {
          setCategoryImage(res?.data);
        }
      } catch (error: any) {
        console.log(error);
      }
    } else {
      errorToast("Only images are allowed");
    }

    return false; // Prevent Ant Design from automatically uploading the file
  };

  useEffect(() => {
    if (path) {
    } else {
      getCategoryDetailById();
    }
  }, []);

  return (
    <div style={{ padding: 50 }}>
      {/* <ContentHeader
        buttonText="Back"
        buttonNavigateTo="product-categories"
        heading="Add Category"
      /> */}
      <Card>
        <div
          style={{
            alignItems: "center",
            display: "flex",
            justifyContent: "space-between",
            flexWrap: "wrap",
          }}
        >
          <h2>Add Category</h2>
          <Button
            style={{
              background: "#ECAC35",
              width: 100,
              height: 40,
              color: "black",
            }}
            type="primary"
            // htmlType="submit"
            onClick={() => {
              navigate("/product-categories", {
                replace: true,
                state: {
                  page: state?.page,
                },
              });
            }}
          >
            Back
          </Button>
        </div>
        <div>
          <Form
            name="basic"
            layout="vertical"
            form={form}
            style={{
              maxWidth: "80%",
              marginTop: 10,
            }}
            initialValues={{ remember: true }}
            autoComplete="off"
            onFinish={onFinish}
          >
            <Row gutter={16}>
              <Col xs={24} md={12}>
                <Form.Item
                  name="image"
                  rules={[
                    {
                      required: true,
                      message: "Please upload an image",
                    },
                  ]}
                >
                  <Upload
                    beforeUpload={handleImageUpload}
                    showUploadList={false}
                    className="upload_imgD"
                    accept="image/*"
                  >
                    <div>
                      <h4>Upload Image</h4>
                      <Button icon={<UploadOutlined />}>Click to upload</Button>
                    </div>
                    {categoryImage ? (
                      <img
                        style={{
                          height: 110,
                          width: 110,
                          borderRadius: "50%",
                          marginTop: 20,
                        }}
                        src={categoryImage}
                        alt="profile"
                      />
                    ) : (
                      <></>
                    )}
                  </Upload>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col xs={24} md={12}>
                <Form.Item
                  name="categoryName"
                  rules={[
                    {
                      required: true,
                      message: "Please enter category name",
                      whitespace: true,
                    },
                    {
                      min: 3,
                      message: "Name must be at least 3 characters",
                    },
                    {
                      pattern: /^[A-Za-z\s\d\W]+$/,
                      message: "Please enter valid characters",
                    },
                  ]}
                >
                  <div>
                    <h4>Category Name ( English )</h4>
                    <Input
                      placeholder="Category Name ( English )"
                      style={{ height: 55 }}
                      value={categoryName}
                      onChange={(e) => {
                        setCategoryName(e.target.value);
                      }}
                    />
                  </div>
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item
                  name="categoryName_ar"
                  rules={[
                    {
                      required: true,
                      message: "Please enter category name",
                      whitespace: true,
                    },
                    {
                      min: 3,
                      message: "Name must be at least 3 characters",
                    },
                    {
                      pattern: /^[A-Za-z\s\d\W]+$/,
                      message: "Please enter valid characters",
                    },
                  ]}
                >
                  <div>
                    <h4>Category Name ( Arabic )</h4>
                    <Input
                      placeholder="Category Name ( Arabic )"
                      style={{ height: 55 }}
                      value={categoryNameAr}
                      onChange={(e) => {
                        setCategoryNameAr(e.target.value);
                      }}
                    />
                  </div>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col xs={24} md={12}>
                <Form.Item
                  name="metaTitle"
                  rules={[
                    {
                      required: true,
                      message: "Please enter Meta Title",
                      whitespace: true,
                    },
                  ]}
                >
                  <div>
                    <h4>Meta Title</h4>
                    <Input
                      placeholder="Meta Title"
                      style={{ height: 55 }}
                      value={metaTitle}
                      onChange={(e) => {
                        setMetaTitle(e.target.value);
                      }}
                    />
                  </div>
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item
                  name="metaDescription"
                  rules={[
                    {
                      required: true,
                      message: "Please enter Meta Description",
                      whitespace: true,
                    },
                  ]}
                >
                  <div>
                    <h4>Meta Description</h4>
                    <Input
                      placeholder="Meta Description"
                      style={{ height: 55 }}
                      value={metaDescription}
                      onChange={(e) => {
                        setMetaDescription(e.target.value);
                      }}
                    />
                  </div>
                </Form.Item>
              </Col>
            </Row>

            <Form.Item wrapperCol={{ offset: 4, span: 16 }}>
              <div
                style={{ display: "flex", marginTop: 20, alignItems: "center" }}
              >
                <Button
                  style={{
                    background: "#C69320",
                    width: 200,
                    height: 50,

                    margin: "auto",
                  }}
                  type="primary"
                  htmlType="submit"
                >
                  {path ? "Add" : "Save"}
                </Button>
              </div>
            </Form.Item>
          </Form>
        </div>
      </Card>
    </div>
  );
};

export default AddProductCategoryForm;
